import React, { useState } from 'react';
import { Box, Typography, Icon } from '@mui/material';

const styles = {
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
  },
  cardHeader: {
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
  },
  cardTitle: {
    margin: 0,
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#333',
  },
  tabList: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
  },
  tabButton: {
    flex: 1,
    padding: '12px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '0.875rem',
    transition: 'all 0.3s ease',
  },
  tabContent: {
    padding: '16px',
  },
  taskItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    marginBottom: '8px',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  },
  taskIcon: {
    marginRight: '12px',
    color: 'white',
  },
  taskInfo: {
    color: 'white',
  },
  taskTitle: {
    margin: 0,
    fontWeight: 500,
  },
  taskDate: {
    margin: '4px 0 0',
    fontSize: '0.75rem',
    opacity: 0.8,
  },
};
export default function TabbedTaskOverview({ events }) {
    const [activeTab, setActiveTab] = useState('upcoming');
    console.log(`events: ${JSON.stringify(events, null, 2)}`);
  
    const filterEvents = (tab) => {
      const now = new Date();
      switch (tab) {
        case 'upcoming':
          return events
            .filter(event => new Date(event.start) > now)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .slice(0, 5);
        case 'recent':
          return events
            .filter(event => new Date(event.start) <= now)
            .sort((a, b) => new Date(b.start) - new Date(a.start))
            .slice(0, 5);
        case 'completed':
          return events
            .filter(event => event.extendedProps?.status === 'completed')
            .sort((a, b) => new Date(b.start) - new Date(a.start))
            .slice(0, 5);
        default:
          return [];
      }
    };
  
    const renderTaskItem = (event) => (
      <div 
        key={event.id} 
        style={{...styles.taskItem, backgroundColor: event.backgroundColor}}
        onClick={() => console.log(event)}
      >
        <span style={styles.taskIcon}>{event.allDay ? '📅' : '🕒'}</span>
        <div style={styles.taskInfo}>
          <p style={styles.taskTitle}>{event.title}</p>
          <p style={styles.taskDate}>
            {new Date(event.start).toLocaleDateString()}
            {!event.allDay && ` ${new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}
          </p>
        </div>
      </div>
    );
  
    const filteredEvents = filterEvents(activeTab);
  
    return (
      <div style={styles.card}>
        <div style={styles.cardHeader}>
          <h2 style={styles.cardTitle}>Task Overview</h2>
        </div>
        <div style={styles.tabList}>
          {['upcoming', 'recent', 'completed'].map((tab) => (
            <button
              key={tab}
              style={{
                ...styles.tabButton,
                fontWeight: activeTab === tab ? 600 : 400,
                color: activeTab === tab ? '#3182ce' : '#4a5568',
                borderBottom: `2px solid ${activeTab === tab ? '#3182ce' : 'transparent'}`,
              }}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <div style={styles.tabContent}>
          {filteredEvents.length > 0 ? (
            filteredEvents.map(renderTaskItem)
          ) : (
            // <p style={{ textAlign: 'center', color: '#4a5568' }}>No {activeTab} tasks</p>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="200px">
          <Icon color="success" style={{ fontSize: 48, marginBottom: 16 }}>check_circle</Icon>
          <Typography variant="h6" color="success" fontWeight="medium">
            You're all caught up!
          </Typography>
        </Box>
          )}
        </div>
      </div>
    );
  }