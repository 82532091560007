import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
 
function GmailAuthCallback() {
  const navigate = useNavigate();
 
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.hash.slice(1));
    const accessToken = queryParams.get('access_token');
 
    if (accessToken) {
      // Close the window and pass the token back to the opener
      if (window.opener) {
        window.opener.postMessage({ type: 'GMAIL_AUTH_SUCCESS', accessToken }, window.location.origin);
      }
      window.close();
    } else {
      console.error('No access token found in URL');
      navigate('/settings'); // Redirect to settings page or show an error
    }
  }, [navigate]);
 
  return <div>Authenticating with Gmail...</div>;
}
 
export default GmailAuthCallback;