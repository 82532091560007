import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Card,
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Phone as PhoneIcon,
  Group as GroupIcon,
  Email as EmailIcon,
  Assignment as TaskIcon,
  Event as EventIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton"; // Added MDButton import
import TimelineItem from "examples/Timeline/TimelineItem";
import { formatDate, formatTimeForDisplay } from "utils/DateTimeConversion";
import { ArrowUpward, Remove } from '@mui/icons-material';

// ActivityDetails Component
const ActivityDetails = ({ activity, open, onClose, onEdit, onDelete }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedActivity, setEditedActivity] = useState(null);

  useEffect(() => {
    if (activity) {
      setEditedActivity({ ...activity });
    }
  }, [activity]);

  const handleSave = () => {
    onEdit(editedActivity);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setEditedActivity({ ...activity });
    setIsEditMode(false);
  };

  if (!activity || !editedActivity) return null;

  const activityTypes = ['Call', 'Meeting', 'Email', 'Task', 'Other'];
  const activityStatuses = ['Scheduled', 'Completed', 'Cancelled'];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Activity Details</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'grid', gap: 2 }}>
          <Box>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Type
            </MDTypography>
            {isEditMode ? (
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Select
                  value={editedActivity.type}
                  sx={{ height: '40px' }}
                  onChange={(e) => setEditedActivity({
                    ...editedActivity,
                    type: e.target.value
                  })}
                >
                  {activityTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <MDTypography variant="body2">
                {activity.type}
              </MDTypography>
            )}
          </Box>

          <Box>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Status
            </MDTypography>
            {isEditMode ? (
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Select
                  value={editedActivity.status}
                  sx={{ height: '40px' }}
                  onChange={(e) => setEditedActivity({
                    ...editedActivity,
                    status: e.target.value
                  })}
                >
                  {activityStatuses.map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <MDTypography variant="body2">
                {activity.status}
              </MDTypography>
            )}
          </Box>

          {activity.description && (
            <Box>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Description
              </MDTypography>
              {isEditMode ? (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  value={editedActivity.description || ''}
                  onChange={(e) => setEditedActivity({
                    ...editedActivity,
                    description: e.target.value
                  })}
                  sx={{ mt: 1 }}
                />
              ) : (
                <MDTypography variant="body2">
                  {activity.description}
                </MDTypography>
              )}
            </Box>
          )}

          <Box>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Due Date
            </MDTypography>
            {isEditMode ? (
              <TextField
                fullWidth
                type="datetime-local"
                size="small"
                value={editedActivity.due_date ? editedActivity.due_date.slice(0, 16) : ''}
                onChange={(e) => setEditedActivity({
                  ...editedActivity,
                  due_date: e.target.value
                })}
                sx={{ mt: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            ) : (
              <MDTypography variant="body2">
                {formatDate(activity.due_date, 'datetime')}
              </MDTypography>
            )}
          </Box>

          {activity.type === 'Meeting' && activity.starttime && activity.endtime && (
            <Box>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Time
              </MDTypography>
              {isEditMode ? (
                <Box sx={{ display: 'grid', gap: 1, mt: 1 }}>
                  <TextField
                    fullWidth
                    label="Start Time"
                    type="time"
                    size="small"
                    value={editedActivity.starttime || ''}
                    onChange={(e) => setEditedActivity({
                      ...editedActivity,
                      starttime: e.target.value
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="End Time"
                    type="time"
                    size="small"
                    value={editedActivity.endtime || ''}
                    onChange={(e) => setEditedActivity({
                      ...editedActivity,
                      endtime: e.target.value
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              ) : (
                <MDTypography variant="body2">
                  {formatTimeForDisplay(activity.starttime)} - {formatTimeForDisplay(activity.endtime)}
                </MDTypography>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        {isEditMode ? (
          <>
            <MDButton
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              onClick={handleSave}
            >
              <SaveIcon />
            </MDButton>
          </>
        ) : (
          <>
            <MDButton
              variant="outlined"
              color="info"
              iconOnly
              onClick={() => setIsEditMode(true)}
            >
              <EditIcon />
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              iconOnly
              onClick={() => {
                onDelete(activity.id);
                onClose();
              }}
            >
              <DeleteIcon />
            </MDButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

function Activities({ activities = [], onDelete, onEdit }) {
  const [selectedActivity, setSelectedActivity] = useState(null);

  const getActivityIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'call':
        return <PhoneIcon />;
      case 'meeting':
        return <GroupIcon />;
      case 'email':
        return <EmailIcon />;
      case 'task':
        return <TaskIcon />;
      default:
        return <EventIcon />;
    }
  };

  const getActivityColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'scheduled':
        return 'info';
      case 'cancelled':
        return 'error';
      default:
        return 'info';
    }
  };

  const getActivityTitle = (activity) => {
    let title = `${activity.type} - ${activity.status}`;
    
    if (activity.type === 'Meeting' && activity.starttime && activity.endtime) {
      title += `\nTime: ${formatTimeForDisplay(activity.starttime)} - ${formatTimeForDisplay(activity.endtime)}`;
    }
    
    return title;
  };

  const todayActivities = activities.filter(activity => {
    const activityDate = new Date(activity.due_date);
    const today = new Date();
    return (
      activityDate.getDate() === today.getDate() &&
      activityDate.getMonth() === today.getMonth() &&
      activityDate.getFullYear() === today.getFullYear()
    );
  });

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Activity Timeline
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Box component="span" color="success.main">
                {/* {todayActivities.length > 0 ? 'up' : 'remove'} */}
                {todayActivities.length > 0 ? (
                <ArrowUpward fontSize="small" />
              ) : (
                <Remove fontSize="small" />
              )}
              </Box>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              {todayActivities.length}
            </MDTypography>{" "}
            {todayActivities.length === 1 ? 'activity' : 'activities'} today
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox 
        p={2} 
        sx={{ 
          maxHeight: '400px', 
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        {activities.length > 0 ? (
          activities.map((activity, index) => (
            <Box
              key={activity.id || index}
              onClick={() => setSelectedActivity(activity)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: 1,
                transition: 'background-color 0.2s',
              }}
            >
              <TimelineItem
                color={getActivityColor(activity.status)}
                icon={getActivityIcon(activity.type)}
                title={
                  <MDTypography variant="button" fontWeight="medium" color={getActivityColor(activity.status)}>
                    {getActivityTitle(activity)}
                  </MDTypography>
                }
                dateTime={formatDate(activity.due_date, 'datetime')}
              />
            </Box>
          ))
        ) : (
          <MDTypography variant="body2" color="text">
            No activities to display.
          </MDTypography>
        )}
      </MDBox>

      <ActivityDetails
        activity={selectedActivity}
        open={!!selectedActivity}
        onClose={() => setSelectedActivity(null)}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </Card>
  );
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      status: PropTypes.string,
      description: PropTypes.string,
      due_date: PropTypes.string,
      starttime: PropTypes.string,
      endtime: PropTypes.string,
    })
  ),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Activities;