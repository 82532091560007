import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const taskTypes = ["Event", "Meeting", "Call", "Email"];
const colorOptions = [
  { value: "info", label: "Blue" },
  { value: "success", label: "Green" },
  { value: "warning", label: "Yellow" },
  { value: "error", label: "Red" },
  { value: "primary", label: "Purple" },
];

function TaskCreationModal({ open, onClose, onTaskCreated, user, axiosInstance }) {
  const [taskData, setTaskData] = useState({
    title: "",
    type: "",
    start: "",
    end: "",
    allDay: false,
    color: "info",
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setTaskData({ ...taskData, allDay: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/tasks', {
        ...taskData,
        assignee_id: user.id,
      });
      onTaskCreated();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
      }}>
        <MDTypography variant="h6" component="h2" mb={3}>
          Create New Task
        </MDTypography>
        <form onSubmit={handleSubmit}>
          <MDBox mb={2}>
            <TextField
              fullWidth
              label="Task Title"
              name="title"
              value={taskData.title}
              onChange={handleInputChange}
              required
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel>Task Type</InputLabel>
              <Select
                name="type"
                value={taskData.type}
                onChange={handleInputChange}
                required
              >
                {taskTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <TextField
              fullWidth
              label="Start Date"
              type="datetime-local"
              name="start"
              value={taskData.start}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </MDBox>
          {!taskData.allDay && (
            <MDBox mb={2}>
              <TextField
                fullWidth
                label="End Date"
                type="datetime-local"
                name="end"
                value={taskData.end}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
          )}
          <MDBox mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={taskData.allDay}
                  onChange={handleCheckboxChange}
                  name="allDay"
                />
              }
              label="All Day Event"
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Select
                name="color"
                value={taskData.color}
                onChange={handleInputChange}
              >
                {colorOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              value={taskData.notes}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end">
            <Button onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create Task
            </Button>
          </MDBox>
        </form>
      </Box>
    </Modal>
  );
}

export default TaskCreationModal;