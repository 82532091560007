import React, { useState } from 'react';
import {
  Card,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GeneralSettings from 'components/SettingsComponents/GenralSettings';
import EmailSettings from 'components/SettingsComponents/EmailSettings';
import { GoogleOAuthProvider } from '@react-oauth/google';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Settings() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Settings
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <Tabs value={value} onChange={handleChange} aria-label="settings tabs">
                  <Tab label="General" />
                  <Tab label="Users" />
                  <Tab label="Email" />
                  <Tab label="AI" />
                  <Tab label="Personal Info" />
                  <Tab label="Automations" />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <GeneralSettings />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  Users Settings (To be implemented)
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {/* <GoogleOAuthProvider clientId="257313893513-560o628mu8moacl38vditneo8qhjep21.apps.googleusercontent.com"> */}
                    <EmailSettings />
                  {/* </GoogleOAuthProvider> */}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  AI Settings (To be implemented)
                </TabPanel>
                <TabPanel value={value} index={4}>
                  Personal Info Settings (To be implemented)
                </TabPanel>
                <TabPanel value={value} index={5}>
                  Automations Settings (To be implemented)
                </TabPanel>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Settings;