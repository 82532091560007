import React, { useState, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  Paper,
  Alert
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

// Initialize Supabase client with retries and timeout
const supabaseUrl = 'https://nrnxmczlxdtieqkamqcu.supabase.co'; // Changed from .com to .co
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5ybnhtY3pseGR0aWVxa2FtcWN1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyMjg1MDQ4OSwiZXhwIjoyMDM4NDI2NDg5fQ.ulMNy607zHSdzUvkF0vvyvcBElKdFj44-IQnmvCdWzo';

const supabase = createClient(supabaseUrl, supabaseKey);

const S3FileUpload = React.forwardRef(({ // Move forwardRef here and add ref parameter
  bucketName = 'attachments',
  folderPath = 'uploads',
  allowedFileTypes = '*/*',
  maxSizeInMB = 25,
  onUploadSuccess,
  onUploadError,
  onCancel,
  customFileName,
  onFileSelect,
}, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  const validateFile = useCallback((file) => {
    if (!file) return { isValid: false, error: 'No file selected' };

    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > maxSizeInMB) {
      return { isValid: false, error: `File size must be less than ${maxSizeInMB}MB` };
    }

    if (allowedFileTypes !== '*/*') {
      const allowedTypes = allowedFileTypes.split(',').map(type => type.trim());
      if (!allowedTypes.some(type => file.type.match(new RegExp(type.replace('*', '.*'))))) {
        return { isValid: false, error: `File type must be one of: ${allowedTypes.join(', ')}` };
      }
    }

    return { isValid: true, error: null };
  }, [maxSizeInMB, allowedFileTypes]);

  const getSignedUrl = async (filePath) => {
    try {
      const { data, error } = await supabase
        .storage
        .from(bucketName)
        .createSignedUrl(filePath, 31536000);

      if (error) throw error;
      return data.signedUrl;
    } catch (error) {
      console.error('Error generating signed URL:', error);
      throw new Error('Failed to generate signed URL');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validation = validateFile(file);
    if (!validation.isValid) {
      setError(validation.error);
      return;
    }

    setError(null);
    setSelectedFile(file);
    onFileSelect?.(file); // Call the new callback

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      setIsUploading(true);
      setUploadProgress(0);
      setError(null);

      // First, check if bucket exists and is accessible
      const { data: buckets, error: bucketError } = await supabase
        .storage
        .listBuckets();

      if (bucketError) {
        throw new Error(`Storage access error: ${bucketError.message}`);
      }

      if (!buckets.some(b => b.name === bucketName)) {
        throw new Error(`Bucket "${bucketName}" not found or not accessible`);
      }

      // Generate file path
      const fileExt = selectedFile.name.split('.').pop();
      const fileName = customFileName || `${uuidv4()}.${fileExt}`;
      const filePath = folderPath ? `${folderPath}/${fileName}` : fileName;

      // Attempt upload
      const { data, error: uploadError } = await supabase.storage
        .from(bucketName)
        .upload(filePath, selectedFile, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) {
        throw new Error(`Upload failed: ${uploadError.message}`);
      }

      // Get signed URL for the uploaded file
      const signedUrl = await getSignedUrl(filePath);
      
      onUploadSuccess?.({
        path: filePath,
        url: signedUrl,
        file: {
          name: selectedFile.name,
          size: selectedFile.size,
          type: selectedFile.type
        }
      });

      setSelectedFile(null);
      setPreview(null);
      setError(null);
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.message || 'Failed to upload file');
      onUploadError?.(error.message || 'Failed to upload file');
    } finally {
      setIsUploading(false);
    }
  };


  // Expose handleUpload to parent through a ref
  React.useImperativeHandle(ref, () => ({
    handleUpload,
    selectedFile: () => selectedFile,
    isUploading: () => isUploading
  }));

  const handleCancel = () => {
    setSelectedFile(null);
    setPreview(null);
    setError(null);
    onCancel?.();
  };

  return (
    <Paper elevation={2} sx={{ p: 3, maxWidth: 500, mx: 'auto' }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {!selectedFile ? (
        <Box
          sx={{
            border: '2px dashed',
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 3,
            textAlign: 'center',
            cursor: 'pointer'
          }}
        >
          <input
            type="file"
            accept={allowedFileTypes}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
              <Typography variant="subtitle1" color="primary" sx={{ mb: 1 }}>
                Choose a file
              </Typography>
              <Typography variant="body2" color="text.secondary">
                or drag and drop it here
              </Typography>
            </Box>
          </label>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            {preview ? (
              <Box sx={{ mb: 2 }}>
                <img
                  src={preview}
                  alt="Preview"
                  style={{ maxWidth: '100%', maxHeight: 200, objectFit: 'contain' }}
                />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {selectedFile.type.startsWith('image/') ? <ImageIcon fontSize="large" /> :
                 selectedFile.type.includes('pdf') ? <PictureAsPdfIcon fontSize="large" /> :
                 <InsertDriveFileIcon fontSize="large" />}
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2">{selectedFile.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                  </Typography>
                </Box>
              </Box>
            )}

            {isUploading ? (
              <Box sx={{ mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} sx={{ mb: 1 }} />
                <Typography variant="body2" color="text.secondary" align="center">
                  Uploading... {Math.round(uploadProgress)}%
                </Typography>
              </Box>
            ) : (
              // <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              //   <Button
              //     fullWidth
              //     variant="gradient"
              //     onClick={handleUpload}
              //     disabled={isUploading}
              //   >
              //     Upload
              //   </Button>
              //   <Button
              //     fullWidth
              //     variant="gradient"
              //     onClick={handleCancel}
              //     disabled={isUploading}
              //   >
              //     Cancel
              //   </Button>
              // </Box>
            <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
              {/* <MDButton onClick={handleUpload} color="info" sx={{ marginRight: 2 }}>
                Upload
              </MDButton> */}
              {/* <MDButton onClick={handleCancel} color="warning" sx={{ marginRight: 2 }}>
                Cancel
              </MDButton> */}
            </MDBox>
            )}
          </Paper>
        </Box>
      )}

      <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
        {`Allowed files: ${allowedFileTypes === '*/*' ? 'All files' : allowedFileTypes}`}
        <br />
        {`Maximum size: ${maxSizeInMB}MB`}
      </Typography>
    </Paper>
  );
});

// export default S3FileUpload;
export default S3FileUpload