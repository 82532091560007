import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Grid,
  Chip,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Box,
  Pagination,
  Stack,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Fade,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
 
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
 
// Icons
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SortIcon from '@mui/icons-material/Sort';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from 'auth/Auth';
 
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import Inventory2Icon from '@mui/icons-material/Inventory2';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContactsIcon from '@mui/icons-material/Contacts';
import CircleIcon from '@mui/icons-material/Circle';


// Add new icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StageIcon from '@mui/icons-material/Flag';
import EventIcon from '@mui/icons-material/Event';
import BadgeIcon from '@mui/icons-material/Badge';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InventoryIcon from '@mui/icons-material/Inventory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FactoryIcon from '@mui/icons-material/Factory';
import CategoryIcon from '@mui/icons-material/Category';



const ITEMS_PER_PAGE = 20;

const typeColors = {
  leads: {
    light: '#E3F2FD',
    main: '#2196F3',
    dark: '#1976D2'
  },
  users: {
    light: '#E8F5E9',
    main: '#4CAF50',
    dark: '#388E3C'
  },
  deals: {
    light: '#FFF3E0',
    main: '#FF9800',
    dark: '#F57C00'
  },
  products: {
    light: '#F3E5F5',
    main: '#9C27B0',
    dark: '#7B1FA2'
  },
  accounts: {
    light: '#E8EAF6',
    main: '#3F51B5',
    dark: '#303F9F'
  },
  contacts: {
    light: '#FFEBEE',
    main: '#F44336',
    dark: '#D32F2F'
  },
  // Add a default color scheme for any unexpected types
  default: {
    light: '#FAFAFA',
    main: '#9E9E9E',
    dark: '#616161'
  }
};


// First, add this function to calculate counts for each type
const getTypeCounts = (results) => {
  const counts = results.reduce((acc, result) => {
    acc[result.type] = (acc[result.type] || 0) + 1;
    return acc;
  }, {});
  
  // Add total count for 'all'
  counts.all = results.length;
  
  return counts;
};


// Define the filter types configuration
const filterTypes = [
  { id: 'all', label: 'All', Icon: SearchIcon },
  { id: 'leads', label: 'Leads', Icon: BusinessIcon },
  { id: 'users', label: 'Users', Icon: PersonIcon },
  { id: 'deals', label: 'Deals', Icon: HandshakeIcon },
  { id: 'products', label: 'Products', Icon: Inventory2Icon },
  { id: 'accounts', label: 'Accounts', Icon: AccountBalanceIcon },
  { id: 'contacts', label: 'Contacts', Icon: ContactsIcon },
];

// Reusable chip styles
const chipStyles = {
  px: 2,
  '&:hover': { transform: 'translateY(-2px)' },
  transition: 'transform 0.2s ease'
};
 
function SearchResults() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [page, setPage] = useState(1);
  const [viewMode, setViewMode] = useState('grid');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState('all');
  const [activeFilters, setActiveFilters] = useState([]);
  const [sortBy, setSortBy] = useState('newest');
  const [hoveredCard, setHoveredCard] = useState(null);
 
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { axiosInstance } = useAuth();
 
 
  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchSearchResults();
    }
  }, [searchParams]);
 
  const fetchSearchResults = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/search`, {
        params: { query: searchQuery }
      });
      setSearchResults(response.data);
      setPage(1); // Reset to first page on new search
    } catch (error) {
      console.error('Error fetching search results:', error);
      if (error.response?.status === 404) {
        setSearchResults([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
 
  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setPage(1); // Reset to first page when changing type
  };
 
 
 
  // Add the missing functions here
  const handleResultClick = (result) => {
    switch (result.type) {
      case 'leads':
        navigate(`/lead/detail/${result.id}`);
        break;
      case 'users':
        navigate(`/profile/${result.id}`);
        break;
      case 'deals':
        navigate(`/deal/detail/${result.id}`);
        break;
      case 'products':
        navigate(`/products/${result.id}`);
        break;
      case 'accounts':
        navigate(`/account/detail/${result.id}`);
        break;
      case 'contacts':
        navigate(`/contact/detail/${result.id}`);
        break;
      default:
        break;
    }
  };
 
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (value.length < 2) {
      setSearchResults([]);
    }
  };
 
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission
    if (searchQuery.length >= 2) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('query', searchQuery);
      setSearchParams(newSearchParams);
      fetchSearchResults();
    }
  };

  const processResults = (results) => {
    let processed = [...results];
 
    // Apply type filter
    if (selectedType !== 'all') {
      processed = processed.filter(item => item.type === selectedType);
    }
 
    // Apply sorting
    switch (sortBy) {
      case 'newest':
        processed.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;
      case 'alphabetical':
        processed.sort((a, b) => getName(a).localeCompare(getName(b)));
        break;
      case 'type':
        processed.sort((a, b) => a.type.localeCompare(b.type));
        break;
    }
 
    return processed;
  };
 
  const processedResults = processResults(searchResults);
  console.log(processedResults);
 
  const totalPages = Math.ceil(processedResults.length / ITEMS_PER_PAGE);
  const paginatedResults = processedResults.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );
 
  const getName = (result) => {
    switch (result.type) {
      case 'leads':
        return `${result.first_name} ${result.last_name}`;
      case 'users':
        return result.username;
      case 'deals':
        return result.name;
      case 'products':
        return result.name;
      case 'accounts':
        return result.name;
      case 'contacts':
        return `${result.first_name} ${result.last_name}`;
      default:
        return '';
    }
  };
 
  const handleViewModeChange = (_, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // const getIcon = (type, size = 40) => {
  //   const iconProps = {
  //     sx: { 
  //       fontSize: size,
  //       color: typeColors[type].main
  //     }
  //   };

  //   switch (type) {
  //     case 'leads':
  //       return <BusinessIcon {...iconProps} />;
  //     case 'users':
  //       return <PersonIcon {...iconProps} />;
  //     case 'deals':
  //       return <HandshakeIcon {...iconProps} />;
  //     default:
  //       return null;
  //   }
  // };

  const getIcon = (type, size = 40) => {
    // Get the color scheme, fallback to default if type doesn't exist
    const colorScheme = typeColors[type] || typeColors.default;
    
    const iconProps = {
      sx: {
        fontSize: size,
        color: colorScheme.main
      }
    };
  
    switch (type) {
      case 'leads':
        return <BusinessIcon {...iconProps} />;
      case 'users':
        return <PersonIcon {...iconProps} />;
      case 'deals':
        return <HandshakeIcon {...iconProps} />;
      case 'products':
        return <Inventory2Icon {...iconProps} />;
      case 'accounts':
        return <AccountBalanceIcon {...iconProps} />;
      case 'contacts':
        return <ContactsIcon {...iconProps} />;
      default:
        return <CircleIcon {...iconProps} />; // Default icon for unknown types
    }
  };

  // Updated ListViewItem component with more lead details
// const ListViewItem = ({ result }) => (
//   <Card
//     onClick={() => handleResultClick(result)}
//     onMouseEnter={() => setHoveredCard(result.id)}
//     onMouseLeave={() => setHoveredCard(null)}
//     sx={{
//       p: 2,
//       mb: 2,
//       cursor: 'pointer',
//       display: 'flex',
//       alignItems: 'flex-start',
//       transition: 'all 0.3s ease',
//       transform: hoveredCard === result.id ? 'translateX(8px)' : 'none',
//       backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
//       '&:hover': {
//         boxShadow: 3,
//       },
//     }}
//   >
//     <Box sx={{ mr: 3, mt: 1 }}>{getIcon(result.type, 32)}</Box>
//     <Box sx={{ flexGrow: 1 }}>
//       {result.type === 'leads' ? (
//         <>
//           <Typography variant="h6" sx={{ mb: 0.5 }}>
//             {`${result.first_name} ${result.last_name}`}
//           </Typography>
//           <Stack spacing={0.5}>
//             <Typography variant="body2" color="text.secondary">
//               <BusinessIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
//               {result.company_name}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
//               {result.email}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
//               {result.mobile}
//             </Typography>
//           </Stack>
//         </>
//       ) : (
//         <>
//           <Typography variant="h6" sx={{ mb: 0.5 }}>
//             {getName(result)}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {result.type === 'users' ? result.email : result.description}
//           </Typography>
//         </>
//       )}
//     </Box>
//     <Chip
//       label={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
//       sx={{ 
//         backgroundColor: typeColors[result.type].light,
//         color: typeColors[result.type].dark,
//         ml: 2 
//       }}
//     />
//   </Card>
// );

const ListViewItem = ({ result }) => {
  const getTypeSpecificContent = () => {
    switch (result.type) {
      case 'users':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <PersonIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.username}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <CheckCircleIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom', color: result.is_active ? 'success.main' : 'text.disabled' }} />
              {result.is_active ? 'Active' : 'Inactive'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <AdminPanelSettingsIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.role}
            </Typography>
          </Stack>
        );

      case 'deals':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <StageIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.stage}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <EventIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.close_date}
            </Typography>
          </Stack>
        );

      case 'accounts':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.manager_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.account_type}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <CircleIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom', color: result.account_status === 'active' ? 'success.main' : 'warning.main' }} />
              {result.account_status}
            </Typography>
          </Stack>
        );

      case 'contacts':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.phone}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <WorkIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.department}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <BadgeIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.title}
            </Typography>
          </Stack>
        );

      case 'products':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <LocalOfferIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.product_code}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <InventoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.sku}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <AttachMoneyIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              ₹{result.price}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <FactoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.manufacturer}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <CategoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.category}
            </Typography>
          </Stack>
        );

      case 'leads':
        return (
          <Stack spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              <BusinessIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.company_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
              {result.mobile}
            </Typography>
          </Stack>
        );

      default:
        return null;
    }
  };

  const getName = (result) => {
    switch (result.type) {
      case 'users':
        return result.username;
      case 'deals':
        return result.name;
      case 'accounts':
        return result.name;
      case 'contacts':
        return result.first_name && result.last_name 
          ? `${result.first_name} ${result.last_name}`
          : result.first_name || result.last_name || '';
      case 'products':
        return result.name;
      case 'leads':
        return `${result.first_name} ${result.last_name}`;
      default:
        return '';
    }
  };

  return (
    // <Card
    //   onClick={() => handleResultClick(result)}
    //   onMouseEnter={() => setHoveredCard(result.id)}
    //   onMouseLeave={() => setHoveredCard(null)}
    //   sx={{
    //     p: 2,
    //     mb: 2,
    //     cursor: 'pointer',
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     transition: 'all 0.3s ease',
    //     transform: hoveredCard === result.id ? 'translateX(8px)' : 'none',
    //     backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
    //     '&:hover': {
    //       boxShadow: 3,
    //     },
    //   }}
    // >
    //   <Box sx={{ mr: 3, mt: 1 }}>{getIcon(result.type, 32)}</Box>
    //   <Box sx={{ flexGrow: 1 }}>
    //     <Typography variant="h6" sx={{ mb: 0.5 }}>
    //       {getName(result)}
    //     </Typography>
    //     {getTypeSpecificContent()}
    //   </Box>
    //   <Chip
    //     label={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
    //     sx={{ 
    //       backgroundColor: typeColors[result.type].light,
    //       color: typeColors[result.type].dark,
    //       ml: 2 
    //     }}
    //   />
    // </Card>
    <Card
      onClick={() => handleResultClick(result)}
      onMouseEnter={() => setHoveredCard(result.id)}
      onMouseLeave={() => setHoveredCard(null)}
      sx={{
        p: 2,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.3s ease',
        transform: hoveredCard === result.id ? 'translateX(4px)' : 'none',
        backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
        '&:hover': { boxShadow: 2 },
      }}
    >
      <Box sx={{ mr: 2 }}>{getIcon(result.type)}</Box>
      <Box sx={{ flexGrow: 1, minWidth: 0 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }} noWrap>
          {getName(result)}
        </Typography>
        {getTypeSpecificContent(result)}
      </Box>
      <Chip
        label={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
        size="small"
        sx={{ 
          backgroundColor: typeColors[result.type].light,
          color: typeColors[result.type].dark,
          ml: 2 
        }}
      />
    </Card>

  );
};

// Updated GridViewItem component with more lead details
// const GridViewItem = ({ result }) => (
//   <Card
//     onClick={() => handleResultClick(result)}
//     onMouseEnter={() => setHoveredCard(result.id)}
//     onMouseLeave={() => setHoveredCard(null)}
//     sx={{
//       p: 3,
//       height: '100%',
//       cursor: 'pointer',
//       transition: 'all 0.3s ease',
//       transform: hoveredCard === result.id ? 'translateY(-8px)' : 'none',
//       backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
//       '&:hover': {
//         boxShadow: 4,
//       },
//     }}
//   >
//     <Stack spacing={2} alignItems="center">
//       <Box 
//         sx={{
//           mb: 2,
//           transition: 'transform 0.3s ease',
//           transform: hoveredCard === result.id ? 'scale(1.1)' : 'none',
//         }}
//       >
//         {getIcon(result.type)}
//       </Box>
      
//       <Chip
//         label={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
//         sx={{ 
//           backgroundColor: typeColors[result.type].light,
//           color: typeColors[result.type].dark,
//           mb: 1 
//         }}
//       />

//       {result.type === 'leads' ? (
//         <>
//           <Typography 
//             variant="h6" 
//             align="center" 
//             sx={{ 
//               mb: 1,
//               color: hoveredCard === result.id ? typeColors[result.type].dark : 'inherit'
//             }}
//           >
//             {`${result.first_name} ${result.last_name}`}
//           </Typography>

//           <Stack spacing={1} alignItems="center" width="100%">
//             <Typography variant="body2" color="text.secondary" align="center" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//               <BusinessIcon sx={{ fontSize: 16 }} />
//               {result.company_name}
//             </Typography>
//             <Typography variant="body2" color="text.secondary" align="center" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//               <EmailIcon sx={{ fontSize: 16 }} />
//               {result.email}
//             </Typography>
//             <Typography variant="body2" color="text.secondary" align="center" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//               <PhoneIcon sx={{ fontSize: 16 }} />
//               {result.mobile}
//             </Typography>
//           </Stack>
//         </>
//       ) : (
//         <>
//           <Typography 
//             variant="h6" 
//             align="center" 
//             sx={{ 
//               mb: 1,
//               color: hoveredCard === result.id ? typeColors[result.type].dark : 'inherit'
//             }}
//           >
//             {getName(result)}
//           </Typography>

//           <Typography variant="body2" color="text.secondary" align="center">
//             {result.type === 'users' ? result.email : result.description}
//           </Typography>
//         </>
//       )}
//     </Stack>
//   </Card>
// );

const GridViewItem = ({ result }) => {
  const getName = (result) => {
    switch (result.type) {
      case 'users':
        return result.username;
      case 'deals':
        return result.name;
      case 'accounts':
        return result.name;
      case 'contacts':
        return result.first_name && result.last_name 
          ? `${result.first_name} ${result.last_name}`
          : result.first_name || result.last_name || 'N/A';
      case 'products':
        return result.name;
      case 'leads':
        return `${result.first_name} ${result.last_name}`;
      default:
        return '';
    }
  };

  // const getTypeSpecificContent = () => {
  //   switch (result.type) {
  //     case 'users':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.email}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <PersonIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.username}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <CheckCircleIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom', color: result.is_active ? 'success.main' : 'text.disabled' }} />
  //             {result.is_active ? 'Active' : 'Inactive'}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <AdminPanelSettingsIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.role}
  //           </Typography>
  //         </Stack>
  //       );

  //     case 'deals':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <StageIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.stage}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <EventIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.close_date}
  //           </Typography>
  //         </Stack>
  //       );

  //     case 'accounts':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.email}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.phone}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <CircleIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom', color: result.account_status === 'active' ? 'success.main' : 'warning.main' }} />
  //             {result.account_status}
  //           </Typography>
  //         </Stack>
  //       );

  //     case 'contacts':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.phone}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <WorkIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.department}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <BadgeIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.title}
  //           </Typography>
  //         </Stack>
  //       );

  //     case 'products':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <LocalOfferIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.product_code}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <InventoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.sku}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <AttachMoneyIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             ₹{result.price}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <FactoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.manufacturer}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <CategoryIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.category}
  //           </Typography>
  //         </Stack>
  //       );

  //     case 'leads':
  //       return (
  //         <Stack spacing={0.5}>
  //           <Typography variant="body2" color="text.secondary">
  //             <BusinessIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.company_name}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <EmailIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.email}
  //           </Typography>
  //           <Typography variant="body2" color="text.secondary">
  //             <PhoneIcon sx={{ fontSize: 16, mr: 1, verticalAlign: 'text-bottom' }} />
  //             {result.mobile}
  //           </Typography>
  //         </Stack>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  const getTypeSpecificContent = (result) => {
    const iconStyle = { fontSize: 14, mr: 0.5, verticalAlign: 'text-bottom' };
  
    switch (result.type) {
      case 'users':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><EmailIcon sx={iconStyle} />{result.email}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><CheckCircleIcon sx={{...iconStyle, color: result.is_active ? 'success.main' : 'text.disabled'}} />{result.is_active ? 'Active' : 'Inactive'}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><AdminPanelSettingsIcon sx={iconStyle} />{result.role}</Typography>
          </>
        );
      case 'deals':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><StageIcon sx={iconStyle} />{result.stage}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><EventIcon sx={iconStyle} />{result.close_date}</Typography>
          </>
        );
      case 'accounts':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><BusinessIcon sx={iconStyle} />{result.account_type}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><PersonIcon sx={iconStyle} />{result.manager_name}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><CheckCircleIcon sx={iconStyle} />{result.account_status}</Typography>
          </>
        );
      case 'contacts':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><PhoneIcon sx={iconStyle} />{result.phone}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><WorkIcon sx={iconStyle} />{result.department}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><BadgeIcon sx={iconStyle} />{result.title}</Typography>
          </>
        );
      case 'products':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><LocalOfferIcon sx={iconStyle} />{result.product_code}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><AttachMoneyIcon sx={iconStyle} />₹{result.price}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><CategoryIcon sx={iconStyle} />{result.category}</Typography>
          </>
        );
      case 'leads':
        return (
          <>
            <Typography variant="body2" color="text.secondary" noWrap><BusinessIcon sx={iconStyle} />{result.company_name}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><EmailIcon sx={iconStyle} />{result.email}</Typography>
            <Typography variant="body2" color="text.secondary" noWrap><PhoneIcon sx={iconStyle} />{result.mobile}</Typography>
          </>
        );
      default:
        return null;
    }
  };

  return (
    // <Card
    //   onClick={() => handleResultClick(result)}
    //   onMouseEnter={() => setHoveredCard(result.id)}
    //   onMouseLeave={() => setHoveredCard(null)}
    //   sx={{
    //     p: 3,
    //     height: '100%',
    //     cursor: 'pointer',
    //     transition: 'all 0.3s ease',
    //     transform: hoveredCard === result.id ? 'translateY(-8px)' : 'none',
    //     backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
    //     '&:hover': {
    //       boxShadow: 4,
    //     },
    //   }}
    // >
    //   <Stack spacing={2} alignItems="center">
    //     <Box 
    //       sx={{
    //         mb: 2,
    //         transition: 'transform 0.3s ease',
    //         transform: hoveredCard === result.id ? 'scale(1.1)' : 'none',
    //       }}
    //     >
    //       {getIcon(result.type)}
    //     </Box>
        
    //     <Chip
    //       label={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
    //       sx={{ 
    //         backgroundColor: typeColors[result.type].light,
    //         color: typeColors[result.type].dark,
    //         mb: 1 
    //       }}
    //     />

    //     <Typography 
    //       variant="h6" 
    //       align="center" 
    //       sx={{ 
    //         mb: 1,
    //         color: hoveredCard === result.id ? typeColors[result.type].dark : 'inherit'
    //       }}
    //     >
    //       {getName(result)}
    //     </Typography>

    //     <Stack spacing={1} alignItems="center" width="100%">
    //       {getTypeSpecificContent()}
    //     </Stack>
    //   </Stack>
    // </Card>

    // <Card
    //   onClick={() => handleResultClick(result)}
    //   onMouseEnter={() => setHoveredCard(result.id)}
    //   onMouseLeave={() => setHoveredCard(null)}
    //   sx={{
    //     p: 2,
    //     height: '100%',
    //     cursor: 'pointer',
    //     transition: 'all 0.3s ease',
    //     transform: hoveredCard === result.id ? 'translateY(-4px)' : 'none',
    //     backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
    //     '&:hover': { boxShadow: 3 },
    //   }}
    // >
    //   <Stack spacing={1} alignItems="flex-start">
    //     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    //       {getIcon(result.type)}
    //       <Chip
    //         label={result.type_singular.charAt(0).toUpperCase() + result.type_singular.slice(1)}
    //         size="small"
    //         sx={{ 
    //           backgroundColor: typeColors[result.type].light,
    //           color: typeColors[result.type].dark,
    //         }}
    //       />
    //     </Box>
    //     <Typography 
    //       variant="subtitle1"
    //       sx={{ 
    //         fontWeight: 'medium',
    //         color: hoveredCard === result.id ? typeColors[result.type].dark : 'text.primary'
    //       }}
    //     >
    //       {getName(result)}
    //     </Typography>
    //     {getTypeSpecificContent(result)}
    //   </Stack>
    // </Card>
    <Card
    onClick={() => handleResultClick(result)}
    onMouseEnter={() => setHoveredCard(result.id)}
    onMouseLeave={() => setHoveredCard(null)}
    sx={{
      p: 2,
      height: '100%',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      transform: hoveredCard === result.id ? 'translateY(-4px)' : 'none',
      backgroundColor: hoveredCard === result.id ? typeColors[result.type].light : 'white',
      '&:hover': { boxShadow: 3 },
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography 
        variant="subtitle1"
        sx={{ 
          fontWeight: 'bold',
          color: hoveredCard === result.id ? typeColors[result.type].dark : 'text.primary',
          flexGrow: 1,
          mr: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {getName(result)}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {getIcon(result.type, 20)}
        <Chip
          label={result.type_singular.charAt(0).toUpperCase() + result.type_singular.slice(1)}
          size="small"
          sx={{ 
            ml: 1,
            backgroundColor: typeColors[result.type].light,
            color: typeColors[result.type].dark,
            height: '24px',
            '& .MuiChip-label': {
              px: 1,
            },
          }}
        />
      </Box>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
      {getTypeSpecificContent(result)}
    </Box>
  </Card>
  );
};

  const typeCounts = getTypeCounts(searchResults);

  // For a more polished look, you can also create a custom component for the pill:
  const FilterPill = ({ id, label, Icon, count, isSelected, onClick }) => (
    <Chip
      icon={<Icon />}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {label}
          <Box
            component="span"
            sx={{
              bgcolor: isSelected ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.08)',
              px: 1,
              py: 0.5,
              borderRadius: '12px',
              fontSize: '0.75rem',
              lineHeight: 1,
              fontWeight: 'medium',
              minWidth: '24px',
              textAlign: 'center'
            }}
          >
            {count}
          </Box>
        </Box>
      }
      onClick={onClick}
      color={isSelected ? 'primary' : 'default'}
      variant={isSelected ? 'filled' : 'outlined'}
      sx={{
        px: 2,
        height: 'auto',
        '&:hover': { transform: 'translateY(-2px)' },
        transition: 'transform 0.2s ease',
        '& .MuiChip-label': {
          px: 1,
          py: 0.75
        }
      }}
    />
  );



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" color="white">
              Search Results
            </MDTypography>
            <Box>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                size="small"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  '& .MuiToggleButton-root': {
                    color: 'white',
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      color: 'white',
                    },
                  },
                }}
              >
                {/* <ToggleButton value="grid">
                  <Tooltip title="Grid View">
                    <ViewModuleIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="list">
                  <Tooltip title="List View">
                    <ViewListIcon />
                  </Tooltip>
                </ToggleButton> */}
              </ToggleButtonGroup>
            </Box>
          </MDBox>
 
          <MDBox pt={3} px={2}>
            <Box sx={{
              display: 'flex',
              gap: 2,
              mb: 4,
              flexDirection: { xs: 'column', sm: 'row' }
            }}>

              <Box sx={{ flexGrow: 1 }}>
                <form onSubmit={handleSearch}>  {/* Add form wrapper */}
                  <TextField
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search for leads, users, or deals..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {isLoading && <CircularProgress size={20} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Box>


              {/* <Tooltip title="Sort Results">
                <Button
                  variant="gradient"
                  onClick={(e) => setSortAnchorEl(e.currentTarget)}
                  startIcon={<SortIcon />}
                >
                  Sort
                </Button>
              </Tooltip> */}

            </Box>


            {/* Type Filter Pills with Counts */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              mb: 4, 
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              {filterTypes.map(({ id, label, Icon }) => (
                <FilterPill
                  key={id}
                  id={id}
                  label={label}
                  Icon={Icon}
                  count={typeCounts[id] || 0}
                  isSelected={selectedType === id}
                  onClick={() => handleTypeSelect(id)}
                />
              ))}
            </Box>


            {/* Sort Menu */}
            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={() => setSortAnchorEl(null)}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  setSortBy('newest');
                  setSortAnchorEl(null);
                }}
                selected={sortBy === 'newest'}
              >
                Newest First
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSortBy('alphabetical');
                  setSortAnchorEl(null);
                }}
                selected={sortBy === 'alphabetical'}
              >
                Alphabetical
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSortBy('type');
                  setSortAnchorEl(null);
                }}
                selected={sortBy === 'type'}
              >
                By Type
              </MenuItem>
            </Menu>
 
            {/* Active Filters Display */}
            {activeFilters.length > 0 && (
              <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {activeFilters.map(filter => (
                  <Chip
                    key={filter}
                    label={filter.charAt(0).toUpperCase() + filter.slice(1)}
                    onDelete={() => {
                      setActiveFilters(prev => prev.filter(f => f !== filter));
                    }}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ))}
                <Chip
                  label="Clear All"
                  onClick={() => setActiveFilters([])}
                  size="small"
                  color="error"
                />
              </Box>
            )}
 
            {/* Results Display */}
            <MDBox mt={2}>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : paginatedResults.length > 0 ? (
                <>
                  {viewMode === 'grid' ? (
                    <Grid container spacing={3}>
                    {paginatedResults.map((result) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={`${result.type}-${result.id}`}>
                        <GridViewItem result={result} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Stack spacing={2}>
                    {paginatedResults.map((result) => (
                      <ListViewItem key={`${result.type}-${result.id}`} result={result} />
                    ))}
                  </Stack>
                )}
               
                {/* Enhanced Pagination */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Showing {((page - 1) * ITEMS_PER_PAGE) + 1} to{' '}
                    {Math.min(page * ITEMS_PER_PAGE, processedResults.length)} of{' '}
                    {processedResults.length} results
                  </Typography>
                 
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(_, value) => {
                      setPage(value);
                      // Smooth scroll to top of results
                      searchRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    sx={{
                      '& .MuiPaginationItem-root': {
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      },
                    }}
                  />
                </Box>
              </>
            ) : searchQuery.length >= 2 && !isLoading ? (
              <Box
                sx={{
                  textAlign: 'center',
                  py: 8,
                  px: 3,
                  backgroundColor: 'grey.50',
                  borderRadius: 2
                }}
              >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No results found
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Try adjusting your search or filters to find what you're looking for
                </Typography>
                {activeFilters.length > 0 && (
                  <Button
                    variant="outlined"
                    onClick={() => setActiveFilters([])}
                    sx={{ mt: 2 }}
                  >
                    Clear Filters
                  </Button>
                )}
              </Box>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
    <Footer />
  </DashboardLayout>
);
}
 
export default SearchResults;