import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@mui/material";
import { Delete, FileCopy, Close, Edit, Save } from "@mui/icons-material";

// Import custom components - make sure these paths are correct
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
// import { Edit, Save } from 'lucide-react';
import Contacts from 'layouts/dashboard/components/Contacts';
import AddContactModal from 'components/AddContactModal';
import AttachmentUploadModal from 'components/CustomModals/AttachmentUploadModal';
import Attachments from 'layouts/dashboard/components/Attachments';
import ContactCard from './ContactCard';
import { formatDate } from 'utils/DateTimeConversion';





function AccountDetail() {
  const { user, axiosInstance } = useAuth();
  const [users, setUsers] = useState({}); // Add this to store user data
  const navigate = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [newAccountName, setNewAccountName] = useState('');
  const [nameError, setNameError] = useState('');
  const [isCheckingName, setIsCheckingName] = useState(false);
  const [managers, setManagers] = useState([]);
  // State for contact details dialog
  const [selectedContact, setSelectedContact] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [agents, setAgents] = useState([]);





  const cloneAnimationKeyframes = `
  @keyframes cloneAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
`;

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const fetchManagers = async () => {
    try {
      const response = await axiosInstance.get(`/users/`);
      const managersData = response.data.map(user => ({
        id: user.id,
        username: user.username,
      }));

      setManagers(managersData); // Set managers to an array of objects
      // console.log('managers:', managersData);
    } catch (error) {
      console.error('Error fetching managers:', error);
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };
  useEffect(() => {
    fetchAccountData();
    fetchContacts();
    fetchManagers();
    fetchAgents();
    fetchAttachments();
  }, [id]);



  // const fetchAccountData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axiosInstance.get(`/accounts/${id}`);
  //     setAccount(response.data);
  //     // console.log(`response.data for fetchAccountData:`, response.data);
  //   } catch (error) {
  //     console.error('Error fetching account data:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  const fetchAccountData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/accounts/${id}`);
      const accountData = response.data;
      console.log('Account data:', accountData);
      
  
      // // Enrich the account data by fetching creator and modifier names
      // const enrichedAccountData = { ...accountData };
  
      // // Check and fetch 'created_by' user details if needed
      // if (accountData.created_by_name && !users[accountData.created_by_name]) {
      //   const creatorDetails = await fetchUserDetails(accountData.created_by_name);
      //   if (creatorDetails) {
      //     setUsers(prev => ({
      //       ...prev,
      //       [accountData.created_by_name]: creatorDetails
      //     }));
      //     enrichedAccountData.created_by_name = creatorDetails.username; // Use username for the name
      //   }
      // } else if (accountData.created_by_name) {
      //   enrichedAccountData.created_by_name = users[accountData.created_by_name]?.username || 'Unknown';
      // }
  
      // // Check and fetch 'updated_by' user details if needed
      // if (accountData.updated_by_name && !users[accountData.updated_by_name]) {
      //   const modifierDetails = await fetchUserDetails(accountData.updated_by_name);
      //   if (modifierDetails) {
      //     setUsers(prev => ({
      //       ...prev,
      //       [accountData.updated_by_name]: modifierDetails
      //     }));
      //     enrichedAccountData.updated_by_name = modifierDetails.username; // Use username for the name
      //   }
      // } else if (accountData.updated_by_name) {
      //   enrichedAccountData.updated_by_name = users[accountData.updated_by_name]?.username || 'Unknown';
      // }
  
      setAccount(accountData); // Update the state with the enriched account data
    } catch (error) {
      console.error('Error fetching account data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  // Function to fetch user details by ID
  // const fetchUserDetails = async (userId) => {
  //   try {
  //     const response = await axiosInstance.get(`/users/${userId}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching user details:', error);
  //     return null;
  //   }
  // };
  

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get(`/accounts/${id}/contacts`);
      const contactDetails = response.data.map(contact => ({
        name: `${contact.first_name} ${contact.last_name}`.trim(),
        email: contact.email, // Extract email
        mobile: contact.mobile_number, // Extract mobile
        details: contact // Keep the whole contact object for details
      }));
      setContacts(contactDetails); // Update state with full contact details
      console.log('Processed contact details:', contactDetails);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };
  
  const handleAgentClick = (agentId) => {
    if (agentId) {
      navigate(`/profile/${agentId}`);
    } else {
      setSnackbar({
        open: true,
        message: 'This lead is not assigned to any agent.',
        severity: 'info',
      });
    }
  };


  // const handleContactClick = (contact) => {
  //   setSelectedContact(contact);
  //   setDialogOpen(true);
  // };

  const handleContactClick = (contact) => {
    // Navigate to contact detail page using the contact ID from contact.details
    navigate(`/contact/detail/${contact.details.id}`);
  };
  const ContactDetailsDialog = ({ open, onClose, contact }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Contact Details</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Name: {contact?.name}</Typography>
        <Typography variant="body2">Email: {contact?.details.email}</Typography>
        {/* Add more details as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );



  const handleEditToggle = () => {
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleFieldUpdate = (field, value) => {
    // setIsLoading(true);
    setUpdatedFields(prev => {
      if (value !== account[field]) {
        return { ...prev, [field]: value };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    console.log('Saving changes:', updatedFields);
    if (Object.keys(updatedFields).length > 0) {
      try {
        const response = await axiosInstance.put(`/accounts/${account.id}`, updatedFields);
        await fetchAccountData();
      } catch (error) {
        console.error('Error updating account:', error);
      } finally {
        setIsLoading(false);
        setIsEditing(false);
        setUpdatedFields({});
      }
    }
  };

  // Add this new function to check if name exists
  const checkNameExists = async (name) => {
    try {
      const response = await axiosInstance.get('/accounts/', {
        params: {
          name: name.trim() // Trim whitespace from name
        }
      });
      // Check if any account has exactly the same name (case-insensitive)
      return response.data.some(account =>
        account.name.toLowerCase() === name.trim().toLowerCase() &&
        account.id !== id  // Exclude current account from check
      );
    } catch (error) {
      console.error('Error checking account name:', error);
      throw error;
    }
  };


  const handleCloneClick = () => {
    setNewAccountName(account.name);
    setNameError('');
    setIsCloneModalOpen(true);
  };


  const handleDeleteAttachment = async (attachmentId) => {
    try {
      await axiosInstance.delete(`/attachments/${attachmentId}`);
      setAttachments(attachments.filter((attachment) => attachment.id !== attachmentId));
      setSnackbar({
        open: true,
        message: 'Attachment deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting attachment:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting attachment',
        severity: 'error',
      });
    }
  };

  const handleDownloadAttachment = async (attachment) => {
    try {

      // Fetch the file using the signed URL
      const response = await fetch(attachment.file_url);
      const blob = await response.blob();

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.file_name);

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading attachment:', error);
      setSnackbar({
        open: true,
        message: 'Error downloading attachment',
        severity: 'error',
      });
    }
  };
  const fetchAttachments = async () => {
    const parentTable = 'accounts';
    const parentId = id;
    try {
      const response = await axiosInstance.get('/attachments/', {
        params: {
          parent_table: parentTable,
          parent_id: parentId
          // skip: 0,
          // limit: 100,
          // sort_by: 'created_at',
          // sort_order: 'desc'
        }
      });

      setAttachments(response.data || []);

    } catch (error) {
      console.error('Error fetching attachments:', error);
      const errorMessage = error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message ||
        'Error fetching attachments';

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
        autoHideDuration: 5000
      });
    } finally {
      // If you have a loading state
      // setLoading(false);
    }
  };
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleNameChange = (event) => {
    const name = event.target.value;
    setNewAccountName(name);
    setNameError(''); // Clear any previous errors
  };



  const handleUploadSuccess = async (uploadData) => {
    console.log('Upload successful:', uploadData);

    const uploadDataPayload = {
      file_name: uploadData.file.name,
      file_type: uploadData.file.type,
      file_size: uploadData.file.size,
      bucket_name: 'attachments', // Replace with actual bucket name if different
      storage_path: uploadData.path,
      description: uploadData.description || '', // Optional, use default if no description is provided
      parent_table: 'accounts',  // Assuming the parent table is 'leads', modify as needed
      parent_id: id,  // Assuming the parent ID is the same as lead ID
      is_active: true,
      is_private: uploadData.isPrivate || false, // Default to false if not provided
      file_url: uploadData.url,
      signed_url_expiry: uploadData.signedUrlExpiry || null,
    };

    setIsAttachmentModalOpen(false);
    try {
      const response = await axiosInstance.post(`/attachments/`, uploadDataPayload);

      setSnackbar({
        open: true,
        message: 'File uploaded successfully!',
        severity: 'success',
      });

      // Update files or attachments state as needed
      // setFiles([...files, response.data]); // Uncomment if `files` state exists
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbar({
        open: true,
        message: 'Error uploading file',
        severity: 'error',
      });
    } finally {
      fetchAttachments();
    }
  };

  // const handleCloneAccount = async () => {
  //   if (!newAccountName.trim()) {
  //     setNameError('Account name is required');
  //     return;
  //   }
  
  //   setIsCloning(true);
  //   try {
  //     // First check if name exists
  //     const exists = await checkNameExists(newAccountName);
  //     console.log('Name exists check result:', exists);
  
  //     if (exists) {
  //       setNameError('An account with this name already exists');
  //       setIsCloning(false);
  //       return;
  //     }
  
  //     // If name doesn't exist, proceed with cloning
  //     const cloneData = {
  //       name: newAccountName.trim(),
  //       account_type: account.account_type,
  //       industry: account.industry,
  //       website: account.website,
  //       mobile: account.mobile_number,
  //       email: account.email,
  //       fax: account.fax,
  //       annual_revenue: account.annual_revenue,
  //       number_of_employees: account.number_of_employees,
  //       billing_address: account.billing_address,
  //       billing_city: account.billing_city,
  //       billing_state: account.billing_state,
  //       billing_country: account.billing_country,
  //       billing_postal_code: account.billing_postal_code,
  //       shipping_address: account.shipping_address,
  //       shipping_city: account.shipping_city,
  //       shipping_state: account.shipping_state,
  //       shipping_country: account.shipping_country,
  //       shipping_postal_code: account.shipping_postal_code,
  //       notes: account.notes,
  //       manager_id: user.id, // Set the manager_id to the current user's ID
  //       account_status: account.account_status,
  //       created_at: new Date().toISOString(), // Correctly set created_at
  //       updated_at: new Date().toISOString(),  // Correctly set updated_at
  //       created_by_name: user.username || "N/A",  // Assuming `user` has the logged-in user's info
  //       updated_by_name: user.username || "N/A",  // Similarly, use logged-in user's name for updated_by_name
  //       created_by: user.id,
  //       updated_by: user.id,
  //     };
      
  
  //     const response = await axiosInstance.post('/accounts/', cloneData);
  
  //     setIsCloneModalOpen(false);
  //     setSnackbar({
  //       open: true,
  //       message: 'Account cloned successfully!',
  //       severity: 'success',
  //     });
  
  //     // Wait for animation and snackbar before navigation
  //     setTimeout(() => {
  //       navigate(`/account/detail/${response.data.id}`);
  //     }, 1000);
  
  //   } catch (error) {
  //     console.error('Error during clone process:', error);
  //     setSnackbar({
  //       open: true,
  //       message: error.response?.data?.detail || 'Error cloning account. Please try again.',
  //       severity: 'error',
  //     });
  //     setNameError(error.response?.data?.detail || 'Error creating clone');
  //   } finally {
  //     setTimeout(() => {
  //       setIsCloning(false);
  //     }, 1000);
  //   }
  // };


  const handleCloneAccount = async () => {
    try {
      setIsCheckingName(true);
  
      // Check if the name already exists
      const nameExists = await checkNameExists(newAccountName);
      if (nameExists) {
        setNameError("An account with this name already exists. Please choose a different name.");
        return;
      }
  
      // If the name is unique, proceed with cloning
      const clonedAccount = { ...account };
      delete clonedAccount.id;
      clonedAccount.name = newAccountName;  // Use the user-entered unique name
      clonedAccount.account_status = "New";
      clonedAccount.created_at = new Date().toISOString();
      clonedAccount.updated_at = new Date().toISOString();
      clonedAccount.last_activity = new Date().toISOString();
  
      const response = await axiosInstance.post('/accounts/', clonedAccount);
  
      setSnackbar({
        open: true,
        message: 'Account cloned successfully!',
        severity: 'success',
      });
  
      setTimeout(() => {
        navigate(`/account/detail/${response.data.id}`);
      }, 1000);
    } catch (error) {
      console.error('Error cloning account:', error);
      setSnackbar({
        open: true,
        message: 'Error cloning account. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsCheckingName(false);
      setIsCloning(false);
    }
  };
  

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/accounts/${account.id}`);
      navigate('/accounts');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
    handleCloseDeleteModal();
  };

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };


  // const handleManagerClick = (manager.id) => {
  //   if (agentId) {
  //     navigate(`/profile/${agentId}`);
  //   } else {
  //     setSnackbar({
  //       open: true,
  //       message: 'This lead is not assigned to any agent.',
  //       severity: 'info',
  //     });
  //   }
  // };
  const [attachments, setAttachments] = useState([]);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);

  const handleUserClick = (userId, userName) => {
    if (userId) {
      navigate(`/profile/${userId}`);
    } else {
      setSnackbar({
        open: true,
        message: `Unable to find user information for ${userName}.`,
        severity: 'info',
      });
    }
  };

  const handleAddContact = () => {
    try {
      console.log('Adding contact...');
      handleCloseContactModal();
      const response = axiosInstance.post(`/contacts/`, {
        first_name: 'John',
        last_name: 'Doe',
        email: 'jHqCp@example.com',
      })




    } catch (error) {

    }
  };

  // const renderSection = (title, fields) => (
  //   <MDBox 
  //     mb={3} 
  //     p={3} 
  //     borderRadius="lg" 
  //     sx={{ 
  //       backgroundColor: 'white',
  //       boxShadow: 3
  //     }}
  //   >
  //     <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
  //       <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
  //     </MDBox>
  //     <Grid container spacing={3}>
  //       {fields.map((item) => (
  //         <Grid item xs={12} md={6} key={item.field}>
  //           <InlineEditField
  //             label={item.label}
  //             value={account?.[item.field] ?? ''}
  //             options={item.options ?? null}
  //             onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
  //             isEditing={isEditing}
  //             readOnly={item.readOnly ?? false}
  //           />
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </MDBox>
  // );

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) return 'Invalid Date';
    
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  
  const renderSection = (title, fields) => (
    <MDBox
      mb={3}
      p={3}
      borderRadius="lg"
      sx={{
        backgroundColor: 'white',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => {
          // Determine the display value based on field type
          let displayValue = account?.[item.field] ?? '';
          
          // Format dates for specific fields
          if (item.field === 'created_at' || item.field === 'updated_at') {
            displayValue = formatDate(displayValue, 'datetime');
          } 
          // else if (item.field === 'created_by_name') {
          //   displayValue = users[account?.created_by_name]?.username || 'Unknown';
          // } else if (item.field === 'updated_by_name') {
          //   displayValue = users[account?.updated_by_name]?.username || 'Unknown';
          // }

          return (
            <Grid item xs={12} md={6} key={item.field}>
              <InlineEditField
                label={item.label}
                value={displayValue}
                options={
                  item.field === 'manager_id'
                    ? agents.map(agent => ({ value: agent.id, label: agent.username }))
                    : item.options ?? null
                }
                onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
                isEditing={isEditing}
                readOnly={item.readOnly ?? false}
                renderValue={
                  (item.field === 'created_by_name' || item.field === 'updated_by_name') ?
                    (value) => (
                      <MDTypography
                        component="span"
                        onClick={() => {
                          const userId = item.field === 'created_by_name' ? 
                            account?.created_by : account?.updated_by;
                            
                          if (userId) {
                            navigate(`/profile/${userId}`);
                          }
                        }}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {value}
                      </MDTypography>
                    ) :
                  item.field === 'manager_id' ?
                    (value) => (
                      <MDTypography
                        component="span"
                        onClick={() => handleAgentClick(value)}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {agents.find(agent => agent.id === value)?.username || 'Unassigned'}
                      </MDTypography>
                    ) :
                    undefined
                }
              />
            </Grid>
          );
        })}
      </Grid>

    </MDBox>
  );


  // Add this function to fetch user details
  const fetchUserDetails = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card
              sx={{
                animation: isCloning ? 'cloneAnimation 1s ease' : 'none',
              }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Account Details: {account?.name}

                  </MDTypography>
                  <MDBox>
                    {!isEditing && (
                      <IconButton
                        aria-label="edit"
                        onClick={handleEditToggle}
                        sx={{
                          backgroundColor: "#f0f0f0",
                          borderRadius: "50%",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            "& .MuiSvgIcon-root": {
                              color: "#f0f0f0",
                            },
                          },
                        }}
                      >
                        <Edit sx={{ color: "#5bc0de" }} />
                      </IconButton>
                    )}
                    {isEditing && (

                      <Button
                        onClick={() => setIsEditing(false)}
                        variant="contained"
                        sx={{
                          mr: 2,
                          backgroundColor: "#f0f0f0",
                          color: "#d9534f",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            color: "#f0f0f0",
                          },
                        }}
                        startIcon={<Close />}
                      >
                        Discard
                      </Button>

                    )}
                    {isEditing && (

                      <Button
                        onClick={handleSaveChanges}
                        variant="contained"
                        sx={{
                          backgroundColor: "#f0f0f0",
                          color: "#000",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            color: "#f0f0f0",
                          },
                        }}
                        startIcon={<Save />}
                      >
                        Save
                      </Button>

                    )}
                    <Dialog
                      open={isCloneModalOpen}
                      onClose={() => !isCloning && setIsCloneModalOpen(false)}
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogTitle>Clone Account</DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="New Account Name"
                          type="text"
                          fullWidth
                          value={newAccountName}
                          onChange={(e) => {
                            setNewAccountName(e.target.value);
                            setNameError(''); // Clear error when user types
                          }}
                          error={!!nameError}
                          helperText={nameError}
                          disabled={isCloning}
                        />
                      </DialogContent>
                      <DialogActions>
                        <MDButton
                          onClick={() => setIsCloneModalOpen(false)}
                          color="secondary"
                          disabled={isCloning}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          onClick={handleCloneAccount}
                          variant="gradient"
                          color="info"
                          disabled={isCloning || !newAccountName.trim()}
                        >
                          {isCloning ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Clone'
                          )}
                        </MDButton>
                      </DialogActions>
                    </Dialog>
                    <IconButton
                      aria-label="clone"
                      onClick={handleCloneClick}  // Changed from handleCloneAccount
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                        marginVertical: "20px",
                        mx: 3,
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#0275d8",
                          "& .MuiSvgIcon-root": {
                            color: "#f0f0f0",
                          },
                        },
                      }}
                    >
                      <FileCopy sx={{ color: "#5cb85c" }} />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      onClick={handleOpenDeleteModal}
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#0275d8",
                          "& .MuiSvgIcon-root": {
                            color: "#f0f0f0",
                          },
                        },
                      }}
                    >
                      <Delete sx={{ color: "#d9534f" }} />
                    </IconButton>
                  </MDBox>

                </MDBox>
              </MDBox>
              <MDBox p={3}>
                {!isLoading ? (
                  <>
                    {renderSection("Basic Information", [
                      // { label: "Account ID", field: "id", readOnly: true },
                      { label: "Name", field: "name" },
                      {
                        label: "Owner", // Label as Owner
                        field: "manager_id", // Still use manager_id to access the manager
                        options: agents.map(a => ({ value: a.id, label: a.username }))
                      },
                      { label: "Account Type", field: "account_type", options: ["Customer", "Prospect", "Partner"] },
                      { label: "Industry", field: "industry", options: ["Automotive", "Banking", "Construction", "Education", "Financial Services", "Government", "Healthcare", "Manufacturing", "Media", "Retail", "Technology", "Travel", "Other"] },
                      { label: "Account Status", field: "account_status", options: ["Active", "Inactive", "Processing"] },
                      { label: "Website", field: "website" },
                      { label: "Mobile", field: "mobile_number" },
                      { label: "Email", field: "email" },
                      { label: "Fax", field: "fax" },
                      { label: "Notes", field: "notes" },
                    ])}

                    {renderSection("Financial Information", [
                      { label: "Annual Revenue", field: "annual_revenue" },
                      { label: "Number of Employees", field: "number_of_employees" },
                    ])}

                    {renderSection("Billing Address", [
                      { label: "Address", field: "billing_address" },
                      { label: "City", field: "billing_city" },
                      { label: "State", field: "billing_state" },
                      { label: "Country", field: "billing_country" },
                      { label: "Postal Code", field: "billing_postal_code" },
                    ])}

                    {renderSection("Shipping Address", [
                      { label: "Address", field: "shipping_address" },
                      { label: "City", field: "shipping_city" },
                      { label: "State", field: "shipping_state" },
                      { label: "Country", field: "shipping_country" },
                      { label: "Postal Code", field: "shipping_postal_code" },
                    ])}

                    {renderSection("Audit Information", [
                      { label: "Created By", field: "created_by_name", readOnly: true },
                      { label: "Modified By", field: "updated_by_name", readOnly: true },
                      { label: "Created Date", field: "created_at", readOnly: true },
                      { label: "Modified Date", field: "updated_at", readOnly: true },
                    ])}
                    {/* {renderSection("Description", [
                      { label: "Notes", field: "notes" },
                      { label: "Attachments", field: "attachments", readOnly: true },
                    ])} */}

                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>


            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Contacts
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenContactModal}>
                  Add Contact
                </MDButton>
              </MDBox>
              <Box
                sx={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  borderRadius: '4px',
                  padding: '1rem',
                }}
              >
                {contacts
                  .slice()
                  .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at)) // Sort by created_at or updated_at in ascending order
                  .map((contact, index) => (
                    <div 
                      key={index} 
                      onClick={() => handleContactClick(contact)}
                      style={{ 
                        cursor: 'pointer',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                        }
                      }}
                    >
                      <ContactCard
                        contactName={contact.name}
                        contactEmail={contact.email}
                        contactMobile={contact.mobile}
                      />
                    </div>
                  ))}
              </Box>

{/* 
              <ContactDetailsDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                contact={selectedContact}
              /> */}
            </Card>
            <Card style={{ marginTop: '10px' }}>
              <MDBox
                mx={2}
                mt={2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Attachments
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={() => setIsAttachmentModalOpen(true)}>
                  Add Attachment
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <Attachments
                  attachments={attachments}
                  onDelete={handleDeleteAttachment}
                  onDownload={handleDownloadAttachment}
                />
              </MDBox>
            </Card>
            <AttachmentUploadModal
              isOpen={isAttachmentModalOpen}
              onClose={() => setIsAttachmentModalOpen(false)}
              onUpload={handleUploadSuccess}
              axiosInstance={axiosInstance}
              allowedFileTypes="image/*,application/pdf"
              maxSizeInMB={25}
            />
          </Grid>

        </Grid>
      </MDBox>

      {/* Add Contact Modal */}
      {/* <Modal
        open={isContactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="contact-modal-title" variant="h6" component="h2" mb={2} onClick={handleOpenContactModal}>
            Add Contact
          </MDTypography>
          <MDTypography id="contact-modal-description" mb={4}>
            Add a new contact for this account.
          </MDTypography>
            <CustomInputForm
              fields={fields}
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseContactModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleAddContact} variant="gradient" color="info">
              Add Contact
            </MDButton>
          </MDBox>
        </Box>
      </Modal> */}

      <AddContactModal
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
        accountId={id}
        onContactAdded={fetchContacts}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
            Confirm Deletion
          </MDTypography>
          <MDTypography id="delete-modal-description" mb={4}>
            Are you sure you want to delete this account? This action cannot be undone.
          </MDTypography>
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleDelete} variant="contained" color="error">
              Delete
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default AccountDetail;