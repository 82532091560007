import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Modal,
  Box,
  TextField,
  MenuItem,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Icon from "@mui/material/Icon";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';
import CustomInputForm from 'components/CustomInputForm';
import { currencies } from 'utils/CurrencyList';
import AccountFormModal from 'components/NewAccountForm';

function Accounts() {

  const defaultCurrency = currencies.find(c => c.code === 'INR') || null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setfilteredAccounts] = useState([]);
  const [managers, setManagers] = useState([]); // Change this to an array
  const [managerList, setManagerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user, axiosInstance } = useAuth();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/accounts/`);
      const transformedAccounts = transformAccountData(response.data);
      setAccounts(transformedAccounts);
      setfilteredAccounts(transformedAccounts);
    } catch (error) {
      console.error('Error fetching accounts data:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching accounts. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await axiosInstance.get(`/users/`);
      const managersData = response.data.map(user => ({
        id: user.id,
        username: user.username,
      }));
  
      setManagers(managersData); // Set managers to an array of objects
      console.log('managers:', managersData);
    } catch (error) {
      console.error('Error fetching managers:', error);
    }
  };
  

  const transformAccountData = (serverData) => {
    return serverData.map(account => ({
      id: account.id,
      manager_id: account.manager_id,
      name: account.name,
      industry: account.industry,
      account_status: account.account_status,
      annual_revenue: account.annual_revenue,
      updated_at: account.updated_at ? account.updated_at.split('T')[0] : "N/A",
      created_at: account.created_at ? account.created_at.split('T')[0] : "N/A", // Add created_at
      created_by_name: account?.created_by_name || "N/A",
      updated_by_name: account?.updated_by_name || "N/A",
      created_by: user?.id,
      updated_by: user?.id,
    }));
  };
  
  useEffect(() => {
    fetchAccounts();
    fetchManagers();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      setLoadingCountries(true);
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const sortedCountries = response.data
          .map(country => ({
            code: country.cca2,
            label: country.name.common
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
      setLoadingCountries(false);
    };

    fetchCountries();
  }, []);

  // Modal handlers
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const handleSaveAccount = async (formData) => {
    
  //   setIsLoading(true);
  //   handleCloseModal();
  //   try {
    
  //     // Remove any properties with undefined values
  //     // Object.keys(formData).forEach(key => formData[key] === undefined && delete formData[key]);
    
  //     const response = await axiosInstance.post(`/accounts/`, formData);
      
  //     await fetchAccounts();
  //     setSnackbar({
  //       open: true,
  //       message: 'Account created successfully.',
  //       severity: 'success',
  //     })
  //   } catch (error) {
  //     console.error('Error creating new account:', error);
  //     setSnackbar({
  //       open: true,
  //       message: 'Error creating new account. Please try again.',
  //       severity: 'error',
  //     })
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveAccount = async (formData) => {
    setIsLoading(true);
    handleCloseModal();
  
    try {
      // Create a new object with the form data and additional fields
      const accountData = {
        ...formData,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        created_by_name: user?.username || "N/A",
        updated_by_name: user?.username || "N/A",
        created_by: user?.id,
        updated_by: user?.id
      };
  
      // Remove any undefined fields
      Object.keys(accountData).forEach(key => {
        if (accountData[key] === undefined) delete accountData[key];
      });
  
      const response = await axiosInstance.post(`/accounts/`, accountData);
      
      // Transform and add the new account to the state
      const newAccount = transformAccountData([response.data])[0];
      setAccounts(prevAccounts => [...prevAccounts, newAccount]);
      setfilteredAccounts(prevAccounts => [...prevAccounts, newAccount]);
  
      setSnackbar({
        open: true,
        message: 'Account created successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error creating new account:', error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'Error creating new account. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  


  // Navigation handler
  const handleRowClick = (rowData) => {
    navigate(`/account/detail/${rowData.id}`);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

 
  // Update the accountsTableData
  const accountsTableData = useMemo(() => ({
    columns: [
      // { Header: "ID", accessor: "id", width: "1%" },
      { 
        Header: "Name", 
        accessor: "name", 
        width: "15%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {row.original.name}
          </MDTypography>
        )
      },
      { Header: "Industry", accessor: "industry", width: "15%" },
      { Header: "Account Status", accessor: "account_status", width: "10%" },
      { Header: "Annual Revenue", accessor: "annual_revenue", width: "10%" },
      { 
        Header: "Owner", 
        accessor: "manager_id", 
        width: "10%",
        Cell: ({ row }) => {
          const manager = managers.find(manager => manager.id === row.original.manager_id);
          return (
            <MDTypography 
              component="span" 
              onClick={() => manager && navigate(`/profile/${manager.id}`)}
              variant="caption" 
              color="blue" 
              fontWeight="medium"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {manager ? manager.username : " "}
            </MDTypography>
          );// Fallback if no manager is found
        }
      },
      { 
        Header: "Last Modified Date", 
        accessor: "updated_at", 
        width: "10%",
        Cell: ({ value }) => (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {value}
          </MDTypography>
        )
      },
    ],
    rows: filteredAccounts,
  }), [filteredAccounts, managers]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="business"
                title="Total Accounts"
                count={accounts.length}
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="check_circle"
                title="Active Accounts"
                count={accounts.filter(account => account.account_status === "Active").length}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="Account Growth"
                count="7.5%"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="New Accounts"
                count="25"
                percentage={{
                  color: "success",
                  amount: "+10%",
                  label: "than last quarter",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        
        <MDBox mt={4.5}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Accounts
                  </MDTypography>
                  <MDButton variant="gradient" color="dark" onClick={handleOpenModal}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Add New Account
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  {isLoading ? (
                    <TableSkeleton />
                  ) : (
                    <>
                      <DataTable
                        table={accountsTableData}
                        isSorted={true}
                        entriesPerPage={{
                          defaultValue: 10,
                          entries: [5, 10, 15, 20, 25],
                        }}
                        showTotalEntries
                        pagination={{ variant: "gradient", color: "info" }}
                        noEndBorder
                        canSearch
                        searchPlaceholder="Search Account"
                        exportCSV={true}
                      />
                    </>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Modal for adding new account */}
      {/* <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 800,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        borderRadius: 2,
      }}>
        <MDTypography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Add New Account
        </MDTypography>

        <CustomInputForm 
          fields={fields}
          onSubmit={handleSaveAccount}
          onCancel={handleCloseModal}
        />

      </Box>
    </Modal> */}

      <AccountFormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveAccount}
        managers={managers}
        currencies={currencies}
        countries={countries}
        loadingCountries={loadingCountries}
        defaultCurrency={defaultCurrency}
        user={user}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </DashboardLayout>
  );
}

export default Accounts;