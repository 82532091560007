import React, { useState } from "react";
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Box, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DocumentIcon from '@mui/icons-material/Description';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";
import { formatDate } from "utils/DateTimeConversion";

function Attachments({ 
  attachments = [], 
  onDelete, 
  onDownload,
}) {
  const [showUpload, setShowUpload] = useState(false);

  const getFileIcon = (fileType) => {
    if (fileType?.includes('image')) return <ImageIcon />;
    if (fileType?.includes('pdf')) return <PdfIcon />;
    if (fileType?.includes('document') || fileType?.includes('word')) return <DocumentIcon />;
    return <FileIcon />;
  };

  const getFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleUploadSuccess = (data) => {
    // You might want to trigger a refresh of the attachments list here
    console.log('Upload success:', data);
    setShowUpload(false);
  };

  const handleUploadError = (error) => {
    console.error('Upload error:', error);
    // Handle error appropriately - maybe show a notification
  };

  return (
    <MDBox p={2}>
      {attachments.length > 0 ? (
        <MDBox
          sx={{
            maxHeight: '300px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          }}
        >
          {attachments.map((attachment, index) => (
            <TimelineItem
              key={attachment.id || index}
              color="info"
              icon={getFileIcon(attachment.file_type)}
              title={
                <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <MDBox>
                    <MDTypography variant="button" fontWeight="medium">
                      {attachment.file_name}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                      {getFileSize(attachment.file_size)}
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Tooltip title="Download">
                      <IconButton
                        size="small"
                        onClick={() => onDownload(attachment)}
                        sx={{ color: 'info.main', mr: 1 }}
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(attachment.id);
                        }}
                        sx={{ 
                          color: 'error.main',
                          '&:hover': {
                            backgroundColor: 'error.light',
                            color: 'white',
                          }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              }
              dateTime={formatDate(attachment.created_at, 'datetime')}
            />
          ))}
        </MDBox>
      ) : (
        <MDTypography variant="body2" color="text">
          No attachments to display.
        </MDTypography>
      )}
    </MDBox>
  );
}

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file_name: PropTypes.string,
      file_type: PropTypes.string,
      file_size: PropTypes.number,
      created_at: PropTypes.string,
    })
  ),
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  bucketName: PropTypes.string,
  folderPath: PropTypes.string,
};

export default Attachments;