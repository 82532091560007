// import React from 'react';
// import { Card, CardContent, Typography, IconButton } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete'; // Optional: For a delete icon
// import { ContactPhone } from '@mui/icons-material';
// import GroupIcon from '@mui/icons-material/Group'; // Importing profile icon

// const ContactCard = ({ contactName, contactEmail, contactPhone, onDelete }) => {
//   return (
//     <Card 
//       variant="outlined" 
//       sx={{ 
//         marginBottom: 1, 
//         position: 'relative', 
//         backgroundColor: '#f5f5f5' // Off-white background color
//       }}
//     >
//       <CardContent>
//         <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
//           <GroupIcon sx={{ marginRight: 1 }} /> {/* Profile icon */}
//           {contactName} {/* Display contact name */}
//         </Typography>
//         <Typography color="text.secondary" sx={{ fontSize: '14px' }}> {/* Updated font size */}
//           Phone: {contactPhone}
//         </Typography>
//         <Typography color="text.secondary" sx={{ fontSize: '14px' }}> {/* Updated font size */}
//           Email: {contactEmail}
//         </Typography>
        
//         {/* Optional: Add a delete button if you want to remove contacts */}
//         {onDelete && (
//           <IconButton 
//             aria-label="delete" 
//             onClick={onDelete} 
//             sx={{ position: 'absolute', right: 8, top: 8 }} // Adjust position as needed
//           >
//             <DeleteIcon />
//           </IconButton>
//         )}
//       </CardContent>
//     </Card>
//   );
// };

// export default ContactCard;


import React from 'react';
import { Card, CardContent, Typography, IconButton, Box, Divider } from '@mui/material';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import DeleteIcon from '@mui/icons-material/Delete'; // Optional: For a delete icon
import { Email } from '@mui/icons-material'; // Importing email icon
import GroupIcon from '@mui/icons-material/Group'; // Importing profile icon

const ContactCard = ({ contactName, contactEmail, contactMobile, onDelete }) => {
  return (
    <Card 
      variant="outlined" 
      sx={{ 
        marginBottom: 1, 
        position: 'relative',
        border: '1px solid #e0e0e0', 
      }}
    >
      <CardContent>
        {/* Centering the contact name */}
        <Typography variant="h6" component="div" sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          marginBottom: 1 
        }}>
          <GroupIcon sx={{ marginRight: 1, fontSize: 'medium' }} /> {/* Profile icon */}
          {contactName} {/* Display contact name */}
        </Typography>

        {/* Horizontal line below the name */}
        <Divider sx={{ marginBottom: 1, marginTop: 1, width: '100%', height: '2px' }} />
        {/* <Divider sx={{ 
          marginBottom: 1, 
          width: '75%', 
          marginLeft: 'auto', 
          marginRight: 'auto', 
          backgroundColor: '#333', // Darker color for the line
          height: '2px', // Optional: Adjust line thickness
        }} /> */}
        {/* Display phone and email, with email below phone */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            <LocalPhoneRoundedIcon sx={{ marginRight: 1, color: '#5181EF' }} /> {/* Phone icon */}
            <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
              {contactMobile}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Email sx={{ marginRight: 1, color: '#FF5062' }} /> {/* Email icon */}
            <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
              {contactEmail}
            </Typography>
          </Box>
        </Box>

        {/* Optional: Add a delete button if you want to remove contacts */}
        {onDelete && (
          <IconButton 
            aria-label="delete" 
            onClick={onDelete} 
            sx={{ position: 'absolute', right: 8, top: 8 }} // Adjust position as needed
          >
            <DeleteIcon />
          </IconButton>
        )}
      </CardContent>
    </Card>
  );
};

export default ContactCard;
