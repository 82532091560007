// import { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from 'auth/Auth';
// import {
//   Box,
//   CircularProgress,
//   ClickAwayListener,
//   Paper,
//   List,
//   ListItem,
//   ListItemText,
//   Typography,
//   Popper,
//   Fade,
// } from '@mui/material';
// import MDInput from "components/MDInput";
// import PersonIcon from '@mui/icons-material/Person';
// import BusinessIcon from '@mui/icons-material/Business';
// import HandshakeIcon from '@mui/icons-material/Handshake';

// const SearchComponent = () => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   const searchRef = useRef(null);
//   const navigate = useNavigate();
//   const { axiosInstance } = useAuth();

//   // Debounce search
//   useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       if (searchQuery.length >= 2) {
//         performSearch();
//       } else {
//         setSearchResults([]);
//         setOpen(false);
//       }
//     }, 300);

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchQuery]);

//   const performSearch = async () => {
//     setLoading(true);
//     try {
//       const response = await axiosInstance.get(`/search?query=${encodeURIComponent(searchQuery)}`);
//       setSearchResults(response.data);
//       setOpen(true);
//     } catch (error) {
//       console.error('Search error:', error);
//       setSearchResults([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleResultClick = (result) => {
//     switch (result.type) {
//       case 'leads':
//         navigate(`/lead/detail/${result.id}`);
//         break;
//       case 'users':
//         navigate(`/profile/${result.id}`);
//         break;
//       case 'deals':
//         navigate(`/deal/detail/${result.id}`);
//         break;
//       default:
//         break;
//     }
//     setOpen(false);
//     setSearchQuery('');
//   };

//   const getIcon = (type) => {
//     switch (type) {
//       case 'leads':
//         return <BusinessIcon color="primary" sx={{ mr: 2 }} />;
//       case 'users':
//         return <PersonIcon color="info" sx={{ mr: 2 }} />;
//       case 'deals':
//         return <HandshakeIcon color="success" sx={{ mr: 2 }} />;
//       default:
//         return null;
//     }
//   };

//   const getResultText = (result) => {
//     switch (result.type) {
//       case 'leads':
//         return `${result.first_name} ${result.last_name} - ${result.company_name}`;
//       case 'users':
//         return `${result.username} (${result.email})`;
//       case 'deals':
//         return result.name;
//       default:
//         return '';
//     }
//   };

//   return (
//     <Box ref={searchRef} sx={{ position: 'relative', width: '100%' }}>
//       <MDInput
//         fullWidth
//         label="Search"
//         value={searchQuery}
//         onChange={handleSearchChange}
//         InputProps={{
//           endAdornment: loading && <CircularProgress size={20} />,
//         }}
//       />
      
//       <Popper
//         open={open && searchResults.length > 0}
//         anchorEl={searchRef.current}
//         transition
//         placement="bottom-start"
//         style={{ width: searchRef.current?.offsetWidth, zIndex: 1301 }}
//       >
//         {({ TransitionProps }) => (
//           <Fade {...TransitionProps} timeout={350}>
//             <Paper elevation={3} sx={{ mt: 1, maxHeight: '400px', overflow: 'auto' }}>
//               <ClickAwayListener onClickAway={() => setOpen(false)}>
//                 <List>
//                   {searchResults.map((result, index) => (
//                     <ListItem
//                       key={`${result.type}-${result.id}`}
//                       button
//                       // component="button"
//                       onClick={() => handleResultClick(result)}
//                       divider={index < searchResults.length - 1}
//                       sx={{
//                         '&:hover': {
//                           backgroundColor: 'action.hover',
//                         },
//                       }}
//                     >
//                       {getIcon(result.type)}
//                       <ListItemText
//                         primary={getResultText(result)}
//                         secondary={
//                           <Typography variant="caption" color="text.secondary">
//                             {result.type.charAt(0).toUpperCase() + result.type.slice(1)}
//                           </Typography>
//                         }
//                       />
//                     </ListItem>
//                   ))}
//                 </List>
//               </ClickAwayListener>
//             </Paper>
//           </Fade>
//         )}
//       </Popper>
//     </Box>
//   );
// };

// export default SearchComponent;






// import { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from 'auth/Auth';
// import {
//   Box,
//   CircularProgress,
//   ClickAwayListener,
//   Paper,
//   List,
//   ListItem,
//   ListItemText,
//   Typography,
//   Popper,
//   Fade,
//   Button
// } from '@mui/material';
// import MDInput from "components/MDInput";
// import PersonIcon from '@mui/icons-material/Person';
// import BusinessIcon from '@mui/icons-material/Business';
// import HandshakeIcon from '@mui/icons-material/Handshake';
// import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
// import ContactsIcon from '@mui/icons-material/Contacts';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// const SearchComponent = () => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   const searchRef = useRef(null);
//   const navigate = useNavigate();
//   const { axiosInstance } = useAuth();

//   useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       if (searchQuery.length >= 2) {
//         performSearch();
//       } else {
//         setSearchResults([]);
//         setOpen(false);
//       }
//     }, 300);

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchQuery]);

//   const performSearch = async () => {
//     setLoading(true);
//     try {
//       const response = await axiosInstance.get(`/search?query=${encodeURIComponent(searchQuery)}`);
//       setSearchResults(response.data);
//       setOpen(true);
//     } catch (error) {
//       console.error('Search error:', error);
//       setSearchResults([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleResultClick = (result) => {
//     switch (result.type) {
//       case 'leads':
//         navigate(`/lead/detail/${result.id}`);
//         break;
//       case 'users':
//         navigate(`/profile/${result.id}`);
//         break;
//       case 'deals':
//         navigate(`/deal/detail/${result.id}`);
//         break;
//       case 'products':
//         navigate(`/product/${result.id}`);
//         break;
//       case 'accounts':
//         navigate(`/account/${result.id}`);
//         break;
//       case 'contacts':
//         navigate(`/contact/${result.id}`);
//         break;
//       default:
//         break;
//     }
//     setOpen(false);
//     setSearchQuery('');
//   };

//   const handleViewAll = () => {
//     navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
//     setOpen(false);
//     setSearchQuery('');
//   };

//   const getIcon = (type) => {
//     switch (type) {
//       case 'leads':
//         return <BusinessIcon color="primary" sx={{ mr: 1 }} />;
//       case 'users':
//         return <PersonIcon color="info" sx={{ mr: 1 }} />;
//       case 'deals':
//         return <HandshakeIcon color="success" sx={{ mr: 1 }} />;
//       case 'products':
//         return <ShoppingBasketIcon color="warning" sx={{ mr: 1 }} />;
//       case 'accounts':
//         return <AccountBalanceIcon color="secondary" sx={{ mr: 1 }} />;
//       case 'contacts':
//         return <ContactsIcon color="error" sx={{ mr: 1 }} />;
//       default:
//         return null;
//     }
//   };

//   const getResultText = (result) => {
//     switch (result.type) {
//       case 'leads':
//         return `${result.first_name} ${result.last_name} - ${result.company_name}`;
//       case 'users':
//         return `${result.username} (${result.email})`;
//       case 'deals':
//         return result.name;
//       case 'products':
//         return `${result.name} - $${result.price}`;
//       case 'accounts':
//         return `${result.name} (${result.account_number})`;
//       case 'contacts':
//         return `${result.first_name} ${result.last_name}`;
//       default:
//         return '';
//     }
//   };

//   return (
//     <Box ref={searchRef} sx={{ position: 'relative', width: '100%' }}>
//       <MDInput
//         fullWidth
//         label="Search"
//         value={searchQuery}
//         onChange={handleSearchChange}
//         InputProps={{
//           endAdornment: loading && <CircularProgress size={20} />,
//         }}
//       />
      
//       <Popper
//         open={open && searchResults.length > 0}
//         anchorEl={searchRef.current}
//         transition
//         placement="bottom-start"
//         style={{ width: searchRef.current?.offsetWidth, zIndex: 1301 }}
//       >
//         {({ TransitionProps }) => (
//           <Fade {...TransitionProps} timeout={350}>
//             <Paper elevation={3} sx={{ mt: 1, maxHeight: '400px', overflow: 'auto' }}>
//               <ClickAwayListener onClickAway={() => setOpen(false)}>
//                 <List sx={{ py: 0 }}>
//                   {searchResults.map((result, index) => (
//                     <ListItem
//                       key={`${result.type}-${result.id}`}
//                       button
//                       onClick={() => handleResultClick(result)}
//                       divider={index < searchResults.length - 1}
//                       sx={{
//                         py: 1,
//                         '&:hover': {
//                           backgroundColor: 'action.hover',
//                         },
//                       }}
//                     >
//                       {getIcon(result.type)}
//                       <ListItemText
//                         primary={
//                           <Typography variant="body2">
//                             {getResultText(result)}
//                           </Typography>
//                         }
//                         secondary={
//                           <Typography variant="caption" color="text.secondary">
//                             {result.type.charAt(0).toUpperCase() + result.type.slice(1)}
//                           </Typography>
//                         }
//                         sx={{ my: 0 }}
//                       />
//                     </ListItem>
//                   ))}
//                   <ListItem
//                     sx={{
//                       justifyContent: 'center',
//                       py: 1,
//                       borderTop: '1px solid',
//                       borderColor: 'divider'
//                     }}
//                   >
//                     <Button
//                       onClick={handleViewAll}
//                       size="small"
//                       color="primary"
//                     >
//                       View All Results
//                     </Button>
//                   </ListItem>
//                 </List>
//               </ClickAwayListener>
//             </Paper>
//           </Fade>
//         )}
//       </Popper>
//     </Box>
//   );
// };

// export default SearchComponent;




import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'auth/Auth';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Popper,
  Fade,
  Button,
  Divider
} from '@mui/material';
import MDInput from "components/MDInput";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { axiosInstance } = useAuth();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery.length >= 2) {
        performSearch();
      } else {
        setSearchResults([]);
        setOpen(false);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const performSearch = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/search?query=${encodeURIComponent(searchQuery)}`);
      // Limit to 7 results
      setSearchResults(response.data.slice(0, 7));
      setOpen(true);
    } catch (error) {
      console.error('Search error:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleResultClick = (result) => {
    switch (result.type) {
      case 'leads':
        navigate(`/lead/detail/${result.id}`);
        break;
      case 'users':
        navigate(`/profile/${result.id}`);
        break;
      case 'deals':
        navigate(`/deal/detail/${result.id}`);
        break;
      case 'products':
        navigate(`/products/${result.id}`);
        break;
      case 'accounts':
        navigate(`/account/detail/${result.id}`);
        break;
      case 'contacts':
        navigate(`/contact/detail/${result.id}`);
        break;
      default:
        break;
    }
    setOpen(false);
    setSearchQuery('');
  };

  const handleViewAll = () => {
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    setOpen(false);
    setSearchQuery('');
  };

  const getIcon = (type) => {
    switch (type) {
      case 'leads':
        return <BusinessIcon color="primary" sx={{ mr: 1 }} />;
      case 'users':
        return <PersonIcon color="info" sx={{ mr: 1 }} />;
      case 'deals':
        return <HandshakeIcon color="success" sx={{ mr: 1 }} />;
      case 'products':
        return <ShoppingBasketIcon color="warning" sx={{ mr: 1 }} />;
      case 'accounts':
        return <AccountBalanceIcon color="secondary" sx={{ mr: 1 }} />;
      case 'contacts':
        return <ContactsIcon color="error" sx={{ mr: 1 }} />;
      default:
        return null;
    }
  };

  const getResultText = (result) => {
    switch (result.type) {
      case 'leads':
        return `${result.first_name} ${result.last_name} - ${result.company_name}`;
      case 'users':
        return `${result.username} (${result.email})`;
      case 'deals':
        return result.name;
      case 'products':
        return `${result.name} - $${result.price}`;
      case 'accounts':
        return `${result.name} (${result.account_number})`;
      case 'contacts':
        return `${result.first_name} ${result.last_name}`;
      default:
        return '';
    }
  };

  return (
    <Box ref={searchRef} sx={{ position: 'relative', width: '100%' }}>
      <MDInput
        fullWidth
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: loading && <CircularProgress size={20} />,
        }}
      />
      
      <Popper
        open={open && searchResults.length > 0}
        anchorEl={searchRef.current}
        transition
        placement="bottom-start"
        style={{ width: searchRef.current?.offsetWidth, zIndex: 1301 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={3} sx={{ mt: 1, maxHeight: '400px', overflow: 'auto' }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <List sx={{ py: 0 }}>
                  <ListItem
                    sx={{
                      justifyContent: 'center',
                      py: 1,
                    }}
                  >
                    <Button
                      onClick={handleViewAll}
                      size="small"
                      color="primary"
                      fullWidth
                    >
                      View All Results
                    </Button>
                  </ListItem>
                  <Divider />
                  {searchResults.map((result, index) => (
                    <ListItem
                      key={`${result.type}-${result.id}`}
                      button
                      onClick={() => handleResultClick(result)}
                      divider={index < searchResults.length - 1}
                      sx={{
                        py: 1,
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                      }}
                    >
                      {getIcon(result.type)}
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            {getResultText(result)}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="text.secondary">
                            {result.type.charAt(0).toUpperCase() + result.type.slice(1)}
                          </Typography>
                        }
                        sx={{ my: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default SearchComponent;