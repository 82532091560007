import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { formatTimeForDisplay, getCurrentDateTime, formatDate } from 'utils/DateTimeConversion';

const ActivityModal = ({ isOpen, onClose, parent, parentId, axiosInstance, onActivitySaved }) => {
  const [newActivity, setNewActivity] = useState({
    type: '',
    status: 'Scheduled',
    description: '',
    due_date: getCurrentDateTime().slice(0, 16),
    starttime: '',
    endtime: '',
  });

  const [isDateValid, setIsDateValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    validateDateAndStatus();
  }, [newActivity.due_date, newActivity.status]);

  const validateDateAndStatus = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dueDate = new Date(newActivity.due_date);

    if (dueDate > today && newActivity.status === 'Completed') {
      setIsDateValid(false);
    } else if (dueDate < today && newActivity.status === 'Scheduled') {
      setIsDateValid(false);
    } else {
      setIsDateValid(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewActivity({ ...newActivity, [name]: value });
  };

  const removeEmptyFields = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== '' && value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handleSaveActivity = async () => {
    if (!isDateValid) return;

    setIsLoading(true);
    try {
      const cleanedActivity = removeEmptyFields(newActivity);
      const activityData = {
        ...cleanedActivity,
        parent_table: parent,
        parent_table_id: parentId,
      };
      
      console.log(`Saving activity:`, activityData);
      
      const currentDate = getCurrentDateTime();
      const response = await axiosInstance.post(`/activities/`, activityData);

      console.log(`Activity saved:`, response.data);
      
      await axiosInstance.patch(`/${parent}/${parentId}`, {
        last_activity: currentDate,
      });

      onActivitySaved(response.data);
      handleClose();
    } catch (error) {
      console.error('Error saving activity:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setNewActivity({
      type: '',
      status: 'Scheduled',
      description: '',
      due_date: getCurrentDateTime().slice(0, 16),
      starttime: '',
      endtime: '',
    });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="activity-modal-title"
      aria-describedby="activity-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <MDTypography id="activity-modal-title" variant="h6" component="h2" mb={2}>
          Add New Activity
        </MDTypography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="activity-type-label">Activity Type</InputLabel>
          <Select
            labelId="activity-type-label"
            id="activity-type"
            name="type"
            value={newActivity.type}
            label="Activity Type"
            onChange={handleInputChange}
            style={{ padding: '10px' }}
          >
            <MenuItem value="Call">Call</MenuItem>
            <MenuItem value="Meeting">Meeting</MenuItem>
            <MenuItem value="Email">Email</MenuItem>
            <MenuItem value="Task">Task</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="activity-status-label">Activity Status</InputLabel>
          <Select
            labelId="activity-status-label"
            id="activity-status"
            name="status"
            value={newActivity.status}
            label="Activity Status"
            onChange={handleInputChange}
            style={{ padding: '10px' }}
          >
            <MenuItem value="Scheduled">Scheduled</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={newActivity.description}
          onChange={handleInputChange}
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          label="Due Date & Time"
          name="due_date"
          type="datetime-local"
          value={newActivity.due_date}
          onChange={handleInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={!isDateValid}
          helperText={
            !isDateValid 
              ? "Invalid date for the selected status" 
              : newActivity.due_date 
                ? `Selected: ${formatDate(newActivity.due_date, 'datetime', 'en-US', false)}`
                : ""
          }
        />
        {newActivity.type === 'Meeting' && (
          <>
            <TextField
              fullWidth
              label="Start Time"
              name="starttime"
              type="time"
              value={newActivity.starttime}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              helperText={newActivity.starttime ? formatTimeForDisplay(newActivity.starttime) : ''}
              inputProps={{
                step: 300 // 5 min intervals
              }}
            />
            <TextField
              fullWidth
              label="End Time"
              name="endtime"
              type="time"
              value={newActivity.endtime}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              helperText={newActivity.endtime ? formatTimeForDisplay(newActivity.endtime) : ''}
              inputProps={{
                step: 300 // 5 min intervals
              }}
            />
          </>
        )}
        <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
          <MDButton onClick={handleClose} color="secondary" sx={{ marginRight: 2 }}>
            Cancel
          </MDButton>
          <MDButton 
            onClick={handleSaveActivity} 
            variant="gradient" 
            color="info" 
            disabled={!isDateValid || isLoading}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save Activity'}
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );
};

export default ActivityModal;