
import { useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Box,
  Button,
  InputAdornment,
  FormLabel,
  Chip,
  Autocomplete,
  Typography,
  IconButton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MDButton from 'components/MDButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomInputForm = ({ fields, onSubmit, onCancel }) => {
  
  // const [formData, setFormData] = React.useState({});

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//   setFormData((prevFormData) => ({
//     ...prevFormData,
//     [name]: name === 'tags' ? value.split(',').map(item => item.trim()) : value
//   }));
// };

const [formData, setFormData] = useState({});

const handleInputChange = (event, fieldName, index, subFieldName) => {
  const { value } = event.target;
  setFormData((prevData) => {
    if (index !== undefined && subFieldName) {
      // Handle array field changes
      const newItems = [...(prevData[fieldName] || [])];
      newItems[index] = { ...newItems[index], [subFieldName]: value };
      return { ...prevData, [fieldName]: newItems };
    } else {
      // Handle regular field changes
      return { ...prevData, [fieldName]: value };
    }
  });
};

const handleAddArrayItem = (fieldName) => {
  setFormData((prevData) => ({
    ...prevData,
    [fieldName]: [...(prevData[fieldName] || []), {}],
  }));
};

const handleRemoveArrayItem = (fieldName, index) => {
  setFormData((prevData) => ({
    ...prevData,
    [fieldName]: prevData[fieldName].filter((_, i) => i !== index),
  }));
};

const handleMultiSelectChange = (event, value, field) => {
  setFormData(prevData => ({ ...prevData, [field.name]: value.map(item => item.id) }));
};

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
    console.log('Form data:', formData);
    
  };

  // const renderField = (field) => {
    const renderField = (field, index, parentField) => {
      const fieldName = parentField ? `${parentField}.${index}.${field.name}` : field.name;
      const value = parentField 
        ? (formData[parentField]?.[index]?.[field.name] || '') 
        : (formData[field.name] || '');
    
    switch (field.type) {
      // case 'select':
      //   return (
      //     <FormControl fullWidth margin="normal">
      //       <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
      //       <Select
      //         labelId={`${field.name}-label`}
      //         id={field.name}
      //         name={field.name}
      //         value={formData[field.name] || ''}
      //         onChange={handleInputChange}
      //         label={field.label}
      //         required={field.required}
      //         IconComponent={() => <ArrowDropDownIcon style={{ transform: 'scale(1.5)' }} />}
      //         style={{ padding: '11px' }}
      //       >
      //         {field.options.map((option) => (
      //           <MenuItem key={option.value || option} value={option.value || option}>
      //             {option.label || option}
      //           </MenuItem>
      //         ))}
      //       </Select>
      //     </FormControl>
      //   );

      case 'select':
        return (
          <FormControl fullWidth margin="normal">
            <InputLabel>{field.label}</InputLabel>
            <Select
              value={formData[field.name] || ''} // Ensure the value is set correctly
              onChange={(e) => handleInputChange(e, field.name)} // Directly pass the field name
              label={field.label}
              IconComponent={() => <ArrowDropDownIcon style={{ transform: 'scale(1.5)' }} />}
              style={{
                padding: '11px',
              }}
            >
              {field.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
        
        case 'userpicklist':
          // field.options.forEach(option => console.log(option));
          return (
            <FormControl fullWidth margin="normal">
              <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
              <Select
                labelId={`${field.name}-label`}
                id={field.name}
                name={field.name}
                value={formData[field.name] || ''} // Ensure value is set correctly
                onChange={(e) => handleInputChange(e, field.name)} // Use the correct field name
                label={field.label}
                required={field.required}
                IconComponent={() => <ArrowDropDownIcon style={{ transform: 'scale(1.5)' }} />}
                style={{ padding: '11px' }}
              >
                {field.options.map((user) => (
                  <MenuItem key={user.id} value={user.id}> {/* Ensure unique key */}
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        case 'multiselect':
        return (
          <FormControl fullWidth margin="normal">
            <Autocomplete
              multiple
              id={field.name}
              name={field.name}
              options={field.options}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => handleMultiSelectChange(event, value, field)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`Search and select ${field.label}`}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              required={field.required}
            />
          </FormControl>
        );
      case 'number':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            variant="outlined"
            margin="normal"
            required={field.required}
            value={formData[field.name] || ''}
            // onChange={handleInputChange}
            onChange={(e) => handleInputChange(e, parentField ? parentField : field.name, index, field.name)}
            type="number"
            InputProps={{
              startAdornment: field.prefix ? <InputAdornment position="start">{field.prefix}</InputAdornment> : null,
              style: { padding: '11px' },
            }}
          />
        );
      case 'date':
      case 'time':
        // Using standard text input for date and time
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            variant="outlined"
            margin="normal"
            required={field.required}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            type={field.type}
          />
        );
      case 'list':
        return (
          <TextField
            fullWidth
            id={field.name}
            name={field.name}
            label={field.label}
            variant="outlined"
            margin="normal"
            required={field.required}
            value={formData[field.name]?.join(', ') || ''}
            onChange={handleInputChange}
          />
        )
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[field.name] || false}
                onChange={handleCheckboxChange}
                name={field.name}
              />
            }
            label={field.label}
          />
        );
      case 'radio':
        return (
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{field.label}</FormLabel>
            <RadioGroup
              name={field.name}
              value={formData[field.name] || ''}
              onChange={handleInputChange}
            >
              {field.options.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'switch':
        return (
          <FormControlLabel
            control={
              <Switch
                checked={formData[field.name] || false}
                onChange={handleCheckboxChange}
                name={field.name}
              />
            }
            label={field.label}
          />
        );
      case 'text':
      default:
        return (
          // <TextField
          //   fullWidth
          //   id={field.name}
          //   name={field.name}
          //   label={field.label}
          //   variant="outlined"
          //   margin="normal"
          //   required={field.required}
          //   value={formData[field.name] || ''}
          //   onChange={handleInputChange}
          //   multiline={field.multiline}
          //   rows={field.rows}
          //   type={field.inputType || 'text'}
          // />
          <TextField
            fullWidth
            label={field.label}
            value={value}
            onChange={(e) => handleInputChange(e, parentField ? parentField : field.name, index, field.name)}
            margin="normal"
          />
        );
    }
  };

//   return (
//     <form onSubmit={handleSubmit}>
//       <Grid container spacing={2}>
//         {fields.map((field) => (
//           <Grid item xs={12} sm={6} key={field.name}>
//             {renderField(field)}
//           </Grid>
//         ))}
//       </Grid>
//       <Box mt={4} mb={1} display="flex" justifyContent="flex-end">
//         {onCancel && (
//           <Button onClick={onCancel} color="secondary" sx={{ marginRight: 2 }}>
//             Cancel
//           </Button>
//         )}
//         <MDButton type="submit" variant="gradient" color="info">
//           Submit
//         </MDButton>
//       </Box>
//     </form>
//   );
// };
const renderArrayField = (field) => {
  return (
    <Box key={field.name} mb={2}>
      <Typography variant="subtitle1">{field.label}</Typography>
      {(formData[field.name] || []).map((_, index) => (
        <Box key={index} display="flex" alignItems="center" mb={1}>
          {field.subfields.map((subfield) => (
            <Box key={subfield.name} mr={1} flexGrow={1}>
              {renderField(subfield, index, field.name)}
            </Box>
          ))}
          <IconButton onClick={() => handleRemoveArrayItem(field.name, index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <IconButton onClick={() => handleAddArrayItem(field.name)} sx={{ mt: 1, scale: '0.6' }} style={{ marginLeft: '-47px' }}>
        <AddIcon /> Add {field.label}
      </IconButton>
    </Box>
  );
};

return (
  <form onSubmit={handleSubmit}>
    <Grid container spacing={2}>
      {fields.map((field) => (
        <Grid item xs={12} key={field.name}>
          {field.type === 'array' ? renderArrayField(field) : renderField(field)}
        </Grid>
      ))}
    </Grid>
    <Box mt={4} mb={1} display="flex" justifyContent="flex-end">
      <MDButton onClick={onCancel} color="secondary" sx={{ marginRight: 2 }}>
        Cancel
      </MDButton>
      <MDButton type="submit" variant="gradient" color="info">
        Submit
      </MDButton>
    </Box>
  </form>
);
};

export default CustomInputForm;
