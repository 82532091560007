// import React from 'react';

// const CurrencyFormatter = ({ amount, currency }) => {
//   const formatCurrency = (value, currencyCode) => {
//     console.log('Formatting currency:', { value, currencyCode });
//     const numericValue = parseFloat(value);
//     if (isNaN(numericValue)) {
//       console.log('Invalid numeric value');
//       return 'Invalid Amount';
//     }

//     const locale = getLocale(currencyCode);
//     console.log('Using locale:', locale);

//     try {
//       const formatter = new Intl.NumberFormat(locale, {
//         style: 'currency',
//         currency: currencyCode,
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       });

//       const formattedValue = formatter.format(numericValue);
//       console.log('Formatted value:', formattedValue);
//       return formattedValue;
//     } catch (error) {
//       console.error('Error formatting currency:', error);
//       return 'Formatting Error';
//     }
//   };

//   const getLocale = (currencyCode) => {
//     switch (currencyCode) {
//       case 'INR':
//         return 'en-IN';
//       case 'EUR':
//         return 'de-DE';
//       case 'USD':
//       default:
//         return 'en-US';
//     }
//   };

//   console.log('CurrencyFormatter props:', { amount, currency });
//   const formattedValue = formatCurrency(amount, currency);
//   console.log('Final formatted value:', formattedValue);

//   return <span>{formattedValue}</span>;
// };

// export default CurrencyFormatter;


import React from 'react';

const formatCurrency = (amount, currency, showSymbol = true) => {
  if (!amount) return 'N/A';
  
  const numAmount = parseFloat(amount);
  
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (showSymbol) {
    formatOptions.style = 'currency';
    formatOptions.currency = currency;
  }

  switch(currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US', formatOptions).format(numAmount);
    case 'EUR':
      return new Intl.NumberFormat('de-DE', formatOptions).format(numAmount);
    case 'INR':
      return new Intl.NumberFormat('en-IN', formatOptions).format(numAmount);
    default:
      return showSymbol 
        ? `${numAmount.toFixed(2)} ${currency}`
        : numAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};

const CurrencyFormatter = ({ amount, currency, showSymbol = true }) => {
  return <span>{formatCurrency(amount, currency, showSymbol)}</span>;
};

export default CurrencyFormatter;