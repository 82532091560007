import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { Send as SendIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import RichTextEditor from '../RichTextEditor/index';

const EmailComposer = React.memo(({ initialEmail, onSend, loading }) => {
  const [email, setEmail] = useState(initialEmail);
  const [isPopout, setIsPopout] = useState(false);
  const [editorHeight, setEditorHeight] = useState(300);
  const resizeRef = useRef(null);
  const editorRef = useRef(null);

  const handleChange = (field) => (event) => {
    setEmail({ ...email, [field]: event.target.value });
  };

  const handleContentChange = (content) => {
    setEmail({ ...email, content });
  };

  const handleSend = () => {
    onSend(email);
    if (isPopout) setIsPopout(false);
  };

  const handlePopout = () => {
    setIsPopout(true);
  };

  const handleClose = () => {
    setIsPopout(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (editorRef.current) {
      const newHeight = e.clientY - editorRef.current.getBoundingClientRect().top;
      setEditorHeight(Math.max(200, newHeight)); // Minimum height of 200px
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const ComposerContent = React.memo(({ email, handleChange, handleContentChange }) => (
    <>
      <TextField
        fullWidth
        margin="normal"
        label="To"
        value={email.to}
        onChange={handleChange('to')}
      />
      <TextField
        fullWidth
        margin="normal"
        label="CC"
        value={email.cc}
        onChange={handleChange('cc')}
      />
      <TextField
        fullWidth
        margin="normal"
        label="BCC"
        value={email.bcc}
        onChange={handleChange('bcc')}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Subject"
        value={email.subject}
        onChange={handleChange('subject')}
      />
      <Box ref={editorRef} sx={{ mt: 2, mb: 2, position: 'relative' }}>
        <RichTextEditor
          value={email.content}
          onChange={handleContentChange}
        />
        <Box
          ref={resizeRef}
          onMouseDown={handleMouseDown}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '5px',
            cursor: 'ns-resize',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        />
      </Box>
    </>
  ));

  return (
    <Box>
      {!isPopout && (
        <>
          <ComposerContent 
            email={email}
            handleChange={handleChange}
            handleContentChange={handleContentChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={handleSend}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Send'}
            </Button>
            <IconButton onClick={handlePopout} title="Popout">
              <OpenInNewIcon />
            </IconButton>
          </Box>
        </>
      )}
      <Dialog open={isPopout} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Compose Email</DialogTitle>
        <DialogContent>
          <ComposerContent 
            email={email}
            handleChange={handleChange}
            handleContentChange={handleContentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={handleSend}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

EmailComposer.displayName = 'EmailComposer';

export default EmailComposer;
