import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Checkbox,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Loader2 } from 'lucide-react';


function Invoices() {
  const { axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [currentInvoiceHtml, setCurrentInvoiceHtml] = useState('');

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/invoices/');
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (rowData) => {
    navigate(`/invoices/${rowData.id}`);
  };

  const handleCheckboxChange = (event, invoiceId) => {
    if (event.target.checked) {
      setSelectedInvoices([...selectedInvoices, invoiceId]);
    } else {
      setSelectedInvoices(selectedInvoices.filter(id => id !== invoiceId));
    }
  };

  const fetchInvoiceHtml = async (invoiceId) => {
    try {
      const response = await axiosInstance.get(`/invoices/${invoiceId}/html`);
      return response.data;
    } catch (error) {
      console.error('Error fetching invoice HTML:', error);
      return null;
    }
  };

  const displayInvoice = async (invoiceId) => {
    const invoiceHtml = await fetchInvoiceHtml(invoiceId);
    if (invoiceHtml) {
      setCurrentInvoiceHtml(invoiceHtml);
      setOpenInvoiceDialog(true);
    }
  };

  // const downloadInvoice = async (invoiceId) => {
  //   const invoiceHtml = await fetchInvoiceHtml(invoiceId);
  //   if (invoiceHtml) {
  //     const element = document.createElement('div');
  //     element.innerHTML = invoiceHtml;
  //     document.body.appendChild(element);

  //     const canvas = await html2canvas(element);
  //     const imgData = canvas.toDataURL('image/png');

  //     const pdf = new jsPDF();
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save(`Invoice_${invoiceId}.pdf`);

  //     document.body.removeChild(element);
  //   }
  // };

  const downloadInvoice = async (invoiceId) => {
    setIsDownloading(true);
    try {
      const invoiceHtml = await fetchInvoiceHtml(invoiceId);
    if (invoiceHtml) {
      const win = window.open('', '_blank');
      win.document.write('<html><head><title>Invoice</title>');
      win.document.write('</head><body>');
      win.document.write(invoiceHtml);
      win.document.write('</body></html>');
      
      // Wait for images and other resources to load
      await new Promise(resolve => setTimeout(resolve, 1000));
  
      const element = win.document.body;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: true
      });
  
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Invoice_${invoiceId}.pdf`);
  
      win.close();
    }
      
    } catch (error) {
      console.error('Error downloading invoice:', error);
    } finally {
      setIsDownloading(false);
    }
  };
  
  const bulkDownload = async () => {
    for (const invoiceId of selectedInvoices) {
      await downloadInvoice(invoiceId);
    }
  };

  

  const columns = [
    { Header: "Invoice Number", accessor: "invoice_number", width: "15%" },
    { Header: "Client", accessor: "client_name", width: "20%" },
    { Header: "Amount", accessor: "total_amount", width: "15%" },
    { Header: "Date", accessor: "invoice_date", width: "15%" },
    { Header: "Due Date", accessor: "due_date", width: "15%" },
    { Header: "Status", accessor: "payment_status", width: "10%" },
    { Header: "Actions", accessor: "actions", width: "15%", Cell: ({ row }) => (
      <MDBox>
        <Tooltip title="View Invoice">
          <IconButton onClick={() => displayInvoice(row.original.id)}>
            <Icon>visibility</Icon>
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Download Invoice">
          <IconButton onClick={() => downloadInvoice(row.original.id)}>
            <Icon>download</Icon>
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Download Invoice">
          <IconButton 
            onClick={() => downloadInvoice(row.original.id)} 
            disabled={isDownloading}
          >
            <Icon>{isDownloading ? 'hourglass_empty' : 'download'}</Icon>
          </IconButton>
        </Tooltip>
      </MDBox>
    )},
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" color="white">
                    Invoices
                  </MDTypography>
                  {/* <MDButton variant="contained" color="dark" onClick={bulkDownload} disabled={selectedInvoices.length === 0}>
                    Bulk Download ({selectedInvoices.length})
                  </MDButton> */}
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: invoices }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openInvoiceDialog}
        onClose={() => setOpenInvoiceDialog(false)}
        maxWidth="md"
        fullWidth
      >

        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: currentInvoiceHtml }} />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpenInvoiceDialog(false)} color="primary">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={isDownloading} onOpenChange={() => {}}>
        <DialogContent className="sm:max-w-[425px]">
          <div className="flex flex-col items-center justify-center p-4">
            <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
            <h2 className="text-lg font-semibold mb-2">Generating Invoice</h2>
            <p className="text-center text-sm text-muted-foreground">
              Please wait while we prepare your invoice for download. This may take a few moments.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Invoices;