import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { keyframes } from '@mui/system';

const iconAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(-10deg) scale(1.1);
  }
  75% {
    transform: rotate(10deg) scale(1.1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
`;

const DeleteButton = ({ onClick }) => (
  <Button
    variant="contained"
    onClick={onClick}
    startIcon={<DeleteIcon />}
    sx={{
      padding: '10px 20px',
      fontSize: '1rem',
      fontWeight: '500',
      borderRadius: '8px',
      textTransform: 'none',
      transition: 'all 0.3s ease',
      backgroundColor: '#ff5252',
      color: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        backgroundColor: '#ff1744',
      },
      '&:active': {
        transform: 'translateY(1px)',
      },
      '& .MuiSvgIcon-root': {
        transition: 'all 0.3s ease',
        color: '#ffffff',
      },
      '&:hover .MuiSvgIcon-root': {
        animation: `${iconAnimation} 0.7s ease-in-out infinite`,
      },
    }}
  >
    Delete
  </Button>
);

export default DeleteButton;