import React from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  CssBaseline,
} from '@mui/material';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      light: '#bbdefb',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      },
    },
  },
});

const StepperWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  top: 22,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: theme.palette.grey[300],
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
  color: theme.palette.text.secondary,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${theme.palette.grey[300]}`,
  transition: theme.transitions.create(['border-color', 'background-color', 'color', 'box-shadow', 'transform']),
  '&:hover': {
    transform: 'scale(1.05)',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  ...(ownerState.active && {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    boxShadow: `0 0 0 3px ${theme.palette.primary.light}`,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),
}));

// const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
//   '& .MuiStepLabel-label': {
//     marginTop: theme.spacing(1),
//   },
//   '&.MuiStepLabel-root.Mui-active .MuiStepLabel-label': {
//     color: theme.palette.primary.main,
//   },
//   '&.MuiStepLabel-root.Mui-completed .MuiStepLabel-label': {
//     color: theme.palette.text.primary,
//   },
// }));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    marginTop: theme.spacing(1),
  },
  '&.MuiStepLabel-root.Mui-active .MuiStepLabel-label': {
    color: theme.palette.primary.main,
  },
  '&.MuiStepLabel-root.Mui-completed .MuiStepLabel-label': {
    color: theme.palette.text.primary,
  },
  '&:hover': {
    cursor: 'pointer',
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
    },
  },
}));

function CustomStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <CustomStepIconRoot ownerState={{ active, completed }} className={className}>
        {props.icon}
      </CustomStepIconRoot>
    );
  }
  
  // function LeadStatusStepper({ activeStep, steps }) {
  //   return (
  //     <StepperWrapper>
  //       <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
  //         {steps.map((label) => (
  //           <Step key={label}>
  //             <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
  //           </Step>
  //         ))}
  //       </Stepper>
  //     </StepperWrapper>
  //   );
  // }

  // function LeadStatusStepper({ activeStep, steps, onStepClick }) {
  //   return (
  //     <StepperWrapper>
  //       <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
  //         {steps.map((label, index) => (
  //           <Step key={label} onClick={() => onStepClick(index)}>
  //             <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
  //           </Step>
  //         ))}
  //       </Stepper>
  //     </StepperWrapper>
  //   );
  // }
  
  function LeadStatusStepper({ activeStep, steps, onStepClick }) {
    return (
      <StepperWrapper>
        <Stepper alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
          {steps.map((label, index) => (
            <Step 
              key={label} 
              onClick={onStepClick ? () => onStepClick(index) : undefined}
              style={{ cursor: onStepClick ? 'pointer' : 'default' }}
            >
              <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperWrapper>
    );
  }
  
  // Wrap the LeadStatusStepper with ThemeProvider
  // function ThemedCustomStepper({ activeStep, steps }) {
  //   return (
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <LeadStatusStepper activeStep={activeStep} steps={steps} />
  //     </ThemeProvider>
  //   );
  // }

  function ThemedCustomStepper({ activeStep, steps, onStepClick }) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LeadStatusStepper activeStep={activeStep} steps={steps} onStepClick={onStepClick} />
      </ThemeProvider>
    );
  }

  
export default ThemedCustomStepper;