import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Chip, 
  Box, 
  styled
} from '@mui/material';

const GradientCard = styled(Card)({
  overflow: 'hidden',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'linear-gradient(135deg, #E3F2FD, #90CAF9, #42A5F5, #1E88E5)',
    opacity: 0.9,
  },
});

const GridOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px)`,
  backgroundSize: '20px 20px',
  maskImage: 'linear-gradient(to bottom, white, transparent)',
});

const StyledTypography = styled(Typography)({
  fontWeight: 700,
  letterSpacing: '0.5px',
  color: '#0D47A1',
  textShadow: '1px 1px 2px rgba(255,255,255,0.5)',
});

export default function SimpleOrderHeader({ orderNumber, status }) {
  return (
    <GradientCard>
      <GridOverlay />
      <CardContent sx={{ position: 'relative', zIndex: 1, p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StyledTypography variant="h5" component="h1">
            Order Details: #{orderNumber}
          </StyledTypography>
          
        </Box>
      </CardContent>
    </GradientCard>
  );
}