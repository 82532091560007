// src/utils/dateUtils.js

/**
 * Converts 24-hour format time to 12-hour format
 * @param {string} time24 - Time in 24-hour format (HH:mm)
 * @returns {string} Time in 12-hour format with AM/PM
 */
export const convertTo12Hour = (time24) => {
  if (!time24) return '';
  
  try {
    const [hours, minutes] = time24.split(':').map(num => parseInt(num, 10));
    
    if (isNaN(hours) || isNaN(minutes)) return '';
    
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  } catch (error) {
    console.error('Error converting to 12-hour format:', error);
    return '';
  }
};

/**
 * Converts 12-hour format time to 24-hour format
 * @param {string} time12 - Time in 12-hour format (hh:mm AM/PM)
 * @returns {string} Time in 24-hour format
 */
export const convertTo24Hour = (time12) => {
  if (!time12) return '';
  
  try {
    const [time, period] = time12.split(' ');
    if (!time || !period) return '';

    let [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
    
    if (isNaN(hours) || isNaN(minutes)) return '';
    
    if (period.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('Error converting to 24-hour format:', error);
    return '';
  }
};

/**
 * Gets the current time in either 12 or 24-hour format
 * @param {boolean} use24Hour - Whether to return 24-hour format
 * @returns {string} Current time in specified format
 */
export const getCurrentTime = (use24Hour = false) => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  
  const time24 = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return use24Hour ? time24 : convertTo12Hour(time24);
};

/**
 * Returns the current date-time string in ISO format
 * @returns {string} Current date-time in ISO format
 */
export const getCurrentDateTime = () => {
  return new Date().toISOString();
};

/**
 * Gets current date in YYYY-MM-DD format
 * @returns {string} Current date in YYYY-MM-DD format
 */
export const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

/**
 * Formats a date string into various display formats
 * @param {string} dateString - The input date string to format
 * @param {string} type - The type of formatting to apply (e.g., 'last_activity', 'datetime', 'date', 'time')
 * @param {string} locale - The locale to use for formatting (defaults to 'en-US')
 * @param {boolean} use24Hour - Whether to use 24-hour format for time (defaults to false)
 * @returns {string} The formatted date string
 */
export const formatDate = (dateString, type = 'datetime', locale = 'en-US', use24Hour = false) => {
  if (!dateString) return '';

  try {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.warn('Invalid date provided:', dateString);
      return '';
    }

    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: !use24Hour
    };

    const formattedDate = date.toLocaleDateString(locale, dateOptions);
    const formattedTime = date.toLocaleTimeString(locale, timeOptions);

    switch (type) {
      case 'date':
        return formattedDate;
      case 'time':
        return formattedTime;
      case 'time24':
        return date.toLocaleTimeString(locale, { ...timeOptions, hour12: false });
      case 'time12':
        return date.toLocaleTimeString(locale, { ...timeOptions, hour12: true });
      case 'datetime':
      case 'last_activity':
        return `${formattedDate}, ${formattedTime}`;
      default:
        return `${formattedDate}, ${formattedTime}`;
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

/**
 * Formats a date to relative time (e.g., "2 hours ago", "3 days ago")
 * @param {string} dateString - The input date string
 * @returns {string} Relative time string
 */
export const getRelativeTime = (dateString) => {
  if (!dateString) return '';

  try {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return 'just now';
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'} ago`;
    }

    return formatDate(dateString, 'date');
  } catch (error) {
    console.error('Error calculating relative time:', error);
    return '';
  }
};

/**
 * Checks if a date is today
 * @param {string} dateString - The date string to check
 * @returns {boolean} True if the date is today
 */
export const isToday = (dateString) => {
  try {
    const today = new Date();
    const date = new Date(dateString);
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  } catch (error) {
    console.error('Error checking if date is today:', error);
    return false;
  }
};

/**
 * Validates a time string in either 12 or 24-hour format
 * @param {string} timeString - Time string to validate
 * @param {boolean} is24Hour - Whether the time is in 24-hour format
 * @returns {boolean} Whether the time string is valid
 */
export const isValidTime = (timeString, is24Hour = true) => {
  if (!timeString) return false;

  try {
    if (is24Hour) {
      const [hours, minutes] = timeString.split(':').map(num => parseInt(num, 10));
      return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
    } else {
      const [time, period] = timeString.split(' ');
      const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
      return hours >= 1 && hours <= 12 && 
             minutes >= 0 && minutes <= 59 && 
             ['AM', 'PM'].includes(period?.toUpperCase());
    }
  } catch (error) {
    return false;
  }
};

/**
 * Formats a date into a custom format
 * @param {string} dateString - The input date string
 * @param {Object} options - Custom formatting options for toLocaleString
 * @param {string} locale - The locale to use (defaults to 'en-US')
 * @returns {string} Formatted date string
 */
export const formatCustomDate = (dateString, options = {}, locale = 'en-US') => {
  if (!dateString) return '';

  try {
    const date = new Date(dateString);
    return date.toLocaleString(locale, options);
  } catch (error) {
    console.error('Error formatting custom date:', error);
    return '';
  }
};

/**
 * Formats time for display in UI elements
 * @param {string} time24 - Time in 24-hour format
 * @returns {string} Formatted time string in 12-hour format
 */
export const formatTimeForDisplay = (time24) => {
  if (!time24) return '';
  return convertTo12Hour(time24);
};

/**
 * Compares two dates
 * @param {string} date1 - First date string
 * @param {string} date2 - Second date string
 * @returns {number} -1 if date1 is earlier, 0 if equal, 1 if date1 is later
 */
export const compareDates = (date1, date2) => {
  try {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    if (d1 < d2) return -1;
    if (d1 > d2) return 1;
    return 0;
  } catch (error) {
    console.error('Error comparing dates:', error);
    return 0;
  }
};