import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  CircularProgress,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
import Activities from 'layouts/dashboard/components/Activities';
import ActivityModal from 'components/CustomModals/CustomActivityModal';

import { Edit, Save, SquareX } from 'lucide-react';

function ContactDetail() {
  const { id } = useParams();
  const { user, axiosInstance } = useAuth();
  const navigate = useNavigate();
  const [contact, setContact] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [newActivity, setNewActivity] = useState({
    contact_id: id,
    type: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    fetchContactData();
    fetchActivities();
  }, [id]);

  const fetchContactData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/contacts/${id}`);
      setContact(response.data);
    } catch (error) {
      console.error('Error fetching contact:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchActivities = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/contacts/${id}/activities`);
      setActivities(response.data || []);
    } catch (error) {
      console.error('Error fetching activities:', error);
      setActivities([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditToggle = () => {
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleFieldUpdate = (field, value) => {
    setUpdatedFields(prev => {
      if (value !== contact[field]) {
        return { ...prev, [field]: value };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    if (Object.keys(updatedFields).length > 0) {
      try {
        const response = await axiosInstance.put(`/contacts/${contact.id}`, updatedFields);
        setContact(response.data);
        setUpdatedFields({});
        setSnackbar({
          open: true,
          message: 'Contact updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating contact:', error);
        setSnackbar({
          open: true,
          message: 'Error updating contact. Please try again.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
    setIsEditing(false);
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDelete = async () => {
    setIsLoading(true);
    handleCloseDeleteModal();
    try {
      await axiosInstance.delete(`/contacts/${contact.id}`);
      navigate('/contacts');
    } catch (error) {
      console.error('Error deleting contact:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenActivityModal = () => {
    setIsActivityModalOpen(true);
  };

  const handleCloseActivityModal = () => {
    setIsActivityModalOpen(false);
    setNewActivity({
      contact_id: id,
      type: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
    });
  };

  const handleActivityInputChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  };

  const handleSaveActivity = async () => {
    setIsLoading(true);
    handleCloseActivityModal();
    try {
      const response = await axiosInstance.post(`/contacts/${id}/activities`, newActivity);
      setActivities([...activities, response.data]);
      setSnackbar({
        open: true,
        message: 'Activity created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving activity:', error);
      setSnackbar({
        open: true,
        message: 'Error saving activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: '#ffffff',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={contact?.[item.field] ?? ''}
              options={item.options ?? null}
              onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
              isEditing={isEditing}
              readOnly={item.readOnly ?? false}
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <MDTypography variant="h6" color="white">
                    Contact Details: {contact?.first_name} {contact?.last_name}
                    {!isEditing && (
                      <Edit
                        size={20}
                        color="#ffffff"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleEditToggle}
                      />
                    )}
                    {isEditing && (
                      <>
                        <Save
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={handleSaveChanges}
                        />
                        <SquareX
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    )}
                  </MDTypography>
                  <MDBox>
                    <MDButton variant="contained" color="error" onClick={handleOpenDeleteModal}>
                      Delete
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                {!isLoading ? (
                  <>
                    {renderSection("Basic Information", [
                      { label: "First Name", field: "first_name" },
                      { label: "Last Name", field: "last_name" },
                      { label: "Email", field: "email" },
                      { label: "Phone", field: "phone" },
                      { label: "Mobile", field: "mobile_number" },
                      { label: "Title", field: "title" },
                      { label: "Department", field: "department" },
                    ])}

                    {renderSection("Company Information", [
                      { label: "Company", field: "account_id", readOnly: true },
                      { label: "Role", field: "contact_role" },
                    ])}

                    {renderSection("Address Information", [
                      { label: "Mailing Address", field: "mailing_address" },
                      { label: "City", field: "mailing_city" },
                      { label: "State", field: "mailing_state" },
                      { label: "Country", field: "mailing_country" },
                      { label: "Postal Code", field: "mailing_postal_code" },
                    ])}

                    <Divider sx={{ my: 3 }} />
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="medium">Notes</MDTypography>
                      <MDBox mt={2}>
                        <InlineEditField
                          label="Notes"
                          value={contact?.notes ?? ''}
                          onSave={(newValue) => handleFieldUpdate('notes', newValue)}
                          isEditing={isEditing}
                          multiline
                        />
                      </MDBox>
                    </MDBox>

                    <Divider sx={{ my: 3 }} />
                    <MDBox>
                      <MDTypography variant="h6" fontWeight="medium">Tags</MDTypography>
                      <MDBox mt={2}>
                        {contact?.tags && contact.tags.map((tag, index) => (
                          <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
                        ))}
                      </MDBox>
                    </MDBox>
                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Activities
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenActivityModal}>
                  Add Activity
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <Activities activities={activities.filter(Boolean)} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Activity Modal */}
      {/* <Modal
        open={isActivityModalOpen}
        onClose={handleCloseActivityModal}
        aria-labelledby="activity-modal-title"
        aria-describedby="activity-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="activity-modal-title" variant="h6" component="h2" mb={2}>
            Add New Activity
          </MDTypography>
          <TextField
            fullWidth
            select
            label="Activity Type"
            name="type"
            value={newActivity.type}
            onChange={handleActivityInputChange}
            margin="normal"
          >
            <option value="Call">Call</option>
            <option value="Meeting">Meeting</option>
            <option value="Email">Email</option>
            <option value="Other">Other</option>
          </TextField>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={newActivity.description}
            onChange={handleActivityInputChange}
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Date"
            name="date"
            type="date"
            value={newActivity.date}
            onChange={handleActivityInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseActivityModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleSaveActivity} variant="gradient" color="info">
              Save Activity
            </MDButton>
          </MDBox>
        </Box>
      </Modal> */}
        <ActivityModal 
          isOpen={isActivityModalOpen} 
          onClose={handleCloseActivityModal} 
          parentId={id}
          parent="contacts"
          axiosInstance={axiosInstance} 
          onActivitySaved={handleSaveActivity}
        />

      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
            Confirm Deletion
          </MDTypography>
          <MDTypography id="delete-modal-description" mb={4}>
            Are you sure you want to delete this contact? This action cannot be undone.
          </MDTypography>
          <MDBox display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleDelete} variant="gradient" color="error">
              Delete
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </DashboardLayout>
  );
}

export default ContactDetail;