import React, { useState, useEffect } from "react";
import { useAuth } from "auth/Auth";
import { 
  Card, Icon, List, ListItem, ListItemText, ListItemIcon, 
  Menu, MenuItem, IconButton, Box, Typography, Chip, Skeleton, useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TaskCreationModal from "./TaskCreationModal";
import { motion, AnimatePresence, useMotionValue, useTransform } from "framer-motion";
import confetti from 'canvas-confetti';

const SlideHint = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: -30, // Position it below the task
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  fontWeight: 'medium',
  fontSize: '0.875rem',
  borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
  zIndex: 1,
  boxShadow: theme.shadows[2],
}));


const colorOptions = [
  { value: "#ef5350", label: "Red" },
  { value: "#ffa726", label: "Yellow" },
  { value: "#66bb6a", label: "Green" },
  { value: "#42a5f5", label: "Blue" },
  { value: "#ab47bc", label: "Purple" },
];

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  overflow: 'visible',
}));

const StyledListItem = styled(motion.div)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  cursor: 'grab',
  position: 'relative',
  overflow: 'hidden',
}));

const ColoredIcon = styled(Icon)(({ theme, color }) => ({
  color: color,
  marginRight: theme.spacing(1.5),
  fontSize: '1.25rem',
}));

const ProgressBar = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '4px',
  background: theme.palette.success.main,
}));

const TaskListSkeleton = () => (
  <List disablePadding>
    {[...Array(5)].map((_, index) => (
      <StyledListItem key={index}>
        <Skeleton variant="circular" width={40} height={40} sx={{ marginRight: 2 }} />
        <ListItemText
          primary={<Skeleton variant="text" width="60%" />}
          secondary={
            <>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="80%" sx={{ mt: 0.5 }} />
            </>
          }
        />
      </StyledListItem>
    ))}
  </List>
);

function TaskOverview() {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, axiosInstance } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/tasks/user/${user.id}?status=Pending`);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateTask = () => {
    setIsModalOpen(true);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleTaskCreated = () => {
    fetchTasks();
    handleModalClose();
  };

  const getTaskIcon = (taskType) => {
    switch(taskType) {
      case 'Meeting': return 'group';
      case 'Call': return 'phone';
      case 'Email': return 'email';
      default: return 'event';
    }
  };

  const handleMarkAsDone = async (taskId) => {
    try {
      await axiosInstance.patch(`/tasks/${taskId}`, { status: 'completed' });
      setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
      
      // Trigger confetti animation
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    } catch (error) {
      console.error("Error marking task as done:", error);
    }
  };

  const SwipeableTask = ({ task }) => {
    const theme = useTheme();
    const x = useMotionValue(0);
    const background = useTransform(
      x,
      [-100, 0, 100],
      [theme.palette.success.light, "rgba(0, 0, 0, 0)", theme.palette.success.light]
    );
    
    const hintOpacity = useTransform(
      x,
      [-10, 0, 10],
      [1, 0, 1]
    );

    const hintOffset = useTransform(
      x,
      [-100, 0, 100],
      [0, 30, 0]
    );
    
    return (
      <Box position="relative" mb={4}>
        <StyledListItem
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={(event, info) => {
            if (Math.abs(info.offset.x) > 100) {
              handleMarkAsDone(task.id);
            }
          }}
          style={{ x }}
        >
          <ProgressBar style={{ background }} />
          <Box display="flex" alignItems="center" width="100%">
            <ColoredIcon color={task.color}>{getTaskIcon(task.type)}</ColoredIcon>
            <ListItemText
              primary={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" fontWeight="medium" sx={{ color: task.color }}>
                    {task.title}
                  </Typography>
                  <Chip 
                    label="Event" 
                    size="small" 
                    sx={{ 
                      backgroundColor: 'rgba(0, 0, 0, 0.08)', 
                      color: 'text.secondary',
                      fontWeight: 'medium',
                      fontSize: '0.625rem',
                    }} 
                  />
                </Box>
              }
              secondary={
                <>
                  <Typography variant="caption" color="text.secondary" display="block">
                    {new Date(task.start).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}
                  </Typography>
                  {task.notes && (
                    <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 0.5 }}>
                      {task.notes}
                    </Typography>
                  )}
                </>
              }
            />
          </Box>
        </StyledListItem>
        <SlideHint style={{ opacity: hintOpacity, bottom: hintOffset }}>
          <Icon fontSize="small" sx={{ mr: 1 }}>swipe</Icon>
          Slide to complete
        </SlideHint>
      </Box>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <TaskListSkeleton />;
    }

    if (tasks.length === 0) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="200px">
          <Icon color="success" style={{ fontSize: 48, marginBottom: 16 }}>check_circle</Icon>
          <Typography variant="h6" color="success" fontWeight="medium">
            You're all caught up!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            No pending tasks at the moment.
          </Typography>
        </Box>
      );
    }

    return (
      <List disablePadding>
        <AnimatePresence>
          {tasks.map((task) => (
            <SwipeableTask key={task.id} task={task} />
          ))}
        </AnimatePresence>
      </List>
    );
  };

  return (
    <StyledCard>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" fontWeight="bold" color="text.primary">
            Task Overview
          </Typography>
          <IconButton onClick={handleMenuOpen} size="small">
            <Icon fontSize="small">more_vert</Icon>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleCreateTask}>
              <Icon fontSize="small" sx={{ mr: 1 }}>add</Icon>
              <Typography variant="body2">
                Create New Task
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
        {renderContent()}
      </Box>
      <TaskCreationModal
        open={isModalOpen}
        onClose={handleModalClose}
        onTaskCreated={handleTaskCreated}
        user={user}
        axiosInstance={axiosInstance}
        colorOptions={colorOptions}
      />
    </StyledCard>
  );
}

export default TaskOverview;