// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import {
//   Box,
//   Modal,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import MDTypography from 'components/MDTypography';
// import MDButton from 'components/MDButton';
// import MDBox from 'components/MDBox';
// import S3FileUpload from 'utils/S3';  // Import the S3FileUpload utility

// const AttachmentUploadModal = ({ 
//   isOpen, 
//   onClose, 
//   onUpload, 
//   bucketName,
//   folderPath,
//   allowedFileTypes = "image/*,application/pdf",
//   maxSizeInMB = 5
// }) => {
//   const [isLoading, setIsLoading] = useState(false);

//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'success',
//   });

//   const handleUploadSuccess = async (data) => {
//     setIsLoading(true);
//     try {
//       await onUpload(data);
//       onClose();
//     } catch (error) {
//       console.error('Error processing upload:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleUploadError = (error) => {
//     console.error('Upload error:', error);
//     setIsLoading(false);
//     setSnackbar({
//       open: true,
//       message: 'Error uploading file. Please try again.',
//       severity: 'error',
//     })
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbar({
//       ...snackbar,
//       open: false,
//     });
//   };

//   return (
//     <>
//     <Modal
//       open={isOpen}
//       onClose={onClose}
//       aria-labelledby="attachment-upload-modal-title"
//     >
//       <Box sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//         bgcolor: 'background.paper',
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 2,
//       }}>
//         <MDTypography id="attachment-upload-modal-title" variant="h6" component="h2" mb={2}>
//           Upload Attachment
//         </MDTypography>
        
//         <MDBox mb={3}>
//           <S3FileUpload
//             bucketName={bucketName}
//             folderPath={folderPath}
//             allowedFileTypes={allowedFileTypes}
//             maxSizeInMB={maxSizeInMB}
//             onUploadSuccess={handleUploadSuccess}
//             onUploadError={handleUploadError}
//             onCancel={onClose}
//           />
//         </MDBox>

//         <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
//           <MDButton 
//             onClick={onClose} 
//             color="secondary"
//             variant="gradient" 
//             sx={{ marginRight: 2 }}
//             disabled={isLoading}
//           >
//             Cancel
//           </MDButton>
//         </MDBox>
//       </Box>
//     </Modal>

//     <Snackbar
//     open={snackbar.open}
//     autoHideDuration={6000}
//     onClose={handleCloseSnackbar}
//     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//   >
//     <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
//       {snackbar.message}
//     </Alert>
//   </Snackbar>
//   </>
//   );
// };

// AttachmentUploadModal.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   onUpload: PropTypes.func.isRequired,
//   bucketName: PropTypes.string.isRequired,
//   folderPath: PropTypes.string.isRequired,
//   allowedFileTypes: PropTypes.string,
//   maxSizeInMB: PropTypes.number
// };

// export default AttachmentUploadModal;


// AttachmentUploadModal.js
// import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import {
//   Box,
//   Modal,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import MDTypography from 'components/MDTypography';
// import MDButton from 'components/MDButton';
// import MDBox from 'components/MDBox';
// import S3FileUpload from 'utils/S3';

// const AttachmentUploadModal = ({ 
//   isOpen, 
//   onClose, 
//   onUpload, 
//   bucketName,
//   folderPath,
//   allowedFileTypes = "image/*,application/pdf",
//   maxSizeInMB = 5
// }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasSelectedFile, setHasSelectedFile] = useState(false);
//   const uploadRef = useRef();

//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: '',
//     severity: 'success',
//   });

//   const handleUploadClick = async () => {
//     if (uploadRef.current) {
//       setIsLoading(true);
//       try {
//         await uploadRef.current.handleUpload();
//       } catch (error) {
//         console.error('Error initiating upload:', error);
//         handleUploadError(error);
//       }
//     }
//   };

//   const handleUploadSuccess = async (data) => {
//     try {
//       await onUpload(data);
//       onClose();
//     } catch (error) {
//       console.error('Error processing upload:', error);
//       handleUploadError(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleUploadError = (error) => {
//     console.error('Upload error:', error);
//     setIsLoading(false);
//     setSnackbar({
//       open: true,
//       message: 'Error uploading file. Please try again.',
//       severity: 'error',
//     });
//   };

//   const handleFileSelect = (file) => {
//     setHasSelectedFile(!!file);
//   };

//   const handleCloseSnackbar = () => {
//     setSnackbar({
//       ...snackbar,
//       open: false,
//     });
//   };

//   return (
//     <>
//       <Modal
//         open={isOpen}
//         onClose={onClose}
//         aria-labelledby="attachment-upload-modal-title"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           boxShadow: 24,
//           p: 4,
//           borderRadius: 2,
//         }}>
//           <MDTypography id="attachment-upload-modal-title" variant="h6" component="h2" mb={2}>
//             Upload Attachment
//           </MDTypography>
          
//           <MDBox mb={3}>
//             <S3FileUpload
//               ref={uploadRef}
//               bucketName={bucketName}
//               folderPath={folderPath}
//               allowedFileTypes={allowedFileTypes}
//               maxSizeInMB={maxSizeInMB}
//               onUploadSuccess={handleUploadSuccess}
//               onUploadError={handleUploadError}
//               onFileSelect={handleFileSelect}
//             />
//           </MDBox>

//           <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
//             <MDButton 
//               onClick={onClose} 
//               color="secondary"
//               variant="gradient" 
//               sx={{ marginRight: 2 }}
//               disabled={isLoading}
//             >
//               Cancel
//             </MDButton>
//             <MDButton
//               onClick={handleUploadClick}
//               color="info"
//               variant="gradient"
//               disabled={!hasSelectedFile || isLoading}
//             >
//               Upload
//             </MDButton>
//           </MDBox>
//         </Box>
//       </Modal>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//       >
//         <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// };

// AttachmentUploadModal.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   onUpload: PropTypes.func.isRequired,
//   bucketName: PropTypes.string.isRequired,
//   folderPath: PropTypes.string.isRequired,
//   allowedFileTypes: PropTypes.string,
//   maxSizeInMB: PropTypes.number
// };

// export default AttachmentUploadModal;




import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import S3FileUpload from 'utils/S3';

const AttachmentUploadModal = ({ 
  isOpen, 
  onClose, 
  onUpload, 
  bucketName,
  folderPath,
  allowedFileTypes = "*/*",
  maxSizeInMB = 5
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSelectedFile, setHasSelectedFile] = useState(false);
  const uploadRef = useRef();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Reset file selection state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setHasSelectedFile(false);
    }
  }, [isOpen]);

  const handleUploadClick = async () => {
    if (uploadRef.current) {
      setIsLoading(true);
      try {
        await uploadRef.current.handleUpload();
      } catch (error) {
        console.error('Error initiating upload:', error);
        handleUploadError(error);
      }
    }
  };

  const handleUploadSuccess = async (data) => {
    try {
      await onUpload(data);
      onClose();
    } catch (error) {
      console.error('Error processing upload:', error);
      handleUploadError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadError = (error) => {
    console.error('Upload error:', error);
    setIsLoading(false);
    setSnackbar({
      open: true,
      message: 'Error uploading file. Please try again.',
      severity: 'error',
    });
  };

  const handleFileSelect = (file) => {
    setHasSelectedFile(!!file);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="attachment-upload-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <MDTypography id="attachment-upload-modal-title" variant="h6" component="h2" mb={2}>
            Upload Attachment
          </MDTypography>
          
          <MDBox mb={3}>
            <S3FileUpload
              ref={uploadRef}
              bucketName={bucketName}
              folderPath={folderPath}
              allowedFileTypes={allowedFileTypes}
              maxSizeInMB={maxSizeInMB}
              onUploadSuccess={handleUploadSuccess}
              onUploadError={handleUploadError}
              onFileSelect={handleFileSelect}
            />
          </MDBox>

          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton 
              onClick={onClose} 
              color="secondary"
              variant="gradient" 
              sx={{ marginRight: hasSelectedFile ? 2 : 0 }}
              disabled={isLoading}
            >
              Cancel
            </MDButton>
            {hasSelectedFile && (
              <MDButton
                onClick={handleUploadClick}
                color="info"
                variant="gradient"
                disabled={isLoading}
              >
                Upload
              </MDButton>
            )}
          </MDBox>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

AttachmentUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  bucketName: PropTypes.string.isRequired,
  folderPath: PropTypes.string.isRequired,
  allowedFileTypes: PropTypes.string,
  maxSizeInMB: PropTypes.number
};

export default AttachmentUploadModal;