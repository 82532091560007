/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import React from "react";
import Icon from "@mui/material/Icon";
// import Orders from "layouts/crm/Orders";
// import OrderDetail from "layouts/crm/OrderDetail";
// import { COMMENT } from "stylis";

// Lazy load the components
// const Dashboard = React.lazy(() => import("layouts/dashboard"));
// const Leads = React.lazy(() => import("layouts/crm/Leads"));
// const Accounts = React.lazy(() => import("layouts/crm/Accounts"));
// const LeadDetail = React.lazy(() => import("layouts/crm/LeadDetail"));
// const AccountDetail = React.lazy(() => import("layouts/crm/AccountDetail"));
// const Settings = React.lazy(() => import("layouts/crm/Settings"));
// const UserManagement = React.lazy(() => import("layouts/crm/UserManagement"));
// const EmailSetup = React.lazy(() => import("layouts/crm/EmailSetup"));
// const Deals = React.lazy(() => import("layouts/crm/Deals"));
// const DealDetail = React.lazy(() => import("layouts/crm/DealDetail"));
// const Inventory = React.lazy(() => import("layouts/crm/Inventory"));
// const Quotation = React.lazy(() => import("layouts/crm/Quotation"));
// const QuotationDetail = React.lazy(() => import("layouts/crm/QuotationDetail"));
// const Orders = React.lazy(() => import("layouts/crm/Orders"));
// const OrderDetail = React.lazy(() => import("layouts/crm/OrderDetail"));

import Dashboard from "layouts/dashboard";
import Leads from "layouts/crm/Leads";
import Accounts from "layouts/crm/Accounts";
import LeadDetail from "layouts/crm/LeadDetail";
import AccountDetail from "layouts/crm/AccountDetail";
import Settings from "layouts/crm/Settings";
import UserManagement from "layouts/crm/UserManagement";
import EmailSetup from "layouts/crm/EmailSetup";
import Deals from "layouts/crm/Deals";
import DealDetail from "layouts/crm/DealDetail";
import Inventory from "layouts/crm/Inventory";
import ProductDetail from "layouts/crm/ProductDetail";
import Quotation from "layouts/crm/Quotation";
import QuotationDetail from "layouts/crm/QuotationDetail";
import Orders from "layouts/crm/Orders";
import OrderDetail from "layouts/crm/OrderDetail";
import Tasks from "layouts/tasks";
import Inbox from "layouts/inbox";
import AgentMap from "layouts/crm/AgentMap";
import Invoices from "layouts/crm/Invoices";
import Reports from "layouts/crm/Reports";
import Contacts from "layouts/crm/Contacts";
import ContactDetail from "layouts/crm/ContactDetails";
import GmailAuthCallback from "components/SettingsComponents/GmailCallback";
import UserProfile from "layouts/crm/UserPage";
import RAGChatInterface from "layouts/crm/RAGChat";
import SearchResults from "layouts/crm/SearchResults";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Contact Detail",
    key: "contact-detail",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/contact/detail/:id",
    component: <ContactDetail />
  },
  {
    type: "collapse",
    name: "Leads",
    key: "leads",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/leads",
    component: <Leads />,
  },
  {
    type: "collapse",
    name: "Deals",
    key: "deals",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/deals",
    component: <Deals/>,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/accounts",
    component: <Accounts />,
  },
  {
    type: "collapse",
    name: "Contacts",
    key: "contacts",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/contacts",
    component: <Contacts />
  },
  {
    type: "collapse",
    name: "Deals Detail",
    key: "deals-detail",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/deals/detail/:id",
    component: <DealDetail/>,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/inventory",
    component: <Inventory/>,
  },
  {
    type: "collapse",
    name: "Product Detail",
    key: "product-detail",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/products/:id",
    component: <ProductDetail/>,
  },
  {
    type: "collapse",
    name: "Quotations",
    key: "quotation",
    icon: <Icon fontSize="small">request_quote</Icon>,
    route: "/quote",
    component: <Quotation />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  {
    type: "collapse",
    name: "OrderDetail",
    key: "order-detail",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders/detail/:id",
    component: <OrderDetail />,
  },
  {
    type: "collapse",
    name: "Quotation Detail",
    key: "quotation-detail",
    icon: <Icon fontSize="small">request_quote</Icon>,
    route: "/quote/detail/:id",
    component: <QuotationDetail />,
  },
  {
    type: "collapse",
    name: "Lead Detail",
    key: "lead-detail",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/lead/detail/:id",
    component: <LeadDetail />,
  },
  {
    type: "collapse",
    name: "Account Detail",
    key: "account-detail",
    icon: <Icon fontSize="small">business</Icon>,
    route: "/account/detail/:id",
    component: <AccountDetail />,
  },

  {
    type: "collapse",
    name: "Gmail Auth Callback",
    key: "gmail-auth-callback",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/auth/gmail/callback",
    component: <GmailAuthCallback />,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  },
  {
    type: "collapse",
    name: "Email Setup",
    key: "email-setup",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/email-setup",
    component: <EmailSetup />,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/invoices",
    component: <Invoices />,
  },
  {
    type: "collapse",
    name: "Search",
    key: "search",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/search",
    component: <SearchResults />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/profile/:id",
    component: <UserProfile />,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Productivity", key: "title-pages" },
  {
    type: "collapse",
    name: "Tasks",
    key: "tasks",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/tasks",
    component: <Tasks />,
  },
  {
    type: "collapse",
    name: "Inbox",
    key: "inbox",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/inbox",
    component: <Inbox />,
  },
  {
    type: "collapse",
    name: "Agent Map",
    key: "agent-map",
    icon: <Icon fontSize="small">map</Icon>,
    route: "/agent-map",
    component: <AgentMap />,
  },

  {
    type: "collapse",
    name: "RAG Chat",
    key: "rag-chat",
    route: "/rag-chat",
    icon: <Icon fontSize="medium">chat</Icon>,
    component: <RAGChatInterface />,
    noCollapse: true,
  }
  
];

export default routes;
