import React, { useState } from 'react';
import { Card, Chip, Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

// Assuming MDBox and MDTypography are custom components
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDTypography from "components/MDTypography";
import { scale } from 'chroma-js';

function ContactChip({ name, onClick }) {
  return (
    <Chip
      label={name}
      variant="outlined"
      className="m-1"
      onClick={onClick} // Add onClick handler
      style={{ cursor: 'pointer' }} // Change cursor to pointer
    />
  );
}

function ContactsTable({ contacts }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          {/* Add more headers for additional contact details */}
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts.map((contact, index) => (
          <TableRow key={index}>
            <TableCell>{contact}</TableCell>
            {/* Add more cells for additional contact details */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function Contacts({ contacts = [] }) {
  const [open, setOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null); // State to track the selected contact

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContactClick = (contact) => {
    setSelectedContact(contact); // Store the clicked contact
    // You can perform any additional actions here, like showing more info
    console.log(`Contact clicked: ${contact}`);
  };

  const displayedContacts = contacts.slice(0, 5);
  const hasMoreContacts = contacts.length > 5;

  console.log(`Displayed contacts:`, contacts);
  
  return (
    <Card>
      <MDBox p={3}>
        {/* <MDTypography variant="h6">Account Contacts</MDTypography> */}
        {contacts.length === 0 ? (
          <MDTypography variant="body2">No contacts to display.</MDTypography>
        ) : (
          <MDBox display="flex" flexWrap="wrap" alignItems="center">
            {displayedContacts.map((contact, index) => (
              <ContactChip key={index} name={contact} onClick={() => handleContactClick(contact)} />
            ))}
            {hasMoreContacts && (
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={handleOpen}
                className="ml-2"
                style={{ padding: '2px 6px', fontSize: '10px', marginLeft: '16px' }} // Custom padding and font size
              >
                View All
              </MDButton>
            )}
          </MDBox>
        )}
      </MDBox>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>All Contacts</DialogTitle>
        <DialogContent>
          <ContactsTable contacts={contacts} />
        </DialogContent>
      </Dialog>
    </Card>
  );
}

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.string),
};

export default Contacts;
