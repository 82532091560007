import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/Auth";
import { Card, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/bg1.png";
// import bgImage from "assets/images/new_login_image.jpg"
// import bgImage from "assets/images/new_login_image_01.jpg"
// import bgImage from "assets/images/ai_titled_login_banner.png"
import bgImage from "assets/images/new_login_screen.png"
import logoImage from "assets/images/new_paracloud_dark.png";

const Basic = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const { signin } = useAuth();
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = async (e) => {
    e.preventDefault();
    const isAuth = await signin(username, password);
    if (isAuth) {
      console.log("Authentication successful, redirecting to dashboard");
      navigate("/dashboard");
    } else {
      setLoginFailed(true);
      setTimeout(() => setLoginFailed(false), 820); // Match animation duration
    }
  };

  return (
    <BasicLayout image={bgImage}>
      {/* Add the logo here */}
      {/* <MDBox mb={4} textAlign="center">
        <img src={logoImage} alt="ParaCloud Logo" style={{ maxWidth: "200px", width: "100%" }} />
      </MDBox> */}

      <Card 
        component="form" 
        role="form" 
        onSubmit={handleSignIn}
        sx={{
          maxWidth: "400px",
          margin: "0 auto",
          padding: "2rem",
          animation: loginFailed ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' : 'none',
          '@keyframes shake': {
            '10%, 90%': { transform: 'translate3d(-1px, 0, 0)' },
            '20%, 80%': { transform: 'translate3d(2px, 0, 0)' },
            '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)' },
            '40%, 60%': { transform: 'translate3d(4px, 0, 0)' },
          },
        }}
      >
        {/* Add the logo here */}
        <MDBox mb={4} textAlign="center">
          <img src={logoImage} alt="ParaCloud Logo" style={{ maxWidth: "150px", width: "100%" }} />
        </MDBox>

        <MDBox mb={2}>
          <MDInput 
            type="text" 
            label="Username" 
            fullWidth 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput 
            type="password" 
            label="Password" 
            fullWidth 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton 
            type="submit" 
            variant="gradient" 
            color="info" 
            fullWidth
            sx={{ maxWidth: "200px", display: "block", margin: "0 auto" }}
          >
            Sign In
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;