import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import axios from 'axios';
import {
  Card,
  Grid,
  Box,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Icon,
  Avatar,
} from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import Board from "@asseinfo/react-kanban";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';
import { styled } from '@mui/material/styles';
import { currencies } from 'utils/CurrencyList';

import { formatDate } from 'utils/DateTimeConversion';

import Lottie from 'react-lottie';
import successAnimation from 'assets/animations/success-animation.json'; // You'll need to add this animation file
import LeadFormModal from 'components/NewLeadModal';

const WhiteToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: 'white',
  borderColor: 'rgba(255, 255, 255, 0.5)',
  '&.Mui-selected': {
    color: '#3a92ee',
    backgroundColor: 'white',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function Leads() {

  const defaultCurrency = currencies.find(c => c.code === 'INR') || null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const { user, axiosInstance } = useAuth();
  // const [currency, setCurrency] = useState(null);
  const [currency, setCurrency] = useState(defaultCurrency);

  const [allLeads, setAllLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [viewMode, setViewMode] = useState('list');
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [agents, setAgents] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [loadingCountries, setLoadingCountries] = useState(false);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);


  const fetchLeads = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/leads/`);
      console.log(`response.data for fetchLeads:`, response.data);
      
      const transformedLeads = transformLeadData(response.data);
      setAllLeads(transformedLeads);
      setFilteredLeads(transformedLeads);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching leads data:', error);
    }
  };

  // Fetch products (you'll need to implement this API endpoint)
  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/products/');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchAgents();
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      setLoadingCountries(true);
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const sortedCountries = response.data
          .map(country => ({
            code: country.cca2,
            label: country.name.common
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
      setLoadingCountries(false);
    };

    fetchCountries();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setAgents(response.data);
      console.log(`response.data for fetchAgents:`, response.data);
      
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  useEffect(() => {
    const filtered = allLeads.filter(lead => {
      return Object.entries(filters).every(([key, value]) => {
        return lead[key].toLowerCase().includes(value.toLowerCase());
      });
    });
    setFilteredLeads(filtered);
  }, [filters, allLeads]);


  const handleCurrencyChange = (event, newValue) => {
    setCurrency(newValue);
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProducts(newValue);
  };

  const transformLeadData = (serverData) => {
    return serverData.map(lead => ({
      id: lead.id,
      name: lead.first_name + ' ' + lead.last_name || "",
      company: lead.company_name || "",
      source: lead.lead_source || "",
      email: lead.email || "",
      status: lead.lead_status || "",
      updated_at: lead.updated_at?.split('T')[0] || "",
      lead_rating: "7.4" || "",  // TODO: Replace with real data
      owner_name: lead.owner?.name || "",
      owner_avatar: lead.owner?.avatar || "https://via.placeholder.com/24",
      assigned_to_name: lead.assigned_to_name || "Unassigned",
      assigned_to: lead.assigned_to,
      created_by_name: lead?.created_by_name || "",
      updated_by_name: lead?.updated_by_name || "",
      // last_activity: lead?.last_activity?.split('T')[0] || "",
      last_activity: formatDate(lead?.last_activity, 'datetime') || "",
    }));
  };

  const handleOpenModal = () => setIsModalOpen(true);
  // const handleCloseModal = () => setIsModalOpen(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCountry(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSaveLead = async (leadData) => {
    setIsLoading(true);
    handleCloseModal();
    
    try {
      const leadPayload = {
        ...leadData,
        last_activity: new Date().toISOString(),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      console.log(`leadPayload for handleSaveLead:`, leadPayload);
      
      const response = await axiosInstance.post(`/leads/`, leadPayload);
      setAllLeads(prevLeads => [...prevLeads, transformLeadData([response.data])[0]]);
      await fetchLeads();
      setShowSuccessAnimation(true);
      setTimeout(() => {
        setShowSuccessAnimation(false);
        setSnackbar({
          open: true,
          message: 'Lead created successfully!',
          severity: 'success',
        });
      }, 2000);
    } catch (error) {
      console.error('Error creating new lead:', error);
      setSnackbar({
        open: true,
        message: 'Error creating lead. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);

      setFormValue({});
    }
  };

  const handleRowClick = (rowData) => {
    navigate(`/lead/detail/${rowData.id}`);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }) => setFormValue(currentTarget.value);


  const handleAgentClick = (agentId) => {
    if (agentId) {
      navigate(`/profile/${agentId}`);
    } else {
      setSnackbar({
        open: true,
        message: 'This lead is not assigned to any agent.',
        severity: 'info',
      });
    }
  };


  const leadsTableData = useMemo(() => ({
    columns: [
      { 
        Header: "Name", 
        accessor: "name", 
        width: "15%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {row.original.name}
          </MDTypography>
        )
      },
      { Header: "Company", accessor: "company", width: "15%" },
      { Header: "Email", accessor: "email", width: "15%" },
      { Header: "Status", accessor: "status", width: "10%" },
      { Header: "Source", accessor: "source", width: "10%" },
      // { Header: "Lead Rating", accessor: "lead_rating", width: "10%" },
      { Header: "Last Activity", accessor: "last_activity", width: "10%" },
      // { Header: "Assigned Agent", accessor: "assigned_to_name", width: "10%" },
      { 
        Header: "Assigned Agent", 
        accessor: "assigned_to_name", 
        width: "10%",
        Cell: ({ value, row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleAgentClick(row.original.assigned_to)}
            variant="caption" 
            color="info" 
            fontWeight="medium"
            sx={{ 
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {value || 'Unassigned'}
          </MDTypography>
        )
      },
    ],
    rows: filteredLeads,
  }), [filteredLeads, agents]);

  const kanbanColumns = useMemo(() => [
    { id: 'new', title: 'New' },
    { id: 'contacted', title: 'Contacted' },
    { id: 'qualified', title: 'Qualified' },
    { id: 'proposal', title: 'Proposal' },
    { id: 'won', title: 'Won' },
    { id: 'lost', title: 'Lost' },
  ], []);

  const getLeadsByStatus = useMemo(() => {
    const leadsByStatus = {
      new: { id: 'new', title: 'New', cards: [] },
      contacted: { id: 'contacted', title: 'Contacted', cards: [] },
      qualified: { id: 'qualified', title: 'Qualified', cards: [] },
      proposal: { id: 'proposal', title: 'Proposal', cards: [] },
      won: { id: 'won', title: 'Won', cards: [] },
      lost: { id: 'lost', title: 'Lost', cards: [] },
    };
    
    filteredLeads.forEach(lead => {
      const status = lead.status.toLowerCase();
      if (leadsByStatus[status]) {
        leadsByStatus[status].cards.push({
          id: lead.id.toString(),
          title: lead.name,
          description: `${lead.company} - ${lead.email}`,
          owner: lead.owner ? {
            name: lead.owner.name || 'Unknown',
            avatar: lead.owner.avatar || 'https://via.placeholder.com/24',
          } : {
            name: 'Unknown',
            avatar: 'https://via.placeholder.com/24',
          },
        });
      }
    });
    
    return Object.values(leadsByStatus);
  }, [filteredLeads]);

  const onCardDragEnd = (board, card, source, destination) => {
    if (source.fromColumnId !== destination.toColumnId) {
      const newStatus = destination.toColumnId;
      const leadId = parseInt(card.id);

      setFilteredLeads(prevLeads => {
        return prevLeads.map(lead => 
          lead.id === leadId ? { ...lead, status: newStatus } : lead
        );
      });

      // Update the lead status in the backend
      axiosInstance.patch(`/leads/${leadId}/`, { lead_status: newStatus })
        .then(response => {
          console.log('Lead status updated successfully');
        })
        .catch(error => {
          console.error('Error updating lead status:', error);
          // Revert the change in the UI if the API call fails
          setFilteredLeads(prevLeads => {
            return prevLeads.map(lead => 
              lead.id === leadId ? { ...lead, status: source.fromColumnId } : lead
            );
          });
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="leaderboard"
                title="Total Leads"
                count={allLeads.length}
                percentage={{
                  color: "success",
                  amount: "+50%",
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="New Leads"
                count={allLeads.length}
                percentage={{
                  color: "success",
                  amount: "+25%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Conversion Rate"
                count="3.5%"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Leads
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                      sx={{
                        mr: 2,
                        backgroundColor: 'transparent',
                        border: 'none',
                        '& .MuiToggleButtonGroup-grouped': {
                          border: '1px solid rgba(255, 255, 255, 0.5)',
                          '&:not(:first-of-type)': {
                            borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
                          },
                        },
                      }}
                    >
                      <WhiteToggleButton value="list" aria-label="list view">
                        <Icon sx={{ color: 'inherit' }}>view_list</Icon>
                      </WhiteToggleButton>
                      <WhiteToggleButton value="kanban" aria-label="kanban view">
                        <Icon sx={{ color: 'inherit' }}>view_kanban</Icon>
                      </WhiteToggleButton>
                    </ToggleButtonGroup>
                    <MDButton variant="gradient" color="dark" onClick={handleOpenModal} sx={{ mr: 2 }}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Add New Lead
                    </MDButton>
                    {/* <MDButton variant="gradient" color="info" onClick={() => navigate('/agent-map')}>
                      <MapIcon sx={{ mr: 1 }} />
                      View Agent Map
                    </MDButton> */}
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {!isLoading ? (
                  viewMode === 'list' ? (
                    <DataTable
                      table={leadsTableData}
                      isSorted={true}
                      entriesPerPage={{
                        defaultValue: 10,
                        entries: [5, 10, 15, 20, 25],
                      }}
                      canSearch
                      noEndBorder
                      showTotalEntries
                      pagination={{ variant: "gradient", color: "info" }}
                      searchPlaceholder="Search Lead"
                      exportCSV={true}
                    />
                  ) : (
                    <MDBox
                      sx={{
                        "& .react-kanban-column": {
                          backgroundColor: ({ palette: { background } }) => `${background.default}E6`,  // Light grey with 90% opacity
                          width: "350px",
                          margin: "0 10px",
                          padding: "20px",
                          borderRadius: "16px",  // Rounded edges
                          boxShadow: 3,
                        },
                        "& .react-kanban-column-header": {
                          backgroundColor: ({ palette: { info } }) => info.main,
                          color: 'white',
                          padding: '10px',
                          borderRadius: '16px 16px 0 0',  // Rounded top edges
                        },
                      }}
                    >
                      <Board
                        initialBoard={{ columns: getLeadsByStatus }}
                        allowAddCard
                        onCardDragEnd={onCardDragEnd}
                        renderColumnHeader={({ id, title }, { addCard }) => (
                          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                            <MDTypography variant="h6">{title}</MDTypography>
                            <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, id)}>
                              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                            </MDButton>
                          </MDBox>
                        )}
                        renderCard={({ id, title, description, owner }, { dragging }) => (
                          <MDBox
                            key={id}
                            dragging={dragging.toString() || undefined}
                            display="block"
                            width="calc(350px - 40px)"
                            bgColor="white"
                            color="text"
                            borderRadius="xl"
                            mt={2.5}
                            py={1.875}
                            px={1.875}
                            lineHeight={1.5}
                            sx={{
                              fontSize: ({ typography: { size } }) => size.md,
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              transition: "box-shadow 0.3s ease-in-out",
                              "&:hover": {
                                cursor: "pointer",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                              },
                            }}
                            onClick={() => handleRowClick({ id: parseInt(id) })}
                          >
                            <MDBox display="flex" alignItems="center" mb={1}>
                              <Avatar
                                src={owner.avatar}
                                alt={owner.name}
                                sx={{ width: 24, height: 24, mr: 1 }}
                              />
                              <MDTypography variant="body2" fontWeight="bold">
                                {title}
                              </MDTypography>
                            </MDBox>
                            <MDTypography variant="caption" color="text">
                              {description}
                            </MDTypography>
                          </MDBox>
                        )}
                      />
                    </MDBox>
                  )
                ) : (
                  <TableSkeleton />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      </MDBox>
      
      <Footer />

      <LeadFormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveLead}
        agents={agents}
        products={products}
        currencies={currencies}
        countries={countries}
        loadingCountries={loadingCountries}
        defaultCurrency={defaultCurrency}
        user={user}
      />

      {showSuccessAnimation && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: successAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={200}
            width={200}
          />
        </Box>
      )}
      

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Leads;
