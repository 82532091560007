import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';

function UserProfile() {
  const { id } = useParams();
  const { axiosInstance } = useAuth();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/users/${id}/`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <TableSkeleton />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (!user) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <MDTypography variant="h4" color="error">User not found</MDTypography>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  User Profile
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pb={3} px={3}>
                <MDBox display="flex" alignItems="center" mb={3}>
                  <Avatar
                    src={user.avatar || "https://via.placeholder.com/150"}
                    alt={user.username}
                    sx={{ width: 100, height: 100, mr: 2 }}
                  />
                  <MDBox>
                    <MDTypography variant="h5">{user.username}</MDTypography>
                    <MDTypography variant="body2" color="text">
                      {user.email}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <List>
                  <ListItem>
                    <ListItemText primary="Full Name" secondary={`${user.username}`} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Role" secondary={user.role || 'N/A'} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Phone" secondary={user.phone || 'N/A'} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <ListItemText primary="Location" secondary={user.location || 'N/A'} />
                  </ListItem>
                </List>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            {/* Add more sections here, such as user statistics, recent activity, etc. */}
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  User Statistics
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pb={3} px={3}>
                {/* Add user statistics here */}
                <MDTypography variant="body2" color="text">
                  User statistics and additional information can be added here.
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserProfile;