import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Chip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ThemedCustomStepper from 'components/CustomStepper';
import { Download, LocalShipping, Payment, Description, Info } from '@mui/icons-material';
import OrderHeader from 'components/OrderHeader';
import CustomTable from 'components/CustomTables';
function OrderDetail() {
  const { axiosInstance } = useAuth();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [salesOrder, setSalesOrder] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  const orderSteps = ['New', 'Processing', 'Shipped', 'Delivered', 'Closed'];

  useEffect(() => {
    fetchOrderData();
  }, [id]);

  const fetchOrderData = async () => {
    setIsLoading(true);
    try {
      const orderResponse = await axiosInstance.get(`/orders/${id}`);
      setOrder(orderResponse.data);

      if (orderResponse.data.sales_order_id) {
        const salesOrderResponse = await axiosInstance.get(`/sales_orders/${orderResponse.data.sales_order_id}`);
        setSalesOrder(salesOrderResponse.data);
      }

      if (orderResponse.data.purchase_order_id) {
        const purchaseOrderResponse = await axiosInstance.get(`/purchase_orders/${orderResponse.data.purchase_order_id}`);
        setPurchaseOrder(purchaseOrderResponse.data);
      }

      console.log(`Order and related documents fetched successfully`);
    } catch (error) {
      console.error('Error fetching order data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getActiveStep = (status) => {
    return orderSteps.indexOf(status);
  };

  const renderDetailCard = (title, details, icon) => (
    <Card sx={{ mb: 3, overflow: 'hidden', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
      <MDBox p={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>{icon}</Grid>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {Object.entries(details).map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <MDTypography variant="button" fontWeight="bold" textTransform="uppercase" color="text">
                {key}
              </MDTypography>
              <MDTypography variant="body2" fontWeight="regular" color="dark">
                {value}
              </MDTypography>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );

  const renderOrderItemsTable = () => (
    <Card sx={{ mb: 3, overflow: 'hidden', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
      <MDBox p={3}>
        <MDTypography variant="h5" fontWeight="medium" mb={2}>
          Order Items
        </MDTypography>
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650 }} aria-label="order items table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f8f9fa' }}>
                <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Product Name</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Quantity</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Price</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.order_items.map((item, index) => (
                <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f8f9fa' } }}>
                  <TableCell component="th" scope="row" sx={{ fontSize: '0.875rem' }}>{item.product_name}</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.875rem' }}>{item.quantity}</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.875rem' }}>${parseFloat(item.unit_price).toFixed(2)}</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.875rem' }}>${parseFloat(item.total_price).toFixed(2)}</TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ backgroundColor: '#e9ecef' }}>
                <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Total</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>${parseFloat(order.total_amount).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );

  const renderRelatedDocuments = (title, data, icon) => {
    if (!data) return null;

    return (
      <Card sx={{ mb: 3, overflow: 'hidden', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
        <MDBox p={3}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item>{icon}</Grid>
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                {title}
              </MDTypography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            {Object.entries(data).map(([key, value]) => (
              <Grid item xs={12} key={key}>
                <MDTypography variant="button" fontWeight="bold" textTransform="uppercase" color="text">
                  {key}
                </MDTypography>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  {value}
                </MDTypography>
                <Divider sx={{ my: 1 }} />
              </Grid>
            ))}
          </Grid>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDButton variant="outlined" color="info" startIcon={<Download />}>
              Download {title}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    );
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
  
    { id: 'lastActive', label: 'Last Active' },
  ];
  
  const rows = [
    { name: 'John Doe', email: 'john@example.com', role: 'Admin', status: 'active', lastActive: '2023-09-15' },
    { name: 'Jane Smith', email: 'jane@example.com', role: 'User', status: 'inactive', lastActive: '2023-08-30' },
    // Add more rows as needed
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
              <OrderHeader orderNumber={order.order_number} status={order.status} />
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            {renderDetailCard("Order Information", {
              "Order Date": new Date(order.order_date).toLocaleDateString(),
              "Status": order.status,
              "Total Amount": `$${parseFloat(order.total_amount).toFixed(2)}`,
              "Discount": `$${parseFloat(order.discount).toFixed(2)}`,
              "Tax": `$${parseFloat(order.tax).toFixed(2)}`,
              "Currency": order.currency,
            }, <Info />
            )}
            {renderDetailCard("Shipping & Payment", {
              "Payment Method": order.payment_methods,
              "Payment Status": order.payment_status,
              "Shipping Method": order.shipping_method,
              "Delivery Date": new Date(order.delivery_date).toLocaleDateString(),
            }, <LocalShipping />)}
            {renderOrderItemsTable()}
          </Grid>
          
          <Grid item xs={12} md={4}>
            {renderRelatedDocuments("Sales Order", {
              "SO Number": "SO-2024-00001",
              "PO Start Date": "2024-09-01",
              "PO End Date": "2024-10-01",
              "Delivery Schedule": "Weekly",
              "Shipping Instructions": "Handle with care",
              "Approval Status": "Approved",
            }, <Description />)}
            {renderRelatedDocuments("Purchase Order", {
              "PO Number": "PO-2024-00001",
              "Delivery Address": "123 Main St, Anytown, AN 12345",
              "Payment Terms": "Net 30",
              "Shipping Method": "Express",
            }, <Payment />)}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetail;