import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import TableSkeleton from 'components/CustomSkeleton/TableSkeleton';

function Contacts() {
  const { axiosInstance } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newContact, setNewContact] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/contacts/');
      setContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      alert('Error fetching contacts');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };

  const handleSaveContact = async (event) => {
    event.preventDefault();
    try {
      await axiosInstance.post('/contacts/', newContact);
      handleCloseModal();
      fetchContacts();
    } catch (error) {
      console.error("Error creating contact:", error);
    }
  };

  const handleRowClick = (rowData) => {
    navigate(`/contact/detail/${rowData.id}`);
  };

  const contactsTableData = {
    columns: [
      { 
        Header: "Name", 
        accessor: "name", 
        width: "25%",
        Cell: ({ row }) => (
          <MDTypography 
            component="span" 
            onClick={() => handleRowClick(row.original)}
            variant="caption" 
            color="text" 
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            {`${row.original.first_name} ${row.original.last_name}`}
          </MDTypography>
        )
      },
      { Header: "Email", accessor: "email", width: "25%" },
      { Header: "Phone", accessor: "phone", width: "20%" },
      { Header: "Company", accessor: "company", width: "20%" },
      { Header: "Created At", accessor: "created_at", width: "10%" },
    ],
    rows: contacts,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="group"
                title="Total Contacts"
                count={contacts.length}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          {/* Add more statistics cards as needed */}
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Contacts
                  </MDTypography>
                  <MDButton variant="gradient" color="dark" onClick={handleOpenModal}>
                    Add New Contact
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  {isLoading ? (
                    <TableSkeleton />
                  ) : (
                    <DataTable
                      table={contactsTableData}
                      isSorted={true}
                      entriesPerPage={{
                        defaultValue: 10,
                        entries: [5, 10, 15, 20, 25],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      canSearch
                      exportCSV={true}
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      
      {/* Modal for adding new contact */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Add New Contact
          </MDTypography>
          <form onSubmit={handleSaveContact}>
            <TextField fullWidth label="First Name" name="first_name" value={newContact.first_name} onChange={handleInputChange} variant="outlined" margin="normal" />
            <TextField fullWidth label="Last Name" name="last_name" value={newContact.last_name} onChange={handleInputChange} variant="outlined" margin="normal" />
            <TextField fullWidth label="Email" name="email" value={newContact.email} onChange={handleInputChange} variant="outlined" margin="normal" />
            <TextField fullWidth label="Phone" name="phone" value={newContact.phone} onChange={handleInputChange} variant="outlined" margin="normal" />
            <TextField fullWidth label="Company" name="company" value={newContact.company} onChange={handleInputChange} variant="outlined" margin="normal" />
            <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseModal} color="secondary" sx={{ marginRight: 2 }}>
                Close
              </MDButton>
              <MDButton type="submit" variant="gradient" color="info">
                Save Contact
              </MDButton>
            </MDBox>
          </form>
        </Box>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default Contacts;