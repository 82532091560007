import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Chip,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyFormatter from 'components/CurrencyFormatter';

function InlineEditField({ 
    label, 
    value, 
    onSave, 
    onError,
    readOnly = false, 
    multiline = false, 
    options = null, 
    isEditing,
    isMultiSelect = false,
    currency = null,
    renderValue = null,
    required = false,
}) {
    
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    setInputValue(value);
    setError('');
  }, [value]);

  const handleChange = (e, newValue) => {
    let updatedValue;
    if (isMultiSelect) {
      updatedValue = newValue;
    } else if (options) {
      updatedValue = e.target.value;
    } else if (currency) {
      updatedValue = parseFloat(e.target.value);
    } else {
      updatedValue = e.target.value;
    }
    setInputValue(updatedValue);
    setError('');
    onError && onError(label, '');
    onSave(updatedValue);
  };

  const validateField = (label, value) => {
    if (required && !value) return 'This field is required';
    if (!value) return ''; // Don't validate empty fields if not required
    switch (label.toLowerCase()) {
      case 'email':
        return /\S+@\S+\.\S+/.test(value) ? '' : 'Invalid email format';
      case 'phone':
        return /^\d{10}$/.test(value) ? '' : 'Phone number must be 10 digits';
        case 'website':
          return /^[a-zA-Z0-9][a-zA-Z0-9-._]*\.[a-zA-Z]{2,}$/i.test(value) ? '' : 'Please enter a valid website domain (e.g. example.com)';
      default:
        return '';
    }
  };

  const handleBlur = () => {
    const validationError = validateField(label, inputValue);
    setError(validationError);
    onError && onError(label, validationError);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !multiline) {
      e.preventDefault();
      handleBlur();
    }
  };

  if (isEditing && !readOnly) {
    if (currency) {
      return (
        <TextField
          fullWidth
          label={label}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          error={!!error}
          helperText={error}
          type="number"
          InputProps={{
            startAdornment: <span>{currency}</span>,
          }}
          required={required}
        />
      );
    } else if (isMultiSelect) {
      return (
        <Autocomplete
          multiple
          options={options.map(option => option.label)}
          value={Array.isArray(inputValue) ? inputValue : []}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error}
              required={required}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={option.label || option}
                {...getTagProps({ index })}
              />
            ))
          }
          onBlur={handleBlur}
        />
      );
    } else if (options) {
      return (
        <FormControl fullWidth error={!!error} required={required}>
          <InputLabel id={`inline-edit-${label}`}>{label}</InputLabel>
          <Select
            labelId={`inline-edit-${label}`}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            label={label}
            IconComponent={() => <ArrowDropDownIcon style={{ scale: '1.5' }} />}
            style={{ padding: '11px' }}
          >
            {options.map((option) => (
              <MenuItem key={option.value || option} value={option.value || option}>
                {option.label || option}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      );
    } else {
      return (
        <TextField
          fullWidth
          label={label}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          multiline={multiline}
          rows={multiline ? 4 : 1}
          error={!!error}
          helperText={error}
          required={required}
        />
      );
    }
  } else {
    let displayContent;
    if (renderValue) {
      displayContent = renderValue(value);
    } else if (currency) {
      displayContent = <CurrencyFormatter amount={value} currency={currency} />;
    } else if (isMultiSelect && Array.isArray(value)) {
      displayContent = value.map(v => v.label || v).join(', ');
    } else if (options && !isMultiSelect) {
      const selectedOption = options.find(opt => opt.value === value || opt === value);
      displayContent = selectedOption ? (selectedOption.label || selectedOption) : value;
    } else {
      displayContent = value || "";
    }

    return (
      <MDTypography variant="button" fontWeight="regular" color="text">
        <strong>{label}:</strong>{' '}
        {displayContent}
      </MDTypography>
    );
  }
}

export default InlineEditField;