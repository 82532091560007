import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const AccountFormModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  managers,
  currencies,
  countries,
  loadingCountries,
  defaultCurrency,
  user,
}) => {
  const [formData, setFormData] = useState({
   
    account_status: 'Active',
   
  });

  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
  const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [formErrors, setFormErrors] = useState({});
  const [selectedAgent, setSelectedAgent] = useState(null);

   useEffect(() => {
    if (user) {
      const currentUserAsAgent = {
        id: user.id,
        username: user.username
      };
      setSelectedAgent(currentUserAsAgent);
    }
  }, [user]);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        ...formData,
        account_status: 'Active',
      });
      setSelectedBillingCountry(null);
      setSelectedShippingCountry(null);
      setCurrency(defaultCurrency);
      setFormErrors({});
    }
  }, [isOpen, defaultCurrency]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleSaveAccount = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSave({
        ...formData,
        billing_country: selectedBillingCountry?.label,
        shipping_country: selectedShippingCountry?.label,
        manager_id: selectedManager?.id,
        manager_name: selectedManager?.username,
        currency: currency?.code,
        created_by: user?.id,
        updated_by: user?.id,
        created_by_name: user?.username,
        updated_by_name: user?.username,
      });
      onClose();
    }
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name) {
      errors.name = 'Account name is required';
    }

    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Invalid email address';
    }

    if (formData.phone && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(formData.phone)) {
      errors.phone = 'Invalid phone number';
    }

    if (formData.website && !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(formData.website)) {
      errors.website = 'Invalid website URL';
    }

    if (formData.mobile_number && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(formData.mobile_number)) {
      errors.mobile_number = 'Invalid mobile number';
    }

    if (formData.number_of_employees && (isNaN(formData.number_of_employees) || parseInt(formData.number_of_employees) < 0)) {
      errors.number_of_employees = 'Number of employees must be a non-negative integer';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCurrencyChange = (_, newValue) => {
    setCurrency(newValue);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="account-form-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', sm: '80%', md: '70%', lg: '60%'},
        maxWidth: 800,
        maxHeight: '90vh',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <MDTypography id="account-form-modal-title" variant="h6" component="h2" mb={2}>
          Add New Account
        </MDTypography>
        <form onSubmit={handleSaveAccount}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                name="name" 
                label="Account Name" 
                variant="outlined" 
                margin="normal" 
                required
                onChange={handleInputChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Account Type</InputLabel>
                <Select
                  name="account_type"
                  label="Account Type"
                  value={formData.account_type}
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  {["Customer", "Partner", "Prospect"].map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Account Status</InputLabel>
                <Select
                  name="account_status"
                  label="Account Status"
                  value={formData.account_status}
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  {["Active", "Inactive", "On Hold"].map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Industry</InputLabel>
                <Select
                  name="industry"
                  label="Industry"
                  value={formData.industry}
                  onChange={handleInputChange}
                  sx={{ height: '55px' }}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {[
                    "Automotive", "Banking", "Construction", "Education",
                    "Financial Services", "Government", "Healthcare", "Manufacturing",
                    "Media", "Retail", "Technology", "Travel", "Other"
                  ].map((industry) => (
                    <MenuItem key={industry} value={industry}>{industry}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="annual_revenue" 
                label="Annual Revenue" 
                variant="outlined" 
                margin="normal" 
                type="number" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="number_of_employees" 
                label="Number of Employees" 
                variant="outlined" 
                margin="normal" 
                type="number"
                inputProps={{ min: 0 }}
                onChange={handleInputChange}
                error={!!formErrors.number_of_employees}
                helperText={formErrors.number_of_employees}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) => `${option.name} (${option.code})`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
                value={currency}
                onChange={handleCurrencyChange}
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="website" 
                label="Website" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.website}
                helperText={formErrors.website}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="email" 
                label="Email" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="phone" 
                label="Phone" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="mobile_number" 
                label="Mobile Number" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.mobile_number}
                helperText={formErrors.mobile_number}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="fax" 
                label="Fax" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            {/* Billing Address Section */}
            <Grid item xs={12}>
              <MDTypography variant="subtitle1" mb={2}>
                Billing Address
              </MDTypography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                fullWidth 
                name="billing_address" 
                label="Street Address" 
                variant="outlined" 
                margin="normal" 
                multiline
                rows={2}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="billing_city" 
                label="City" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="billing_state" 
                label="State" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                loading={loadingCountries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Country"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCountries ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                value={selectedBillingCountry}
                onChange={(_, newValue) => setSelectedBillingCountry(newValue)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="billing_postal_code" 
                label="Postal Code" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            {/* Shipping Address Section */}
            <Grid item xs={12}>
              <MDTypography variant="subtitle1" mb={2}>
                Shipping Address
              </MDTypography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                fullWidth 
                name="shipping_address" 
                label="Street Address" 
                variant="outlined" 
                margin="normal" 
                multiline
                rows={2}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="shipping_city" 
                label="City" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="shipping_state" 
                label="State" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                loading={loadingCountries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Country"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCountries ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                value={selectedShippingCountry}
                onChange={(_, newValue) => setSelectedShippingCountry(newValue)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="shipping_postal_code" 
                label="Postal Code" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={managers}
                getOptionLabel={(option) => option.username}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Account Manager"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                value={selectedManager}
                onChange={(_, newValue) => setSelectedManager(newValue)}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField 
                fullWidth 
                name="notes" 
                label="Notes" 
                variant="outlined" 
                margin="normal" 
                multiline 
                rows={3} 
                onChange={handleInputChange}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.tags || []}
                onChange={(_, newValue) => {
                  setFormData(prev => ({
                    ...prev,
                    tags: newValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Add tags"
                    helperText="Press enter to add new tags"
                  />
                )}
              />
            </Grid> */}
          </Grid>

          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton onClick={onClose} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton type="submit" variant="gradient" color="info">
              Save Account
            </MDButton>
          </MDBox>
        </form>
      </Box>
    </Modal>
  );
};

export default AccountFormModal;