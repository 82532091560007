import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  ListItemText,
  IconButton,
  Chip,
  Box,
} from "@mui/material";
import MDButton from "components/MDButton";
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Add } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const objectOptions = [
  "lead", "account", "deal", "inventory", "quotation", "sales_order", "activity"
];

const comparators = [
  "eq", "ne", "gt", "lt", "ge", "le", "contains", "startswith", "endswith"
];

const objectFields = {
  lead: ["first_name", "last_name", "email", "phone", "company_name", "lead_status", "lead_source", "created_at"],
  account: ["name", "industry", "annual_revenue", "number_of_employees", "website", "created_at"],
  deal: ["name", "value", "stage", "close_date", "probability", "created_at"],
  inventory: ["product_id", "quantity", "location", "created_at"],
  quotation: ["quote_date", "total_amount", "status", "account_name", "created_at"],
  sales_order: ["so_number", "po_start_date", "po_end_date", "delivery_schedule", "approval_status", "created_at"],
  activity: ["type", "description", "date", "lead_id", "created_at"]
};

function Reports() {
  const [selectedObject, setSelectedObject] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [filters, setFilters] = useState([]);
  const [reportData, setReportData] = useState([]);
  const { axiosInstance } = useAuth();

  useEffect(() => {
    if (selectedObject) {
      setSelectedFields(objectFields[selectedObject]);
    }
  }, [selectedObject]);

  const handleAddFilter = () => {
    setFilters([...filters, { field: '', comparator: '', value: '' }]);
  };

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleResetFilters = () => {
    setFilters([]);
  };

  const handleFieldSelection = (event) => {
    setSelectedFields(event.target.value);
  };

  const handleRunReport = async () => {
    try {
      const reportRequest = {
        object: selectedObject.toLowerCase(),
        fields: selectedFields,
        filters: filters.map(filter => ({
          field: filter.field,
          comparator: comparators.indexOf(filter.comparator) > -1 ? filter.comparator : 'eq',
          value: filter.value
        }))
      };
      const response = await axiosInstance.post('/run-report', reportRequest);
      setReportData(response.data);
    } catch (error) {
      console.error('Error running report:', error);
    }
  };

  const handleExportCSV = async () => {
    try {
      const response = await axiosInstance.post('/export-report', {
        object: selectedObject.toLowerCase(),
        fields: selectedFields,
        filters: filters.map(filter => ({
          field: filter.field,
          comparator: comparators.indexOf(filter.comparator) > -1 ? filter.comparator : 'eq',
          value: filter.value
        }))
      }, { responseType: 'blob' });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedObject}_report.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting report:', error);
    }
  };

  const reportTableData = {
    columns: selectedFields.map(field => ({ Header: field, accessor: field })),
    rows: reportData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Custom Reports
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Select Object</InputLabel>
                      <Select
                        value={selectedObject}
                        onChange={(e) => setSelectedObject(e.target.value)}
                        sx={{ 
                          height: 45,
                          '& .MuiSelect-icon': {
                            display: 'block',
                          },
                        }}
                        IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                      >
                        {objectOptions.map((option) => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {selectedObject && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Select Fields</InputLabel>
                        <Select
                          multiple
                          value={selectedFields}
                          onChange={handleFieldSelection}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          sx={{ 
                            height: 50,
                            '& .MuiSelect-icon': {
                              display: 'block',
                            },
                          }}
                          IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                        >
                          {objectFields[selectedObject].map((field) => (
                            <MenuItem key={field} value={field}>
                              <Checkbox checked={selectedFields.indexOf(field) > -1} />
                              <ListItemText primary={field} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {filters.map((filter, index) => (
                      <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                              <InputLabel>Field</InputLabel>
                              <Select
                                value={filter.field}
                                onChange={(e) => handleFilterChange(index, 'field', e.target.value)}
                                sx={{ 
                                  height: 45,
                                  '& .MuiSelect-icon': {
                                    display: 'block',
                                  },
                                }}
                                IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                              >
                                {objectFields[selectedObject]?.map((field) => (
                                  <MenuItem key={field} value={field}>{field}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                              <InputLabel>Comparator</InputLabel>
                              <Select
                                value={filter.comparator}
                                onChange={(e) => handleFilterChange(index, 'comparator', e.target.value)}
                                sx={{ 
                                  height: 45,
                                  '& .MuiSelect-icon': {
                                    display: 'block',
                                  },
                                }}
                                IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                              >
                                {comparators.map((comp) => (
                                  <MenuItem key={comp} value={comp}>{comp}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              fullWidth
                              label="Value"
                              value={filter.value}
                              onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <IconButton onClick={() => handleRemoveFilter(index)} color="error">
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <MDButton
                        variant="contained"
                        color="info"
                        onClick={handleAddFilter}
                        startIcon={<Add/>}
                      >
                        Add Filter
                      </MDButton>
                      <MDButton
                        variant="outlined"
                        color="info"
                        onClick={handleResetFilters}
                        startIcon={<RefreshIcon />}
                      >
                        Reset Filters
                      </MDButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <MDButton
                        variant="contained"
                        color="success"
                        onClick={handleRunReport}
                      >
                        Run Report
                      </MDButton>
                      {reportData.length > 0 && (
                        <MDButton
                          variant="contained"
                          color="info"
                          onClick={handleExportCSV}
                          startIcon={<FileDownloadIcon />}
                        >
                          Export CSV
                        </MDButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </MDBox>
              {reportData.length > 0 && (
                <MDBox pt={3}>
                  <DataTable
                    table={reportTableData}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Reports;
