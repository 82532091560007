import React from 'react';
import {
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function QualificationModal({ 
  isOpen, 
  onClose, 
  qualificationData, 
  handleQualificationDataChange, 
  handleQualificationConfirm 
}) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="qualification-modal-title"
      aria-describedby="qualification-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <MDTypography id="qualification-modal-title" variant="h6" component="h2" mb={2}>
          Confirm Lead Qualification
        </MDTypography>
        <MDTypography 
          id="qualification-modal-description" 
          mb={4}
          variant="body2"
          fontWeight="regular"
          fontSize="0.875rem"
          color="text"
        >
          Please provide the following information to create a deal for this qualified lead:
        </MDTypography>
        <TextField
          fullWidth
          label="Deal Value"
          name="value"
          type="number"
          value={qualificationData.value}
          onChange={handleQualificationDataChange}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="deal-stage-label">Deal Stage</InputLabel>
          <Select
            labelId="deal-stage-label"
            id="deal-stage"
            name="stage"
            value={qualificationData.stage}
            label="Deal Stage"
            onChange={handleQualificationDataChange}
            sx={{ height: '40px' }}
          >
            <MenuItem value="Qualification">Qualification</MenuItem>
            <MenuItem value="Needs Analysis">Needs Analysis</MenuItem>
            <MenuItem value="Proposal">Proposal</MenuItem>
            <MenuItem value="Negotiation">Negotiation</MenuItem>
            <MenuItem value="Closed Won">Closed Won</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Probability (%)"
          name="probability"
          type="number"
          value={qualificationData.probability}
          onChange={handleQualificationDataChange}
          margin="normal"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
        />
        <MDBox display="flex" justifyContent="flex-end" mt={4}>
          <MDButton onClick={onClose} color="secondary" sx={{ marginRight: 2 }}>
            Cancel
          </MDButton>
          <MDButton onClick={handleQualificationConfirm} variant="contained" color="info">
            Confirm
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );
}

export default QualificationModal;