import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../auth/Auth';

const MessageContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  maxWidth: '70%',
}));

const UserMessage = styled(MessageContainer)(({ theme }) => ({
  alignSelf: 'flex-end',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const AssistantMessage = styled(MessageContainer)(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
}));

const RAGChatInterface = () => {
  const { axiosInstance } = useAuth();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async () => {
    if (!input.trim() || isLoading) return;

    const newMessages = [...messages, { role: 'user', content: input }];
    setMessages(newMessages);
    setInput('');
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Sending message:', { message: input });
      const response = await axiosInstance.post('/chat', {
        message: input
      });

      console.log('Received response:', response.data);
      setMessages([...newMessages, { role: 'assistant', content: response.data.response }]);
    } catch (error) {
      console.error('Error sending message:', error);
      setError(error.response?.data?.detail || 'An unexpected error occurred');
      setMessages([...newMessages, { role: 'assistant', content: 'Sorry, I encountered an error. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
        <CardHeader title="CRM AI Assistant" />
        <CardContent>
          <Box sx={{ height: 400, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            {messages.map((msg, index) => (
              msg.role === 'user' ? (
                <UserMessage key={index} elevation={1}>
                  <Typography variant="body1">{msg.content}</Typography>
                </UserMessage>
              ) : (
                <AssistantMessage key={index} elevation={1}>
                  <Typography variant="body1">{msg.content}</Typography>
                </AssistantMessage>
              )
            ))}
            <div ref={messagesEndRef} />
          </Box>
        </CardContent>
        <CardActions sx={{ padding: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Ask about your CRM data..."
            disabled={isLoading}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            onClick={sendMessage}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </Button>
        </CardActions>
      </Card>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RAGChatInterface;