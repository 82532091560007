import React, { useState, useEffect } from 'react';
import { useAuth } from 'auth/Auth';
import {
  Card,
  Grid,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

function EmailSettings() {
  const { axiosInstance, user } = useAuth();
  const [emailConfig, setEmailConfig] = useState({
    provider: '',
    email: '',
    password: '',
    imap_host: '',
    imap_port: '',
    smtp_host: '',
    smtp_port: '',
    gmail_token: '', // New field for Gmail token
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    fetchEmailConfig();
  }, []);

  const fetchEmailConfig = async () => {
    try {
      const response = await axiosInstance.get('/email/config/');
      setEmailConfig(response.data);
      setIsUpdating(Object.keys(response.data).length > 0);
      console.log(`Email config: ${JSON.stringify(response.data)}`);
    } catch (error) {
      console.error('Error fetching email configuration:', error);
      setSnackbar({
        open: true,
        message: `Error fetching email configuration: ${error.response?.data?.detail || error.message}`,
        severity: 'error',
      });
    }
  };

  const handleInputChange = (e) => {
    setEmailConfig({ ...emailConfig, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const method = isUpdating ? 'put' : 'post';
      await axiosInstance[method]('/email/config/', {...emailConfig, ["user_id"]: user.id});
      setSnackbar({
        open: true,
        message: `Email configuration ${isUpdating ? 'updated' : 'saved'} successfully`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving email configuration:', error);
      setSnackbar({
        open: true,
        message: `Error ${isUpdating ? 'updating' : 'saving'} email configuration: ${error.response?.data?.detail || error.message}`,
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleTestConnection = async () => {
    setIsTesting(true);
    try {
      const response = await axios.post('http://localhost:8000/test-email-connection/', emailConfig, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        }
      );
      setSnackbar({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error testing connection:', error);
      setSnackbar({
        open: true,
        message: `Error testing connection: ${error.response?.data?.detail || error.message}`,
        severity: 'error',
      });
    } finally {
      setIsTesting(false);
    }
  };

  const handleGmailAuth = async () => {
    console.log('in Gmail Auth method');
    const clientId = '257313893513-560o628mu8moacl38vditneo8qhjep21.apps.googleusercontent.com';
    const redirectUri = 'http://localhost:3000/auth/gmail/callback';
    const scope = 'https://mail.google.com/';
    
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}`;
    
    // Open a new window for Gmail authentication
    window.open(authUrl, 'Gmail Authentication', 'width=600,height=600');

    // Listen for the callback from the authentication window
    window.addEventListener('message', async (event) => {
      console.log('in Gmail Auth Listener');
      if (event.origin !== window.location.origin) return;

      if (event.data.type === 'GMAIL_AUTH_SUCCESS') {
        const { accessToken } = event.data;
        console.log(`data: ${JSON.stringify(event.data)}`);
        
        
        console.log('in Gmail Auth method Sucess');
        // Save the token in the email configuration
        setEmailConfig(prev => ({ ...prev, gmail_token: accessToken, provider: 'gmail' }));
        
        // You may want to send this token to your backend for storage
        console.log(`Code Reached till Authentication Endpoint ${accessToken}`);
        try {
          await axiosInstance.post('/email/config/gmail', { token: accessToken, user_id: user.id });
          setSnackbar({
            open: true,
            message: 'Gmail authentication successful',
            severity: 'success',
          });
        } catch (error) {
          console.error('Error saving Gmail token:', error);
          setSnackbar({
            open: true,
            message: `Error saving Gmail token: ${error.response?.data?.detail || error.message}`,
            severity: 'error',
          });
        }
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Card>
      <MDBox p={3}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Email Provider</InputLabel>
                <Select
                  name="provider"
                  label="Email Provider"
                  value={emailConfig.provider}
                  onChange={handleInputChange}
                  sx={{ 
                    height: 45,
                    '& .MuiSelect-icon': {
                      display: 'block',
                    },
                  }}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[
                    "Gmail",
                    "Outlook",
                    "Custom",
                    "Office365",
                    "Yahoo",
                    "Outlook.com",
                    "Protonmail", 
                    "Other"
                  ].map((provider) => (
                    <MenuItem key={provider} value={provider.toLowerCase()}>
                      {provider}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {emailConfig.provider === 'gmail' ? (
                <MDBox mt={2}>
                  <MDButton 
                    onClick={handleGmailAuth} 
                    variant="outlined" 
                    color="info" 
                    fullWidth
                  >
                    Authenticate with Gmail
                  </MDButton>
                </MDBox>
              ) : (
                <>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    value={emailConfig.email}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    value={emailConfig.password}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                </>
              )}
            </Grid>
            {emailConfig.provider !== 'gmail' && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="IMAP Host"
                  name="imap_host"
                  value={emailConfig.imap_host}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="IMAP Port"
                  name="imap_port"
                  value={emailConfig.imap_port}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="SMTP Host"
                  name="smtp_host"
                  value={emailConfig.smtp_host}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="SMTP Port"
                  name="smtp_port"
                  value={emailConfig.smtp_port}
                  onChange={handleInputChange}
                  margin="normal"
                />
              </Grid>
            )}
          </Grid>
          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            {emailConfig.provider !== 'gmail' && (
              <MDButton 
                onClick={handleTestConnection} 
                variant="outlined" 
                color="info" 
                sx={{ mr: 2 }}
                disabled={isTesting}
              >
                {isTesting ? 'Testing...' : 'Test Connection'}
              </MDButton>
            )}
            <MDButton 
              type="submit" 
              variant="gradient" 
              color="info"
              disabled={isSaving}
            >
              {isSaving ? (isUpdating ? 'Updating...' : 'Saving...') : (isUpdating ? 'Update Configuration' : 'Save Configuration')}
            </MDButton>
          </MDBox>
        </form>
      </MDBox>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default EmailSettings;