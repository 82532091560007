import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Calendar from "examples/Calendar";

import { useAuth } from "auth/Auth";

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import NoteIcon from '@mui/icons-material/Note';
import { styled } from '@mui/material/styles';
import TaskOverviewTabs from "./components/TabbedTaskOverview";

const colorOptions = [
  { value: "#4e79a7", label: "Blue" },
  { value: "#59a14f", label: "Green" },
  { value: "#edc949", label: "Yellow" },
  { value: "#e15759", label: "Red" },
  { value: "#af7aa1", label: "Purple" },
];

const relatedToOptions = [
  { value: "account", label: "Account" },
  { value: "contact", label: "Contact" },
  { value: "lead", label: "Lead" },
  { value: "deal", label: "Deal" },
];

// Update the ColorSwatch component
const ColorSwatch = styled('div')(({ color }) => ({
  width: 28,
  height: 28,
  borderRadius: '50%',
  backgroundColor: color,
  cursor: 'pointer',
  border: '2px solid transparent',
  margin: '0 4px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '&.selected': {
    border: `2px solid #ffffff`,
    boxShadow: `0 0 0 2px ${color}, 0 0 0 4px rgba(0,0,0,0.1)`,
  },
}));



function Tasks() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { user, axiosInstance } = useAuth();
  const [users, setUsers] = useState([]);

  // Add these new state variables
  const [relatedEntities, setRelatedEntities] = useState({});
  const [selectedRelatedEntity, setSelectedRelatedEntity] = useState(null);

  useEffect(() => {
    fetchEvents();
    fetchUsers();
  }, []);

  // const fetchEvents = async () => {
  //   try {
  //     const response = await axiosInstance.get(`/tasks/user/${user.id}`);
  //     const formattedEvents = response.data.map(task => ({
  //       id: task.id,
  //       title: task.title,
  //       start: task.start,
  //       end: task.end,
  //       allDay: task.all_day,
  //       backgroundColor: task.color, // Use the color directly
  //       borderColor: task.color,
  //       extendedProps: {
  //         type: task.type,
  //         assignee_id: task.assignee_id,
  //         assignee_name: task.assignee_name,
  //         related_to: {
  //           type: task.related_to_type,
  //           id: task.related_to_id
  //         }
  //       }
  //     }));
  //     setEvents(formattedEvents);
  //     console.log(`Events fetched successfully: ${JSON.stringify(formattedEvents)}`);
      
  //   } catch (error) {
  //     console.error('Error fetching events:', error);
  //     showSnackbar('Failed to fetch events', 'error');
  //   }
  // };

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(`/users/`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Failed to fetch users', 'error');
    }
  }
  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get(`/tasks/user/${user.id}`);
      const formattedEvents = response.data.map(task => ({
        id: task.id,
        title: task.title,
        start: task.start,
        end: task.end,
        allDay: task.all_day,
        backgroundColor: task.color,
        borderColor: task.color,
        textColor: '#ffffff', // Ensure text is visible on all color backgrounds
        extendedProps: {
          type: task.type,
          assignee_id: task.assignee_id,
          assignee_name: task.assignee_name,
          related_to: {
            type: task.related_to_type,
            id: task.related_to_id
          },
          notes: task.notes,
          status: task.status
        }
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      showSnackbar('Failed to fetch events', 'error');
    }
  };

  // const handleEventClick = (info) => {
  //   if (!info || !info.event) {
  //     console.error('Invalid event data received');
  //     return;
  //   }

  //   const eventData = info.event.toPlainObject();
  //   if (!eventData) {
  //     console.error('Failed to convert event to plain object');
  //     return;
  //   }

  //   // Safely access properties with default values
  //   setSelectedEvent({
  //     ...eventData,
  //     start: eventData.start ? new Date(eventData.start).toISOString().slice(0, 16) : '',
  //     end: eventData.end ? new Date(eventData.end).toISOString().slice(0, 16) : '',
  //     color: info.event.classNames ? info.event.classNames[0] || "info" : "info",
  //     type: eventData.extendedProps?.type || "Event", // Provide a default type
  //     related_to: eventData.extendedProps?.related_to || { type: "", id: "" },
  //     assignee_id: eventData.extendedProps?.assignee_id || user.id,
  //   });
  //   setIsNewEvent(false);
  //   setIsModalOpen(true);
  // };

  const handleEventClick = (info) => {
    if (!info || !info.event) {
      console.error('Invalid event data received');
      return;
    }
  
    const eventData = info.event.toPlainObject();
    if (!eventData) {
      console.error('Failed to convert event to plain object');
      return;
    }
  
    setSelectedEvent({
      ...eventData,
      start: eventData.start ? new Date(eventData.start).toISOString().slice(0, 16) : '',
      end: eventData.end ? new Date(eventData.end).toISOString().slice(0, 16) : '',
      color: eventData.backgroundColor, // Use the backgroundColor as the color
      type: eventData.extendedProps?.type || "Event",
      related_to: eventData.extendedProps?.related_to || { type: "", id: "" },
      assignee_id: eventData.extendedProps?.assignee_id || user.id,
      notes: eventData.extendedProps?.notes || "",
    });
    setIsNewEvent(false);
    setIsModalOpen(true);
  };
  
  
  const handleDateClick = (info) => {
    console.log(`Date clicked: ${info.dateStr}`);
    const clickedDate = new Date(info.dateStr);
    
    // Get current date and time in IST
    const options = { timeZone: 'Asia/Kolkata' };
    const nowInIST = new Date(new Date().toLocaleString('en-US', options));
    
    // Set the clicked date to the current IST time
    clickedDate.setUTCHours(nowInIST.getHours(), nowInIST.getMinutes(), nowInIST.getSeconds(), nowInIST.getMilliseconds());
    
    // Set end date 30 minutes ahead
    let endDate = new Date(clickedDate);
    endDate.setMinutes(endDate.getMinutes() + 30);
  
    console.log(`Start date (IST): ${clickedDate.toLocaleString('en-US', options)}`);
    console.log(`End date (IST): ${endDate.toLocaleString('en-US', options)}`);
  
    const newEvent = {
      title: "",
      start: clickedDate.toISOString().slice(0, 16), // Format: "YYYY-MM-DDTHH:mm"
      end: endDate.toISOString().slice(0, 16),
      allDay: false, // Since we're setting specific times, it's not an all-day event
      type: "Event",
      color: "info",
      assignee_id: user.id,
      related_to: { type: "", id: "" },
      notes: "",
    };
    setSelectedEvent(newEvent);
    setIsNewEvent(true);
    setIsModalOpen(true);
  };

  // Add this new function to handle event deletion
const handleDeleteEvent = async () => {
  try {
    await axiosInstance.delete(`/tasks/${selectedEvent.id}`);
    setEvents(events.filter(event => event.id !== selectedEvent.id));
    handleCloseModal();
    showSnackbar('Task deleted successfully');
    fetchEvents(); // Refetch to ensure we have the latest data
  } catch (error) {
    console.error('Error deleting event:', error);
    showSnackbar('Failed to delete task', 'error');
  }
};

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setIsNewEvent(false);
  };

  const handleSaveEvent = async () => {
    try {
      const taskData = {
        title: selectedEvent.title,
        type: selectedEvent.type,
        start: selectedEvent.start,
        end: selectedEvent.end,
        all_day: selectedEvent.allDay,
        color: selectedEvent.color,
        assignee_id: selectedEvent.assignee_id,
        related_to_type: selectedEvent.related_to.type,
        related_to_id: selectedEvent.related_to.id,
        notes: selectedEvent.notes,
      };

      console.log(`Task data: ${JSON.stringify(taskData, null, 2)}`);
      
      const response = isNewEvent
        ? await axiosInstance.post('/tasks', taskData)
        : await axiosInstance.put(`/tasks/${selectedEvent.id}`, taskData);
        console.log(`Response: ${JSON.stringify(response)}`);

        const notificationData = await axiosInstance.post('/notifications', {
          user_id: selectedEvent.assignee_id,
          message: `New task ${response.data.title} has been assigned to you by ${user.username}`,
          owner_id: user.id,
        })
        

      if (isNewEvent) {
        setEvents([...events, response.data]);
      } else {
        setEvents(events.map(event => event.id === response.data.id ? response.data : event));
      }

      handleCloseModal();
      showSnackbar(`Task ${isNewEvent ? 'created' : 'updated'} successfully`);
      fetchEvents(); // Refetch to ensure we have the latest data
    } catch (error) {
      console.error('Error saving event:', error);
      showSnackbar(`Failed to ${isNewEvent ? 'create' : 'update'} task ${error}`, 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedEvent((prev) => ({ ...prev, [name]: checked }));
  };

  // const handleRelatedToChange = (e) => {
  //   const { name, value } = e.target;
  //   setSelectedEvent((prev) => ({
  //     ...prev,
  //     related_to: { ...prev.related_to, [name]: value },
  //   }));
  // };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

    // Add this new function to fetch related entities
    const fetchRelatedEntities = async (type) => {
      try {
        const response = await axiosInstance.get(`/${type}s`); // Assuming the endpoint is plural
        setRelatedEntities({ ...relatedEntities, [type]: response.data });
      } catch (error) {
        console.error(`Error fetching ${type}s:`, error);
        showSnackbar(`Failed to fetch ${type}s`, 'error');
      }
    };

    // Modify the handleRelatedToChange function
    const handleRelatedToChange = async (e) => {
      const { name, value } = e.target;
      if (name === 'type') {
        setSelectedEvent((prev) => ({
          ...prev,
          related_to: { type: value, id: '' },
        }));
        setSelectedRelatedEntity(null);
        if (value && !relatedEntities[value]) {
          await fetchRelatedEntities(value);
        }
      } else if (name === 'id') {
        const selectedEntity = relatedEntities[selectedEvent.related_to.type].find(entity => entity.id === value);
        setSelectedRelatedEntity(selectedEntity);
        setSelectedEvent((prev) => ({
          ...prev,
          related_to: { ...prev.related_to, id: value },
        }));
      }
    };

    // const renderTaskItem = (event, index) => (
    //   <MDBox
    //     key={index}
    //     mb={2}
    //     display="flex"
    //     alignItems="center"
    //     sx={{
    //       backgroundColor: event.backgroundColor,
    //       borderRadius: "8px",
    //       padding: "8px",
    //       transition: "background-color 0.3s",
    //       "&:hover": {
    //         backgroundColor: `${event.backgroundColor}dd`,
    //         cursor: "pointer",
    //       },
    //     }}
    //     onClick={() => handleEventClick({ event })}
    //   >
    //     <Icon
    //       fontSize="small"
    //       sx={{ marginRight: 1, color: '#ffffff' }}
    //     >
    //       {event.allDay ? "event" : "schedule"}
    //     </Icon>
    //     <MDBox>
    //       <MDTypography variant="body2" fontWeight="medium" sx={{ color: '#ffffff' }}>
    //         {event.title}
    //       </MDTypography>
    //       <MDTypography variant="caption" sx={{ color: '#ffffff' }}>
    //         {new Date(event.start).toLocaleDateString()} 
    //         {!event.allDay && ` ${new Date(event.start).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}
    //       </MDTypography>
    //     </MDBox>
    //   </MDBox>
    // );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <MDBox mb={3}>
              <Calendar
                header={{ title: "Calendar" }}
                events={events}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                height="calc(100vh - 310px)"
                
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={3}>
          {/* <Card>
            <MDBox p={3}>
              <MDTypography variant="h6" fontWeight="medium" mb={2}>
                Upcoming Events
              </MDTypography>
              <MDBox>
                {events
                  .filter((event) => new Date(event.start) > new Date())
                  .sort((a, b) => new Date(a.start) - new Date(b.start))
                  .slice(0, 5)
                  .map(renderTaskItem)}
              </MDBox>
            </MDBox>
          </Card>

            <Card sx={{ mt: 3 }}>
              <MDBox p={3}>
                <MDTypography variant="h6" fontWeight="medium" mb={2}>
                  Recent Tasks
                </MDTypography>
                <MDBox>
                  {events
                    .filter((event) => new Date(event.start) < new Date())
                    .sort((a, b) => new Date(b.start) - new Date(a.start))
                    .slice(0, 5)
                    .map(renderTaskItem)}
                </MDBox>
              </MDBox>
            </Card> */}
            <TaskOverviewTabs events={events} />

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '16px',
      }}>
        <MDTypography variant="h6" component="h2" mb={3} display="flex" justifyContent="space-between" alignItems="center">
          {isNewEvent ? "Create New Task" : "Edit Task"}
          {!isNewEvent && (
            <IconButton
              aria-label="delete"
              onClick={handleDeleteEvent}
              sx={{ color: 'error.main' }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </MDTypography>
        {selectedEvent && (
          <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Box sx={{ flex: '1 1 45%', minWidth: 250 }}>
              <TextField
                fullWidth
                name="title"
                label="Task Title"
                value={selectedEvent.title || ''}
                onChange={handleInputChange}
                size="small"
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="event-type-label">Task Type</InputLabel>
                <Select
                  labelId="event-type-label"
                  name="type"
                  value={selectedEvent.type || ''}
                  onChange={handleInputChange}
                  label="Task Type"
                  sx={{ height: "40px" }}
                >
                  <MenuItem value="Event">Event</MenuItem>
                  <MenuItem value="Meeting">Meeting</MenuItem>
                  <MenuItem value="Task">Task</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                name="start"
                label="Start Date"
                type="datetime-local"
                value={selectedEvent.start || ''}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ mb: 2 }}
              />
              {!selectedEvent.allDay && (
                <TextField
                  fullWidth
                  name="end"
                  label="End Date"
                  type="datetime-local"
                  value={selectedEvent.end || ''}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{ mb: 2 }}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedEvent.allDay || false}
                    onChange={handleCheckboxChange}
                    name="allDay"
                    size="small"
                  />
                }
                label="All Day Event"
              />
            </Box>
            <Box sx={{ flex: '1 1 45%', minWidth: 250 }}>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="assignee-label">Assignee</InputLabel>
                <Select
                  labelId="assignee-label"
                  name="assignee_id"
                  value={selectedEvent.assignee_id || ''}
                  onChange={handleInputChange}
                  label="Assignee"
                  sx={{ height: "40px", mb: -0.5 }}
                >
                  {/* <MenuItem value={user.id}>{user.username}</MenuItem> */}
                  {users.map((users) => (
                    <MenuItem key={users.id} value={users.id}>
                      {users.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="related-to-type-label">Related To</InputLabel>
                <Select
                  labelId="related-to-type-label"
                  name="type"
                  value={selectedEvent.related_to?.type || ''}
                  onChange={handleRelatedToChange}
                  label="Related To"
                  sx={{height: "40px"}}
                >
                  {relatedToOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedEvent.related_to?.type && (
                <TextField
                  fullWidth
                  name="id"
                  label={`${selectedEvent.related_to.type.charAt(0).toUpperCase() + selectedEvent.related_to.type.slice(1)} ID`}
                  value={selectedEvent.related_to.id || ''}
                  onChange={handleRelatedToChange}
                  type="number"
                  size="small"
                  sx={{ mb: 2 }}
                />
              )}

          <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
            <MDTypography variant="subtitle2" gutterBottom>
              Color Label
            </MDTypography>
            <Box 
              display="flex" 
              justifyContent="flex-start" 
              alignItems="center" 
              mt={1}
              sx={{ 
                backgroundColor: (theme) => theme.palette.grey[100],
                borderRadius: '16px',
                padding: '8px 12px',
              }}
            >
              {colorOptions.map((option) => (
                <ColorSwatch
                  key={option.value}
                  color={option.value}
                  className={selectedEvent.color === option.value ? 'selected' : ''}
                  onClick={() => handleInputChange({ target: { name: 'color', value: option.value } })}
                  title={option.label}
                />
              ))}
            </Box>
          </FormControl>

            </Box>
            <Box sx={{ width: '100%' }}>
              <MDTypography variant="subtitle2" display="flex" alignItems="center" gutterBottom>
                <NoteIcon sx={{ mr: 1 }} fontSize="small" />
                Notes
              </MDTypography>
              <TextField
                fullWidth
                multiline
                rows={3}
                name="notes"
                value={selectedEvent.notes || ''}
                onChange={handleInputChange}
                placeholder="Add any additional notes here..."
                size="small"
              />
            </Box>
            <Box mt={3} width="100%" display="flex" justifyContent="flex-end">
              <Button 
                variant="outlined" 
                onClick={handleCloseModal} 
                sx={{ mr: 2, color: 'primary.main' }}
              >
                Cancel
              </Button>
              <Button 
                variant="gradient"
                color="primary" 
                onClick={handleSaveEvent}
              >
                {isNewEvent ? "Create Task" : "Save Changes"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Tasks;