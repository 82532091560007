import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const LeadFormModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  agents, 
  products, 
  currencies, 
  countries,
  loadingCountries,
  defaultCurrency,
  user,
}) => {
  const [formData, setFormData] = useState({
    lead_status: 'New',
    lead_rating: 'Hot',
    salutation: 'Mr.',
    designation: '',
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [formErrors, setFormErrors] = useState({});

  console.log(`User:`, user);
  
  useEffect(() => {
    if (user) {
      const currentUserAsAgent = {
        id: user.id,
        username: user.username
      };
      setSelectedAgent(currentUserAsAgent);
    }
  }, [user]);

  useEffect(() => {
    if (countries.length > 0) {
      const india = countries.find(country => country.label === 'India');
      if (india) {
        setSelectedCountry(india);
      }
    }
  }, [countries]);

  useEffect(() => {
    if (isOpen) {
      // setFormData(defaultFormData);
      setSelectedProducts([]);
      setSelectedCountry(null);
      setCurrency(defaultCurrency);
      setFormErrors({});
      
      // Don't reset selectedAgent here as we want to keep the default user
    }
  }, [isOpen, defaultCurrency]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleSaveLead = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSave({
        ...formData,
        country: selectedCountry?.label,
        assigned_to: selectedAgent?.id,
        interested_products: selectedProducts.map(p => p.name),
        currency: currency?.code,
        created_by: user?.id,
        updated_by: user?.id,
      });
       // Reset form data but keep the selected agent
      //  setFormData(defaultFormData);
       setSelectedProducts([]);
       setSelectedCountry(null);
       setCurrency(defaultCurrency);
       setFormErrors({});
       // Don't reset selectedAgent here
       
       onClose();
    }
  };

  const handleClose = () => {
    // Reset form data but keep the selected agent
    // setFormData(defaultFormData);
    setSelectedProducts([]);
    setSelectedCountry(null);
    setCurrency(defaultCurrency);
    setFormErrors({});
    // Don't reset selectedAgent here
    
    onClose();
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.last_name) {
      errors.last_name = 'Last name is required';
    }

    if (!formData.email && !formData.phone) {
      errors.email = 'Either Email or Phone is required';
      errors.phone = 'Either Email or Phone is required';
    }

    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Invalid email address';
    }

    if (formData.phone && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(formData.phone)) {
      errors.phone = 'Invalid phone number';
    }

    if (formData.website && !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(formData.website)) {
      errors.website = 'Invalid website URL';
    }

    if (formData.mobile && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(formData.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    if (formData.number_of_employees && (isNaN(formData.number_of_employees) || parseInt(formData.number_of_employees) < 0)) {
      errors.number_of_employees = 'Number of employees must be a non-negative integer';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCurrencyChange = (_, newValue) => {
    setCurrency(newValue);
  };

  const handleProductChange = (_, newValue) => {
    setSelectedProducts(newValue);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="lead-form-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', sm: '80%', md: '70%', lg: '60%'},
        maxWidth: 800,
        maxHeight: '90vh',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <MDTypography id="lead-form-modal-title" variant="h6" component="h2" mb={2}>
          Add New Lead
        </MDTypography>
        <form onSubmit={handleSaveLead}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Salutation</InputLabel>
                <Select
                  name="salutation"
                  label="Salutation"
                  value={formData.salutation}
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  {["Ms.", "Mr.", "Mrs.", "Dr.", "Prof."].map((salutation) => (
                    <MenuItem key={salutation} value={salutation}>{salutation}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="first_name" 
                label="First Name" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="last_name" 
                label="Last Name" 
                variant="outlined" 
                margin="normal" 
                required 
                onChange={handleInputChange}
                error={!!formErrors.last_name}
                helperText={formErrors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="designation" 
                label="Designation" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="email" 
                label="Email" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="phone" 
                label="Phone" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="mobile" 
                label="Mobile" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.mobile}
                helperText={formErrors.mobile}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="company_name" 
                label="Company Name" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="address" 
                label="Address" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                loading={loadingCountries}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="country"
                    label="Country"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCountries ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                value={selectedCountry}
                onChange={(_, newValue) => setSelectedCountry(newValue)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="state" 
                label="State" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="city"
                label="City"
                variant="outlined"
                margin="normal"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="postal_code"
                label="Postal Code"
                variant="outlined"
                margin="normal"
                type="number"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="website" 
                label="Website" 
                variant="outlined" 
                margin="normal" 
                onChange={handleInputChange}
                error={!!formErrors.website}
                helperText={formErrors.website}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Industry</InputLabel>
                <Select
                  name="industry"
                  label="Industry"
                  defaultValue=""
                  onChange={handleInputChange}
                  sx={{ height: '40px' }}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {[
                    "Automotive", "Banking", "Construction", "Education",
                    "Financial Services", "Government", "Healthcare", "Manufacturing",
                    "Media", "Retail", "Technology", "Travel", "Other"
                  ].map((industry) => (
                    <MenuItem key={industry} value={industry}>{industry}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="annual_revenue" 
                label="Annual Revenue" 
                variant="outlined" 
                margin="normal" 
                type="number" 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) => `${option.name} (${option.code})`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
                value={currency}
                onChange={handleCurrencyChange}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name} ({option.code})
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                fullWidth 
                name="number_of_employees" 
                label="Number of Employees" 
                variant="outlined" 
                margin="normal" 
                type="number"
                inputProps={{ min: 0 }}
                onChange={handleInputChange}
                error={!!formErrors.number_of_employees}
                helperText={formErrors.number_of_employees}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth 
                name="notes" 
                label="Notes" 
                variant="outlined" 
                margin="normal" 
                multiline 
                rows={3} 
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Lead Source</InputLabel>
                <Select
                  name="lead_source"
                  label="Lead Source"
                  defaultValue=""
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {[
                    "Website", "Referral", "Social Media", "Email Campaign",
                    "Trade Show", "Cold Call", "Advertisement", "Other"
                  ].map((source) => (
                    <MenuItem key={source} value={source}>{source}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Lead Status</InputLabel>
                <Select
                  name="lead_status"
                  label="Lead Status"
                  value={formData.lead_status}
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  {["New", "In Progress", "Qualified"].map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Lead Rating</InputLabel>
                <Select
                  name="lead_rating"
                  label="Lead Rating"
                  value={formData.lead_rating}
                  onChange={handleInputChange}
                  sx={{height: '55px'}}
                  IconComponent={() => <ArrowDropDown style={{ scale: '1.7', marginRight: '10px' }} />}
                >
                  {["Hot", "Warm", "Cold"].map((rating) => (
                    <MenuItem key={rating} value={rating}>{rating}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                options={agents}
                getOptionLabel={(option) => option.username}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="assigned_to"
                    label="Assigned To"
                    variant="outlined"
                    margin="normal"
                    error={!!formErrors.assigned_to}
                    helperText={formErrors.assigned_to}
                  />
                )}
                onChange={(_, newValue) => {
                  setSelectedAgent(newValue);
                  if (newValue) {
                    setFormErrors(prev => ({ ...prev, assigned_to: '' }));
                  }
                }}
                value={selectedAgent}
              />
            </Grid> */}

{/* <Grid item xs={12} sm={6}>
          <Autocomplete
            options={agents}
            getOptionLabel={(option) => option.username}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                name="assigned_to"
                label="Assigned To"
                variant="outlined"
                margin="normal"
                error={!!formErrors.assigned_to}
                helperText={formErrors.assigned_to}
              />
            )}
            onChange={(_, newValue) => {
              setSelectedAgent(newValue);
              if (newValue) {
                setFormErrors(prev => ({ ...prev, assigned_to: '' }));
              }
            }}
            value={selectedAgent}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </Grid> */}

          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={agents}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="assigned_to"
                  label="Assigned To"
                  variant="outlined"
                  margin="normal"
                  error={!!formErrors.assigned_to}
                  helperText={formErrors.assigned_to}
                />
              )}
              onChange={(_, newValue) => {
                setSelectedAgent(newValue || (user ? { id: user.id, username: user.username } : null));
                if (newValue) {
                  setFormErrors(prev => ({ ...prev, assigned_to: '' }));
                }
              }}
              value={selectedAgent}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={products}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Interested Products"
                    placeholder="Select products"
                  />
                )}
                value={selectedProducts}
                onChange={handleProductChange}
              />
            </Grid>
          </Grid>
          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton onClick={onClose} color="secondary" sx={{ marginRight: 2 }}>
              Close
            </MDButton>
            <MDButton type="submit" variant="gradient" color="info">
              Save Lead
            </MDButton>
          </MDBox>
        </form>
      </Box>
    </Modal>
  );
};

export default LeadFormModal;