import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  Skeleton,
  IconButton,
  Button,
} from "@mui/material";
import { 
  Delete, 
  FileCopy, 
  Edit, 
  Save, 
  Close 
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ThemedCustomStepper from 'components/CustomStepper';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Activities from 'layouts/dashboard/components/Activities';
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
// import ConvertLeadModal from 'components/CustomLeadForms/CustomLeadConvert';
import DeleteButton from 'components/CustomDeleteIcon';
import QualificationModal from 'components/LeadQulificationModal';
// import { Edit, Save, SquareX, Copy, Trash2, Edit2 } from 'lucide-react';
import ActivityModal from 'components/CustomModals/CustomActivityModal';
import { formatDate } from 'utils/DateTimeConversion';
import AttachmentUploadModal from 'components/CustomModals/AttachmentUploadModal';
import Attachments from 'layouts/dashboard/components/Attachments';



const cloneAnimationKeyframes = `
  @keyframes cloneAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
`;

function LeadDetail() {
  const { user, axiosInstance } = useAuth();
  const { id } = useParams();
  console.log(`Lead ID: ${id}`);
  
  const navigate = useNavigate();

  const location = useLocation();
  const leadData = location.state?.leadData;
  const [lead, setLead] = useState(leadData || {});

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoteLoading, setIsNoteLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [deleteRelated, setDeleteRelated] = useState(false);
  const [newActivity, setNewActivity] = useState({
    lead_id: id,
    type: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [activeStep, setActiveStep] = useState(0);
  const leadStatus = [
    'New',
    'In Progress',
    'Qualified',
    'Disqualified',
  ];
  const steps = ['New', 'In Progress', 'Qualified' , 'Disqualified'];
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const isLeadLocked = lead.lead_status === "Qualified" || lead.lead_status === "Disqualified";
  const [isQualificationModalOpen, setIsQualificationModalOpen] = useState(false);
  const [qualificationData, setQualificationData] = useState({
    value: '',
    stage: 'Qualification',
    probability: 0,
  });
  const [formErrors, setFormErrors] = useState({});

  const [agents, setAgents] = useState([]);
  const [isCloning, setIsCloning] = useState(false);

  const [attachments, setAttachments] = useState([]);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);


  useEffect(() => {
    fetchLeadData();
    fetchActivities();
    fetchAgents();
    fetchProducts();
    fetchAttachments();
  }, [id]);


  useEffect(() => {
    console.log('Form errors updated:', formErrors);
  }, [formErrors]);

  // Add these functions
// const fetchAttachments = async () => {
//   try {
//     const response = await axiosInstance.get(`/attachments/`);
//     setAttachments(response.data || []);
//   } catch (error) {
//     console.error('Error fetching attachments:', error);
//     setSnackbar({
//       open: true,
//       message: 'Error fetching attachments',
//       severity: 'error',
//     });
//   }
// };
const fetchAttachments = async () => {
  const parentTable = 'leads';
  const parentId = id;
  try {
    const response = await axiosInstance.get('/attachments/', {
      params: {
        parent_table: parentTable,
        parent_id: parentId
        // skip: 0,
        // limit: 100,
        // sort_by: 'created_at',
        // sort_order: 'desc'
      }
    });

    setAttachments(response.data || []);

  } catch (error) {
    console.error('Error fetching attachments:', error);
    const errorMessage = error.response?.data?.detail || 
                        error.response?.data?.message || 
                        error.message || 
                        'Error fetching attachments';
                        
    setSnackbar({
      open: true,
      message: errorMessage,
      severity: 'error',
      autoHideDuration: 5000
    });
  } finally {
    // If you have a loading state
    // setLoading(false);
  }
};

const handleDeleteAttachment = async (attachmentId) => {
  try {
    await axiosInstance.delete(`/attachments/${attachmentId}`);
    setAttachments(attachments.filter((attachment) => attachment.id !== attachmentId));
    setSnackbar({
      open: true,
      message: 'Attachment deleted successfully!',
      severity: 'success',
    });
  } catch (error) {
    console.error('Error deleting attachment:', error);
    setSnackbar({
      open: true,
      message: 'Error deleting attachment',
      severity: 'error',
    });
  }
};

const handleDownloadAttachment = async (attachment) => {
  try {
    
    // Fetch the file using the signed URL
    const response = await fetch(attachment.file_url);
    const blob = await response.blob();

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.file_name);
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading attachment:', error);
    setSnackbar({
      open: true,
      message: 'Error downloading attachment',
      severity: 'error',
    });
  }
};

  const fetchAgents = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const handleEditToggle = () => {
    if (isLeadLocked) {
      setSnackbar({
        open: true,
        message: 'This lead is locked and cannot be edited.',
        severity: 'warning',
      });
      return;
    }
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleCloneLead = async () => {
    try {
      const clonedLead = { ...lead };
      delete clonedLead.id;
      clonedLead.lead_status = "New";
      clonedLead.created_at = new Date().toISOString();
      clonedLead.updated_at = new Date().toISOString();

      clonedLead.last_activity = new Date().toISOString();

      const response = await axiosInstance.post('/leads/', clonedLead);
      setSnackbar({
        open: true,
        message: 'Lead cloned successfully!',
        severity: 'success',
      });

  setTimeout(() => {
    navigate(`/lead/detail/${response.data.id}`);
  }, 1000);
} catch (error) {
  console.error('Error cloning lead:', error);
  setSnackbar({
    open: true,
    message: 'Error cloning lead. Please try again.',
    severity: 'error',
  });
} finally {
  // Allow animation to complete before resetting
  setTimeout(() => {
    setIsCloning(false);
  }, 1000);
}
};

  const handleQualificationDataChange = (e) => {
    setQualificationData({
      ...qualificationData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/products/');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProducts(newValue);
    handleFieldUpdate('interested_products', newValue.map(product => product.name));
  };

  const handleFieldUpdate = (field, value) => {
    if (field === 'lead_status') {
      const stepIndex = steps.indexOf(value);
      if (stepIndex !== -1) {
        handleStepClick(stepIndex);
      }
    } else {
      console.log(`Updating field: ${field} with value: ${value}`);
      setUpdatedFields(prev => ({
        ...prev,
        [field]: value
      }));
      setFormErrors(prev => {
        const { [field]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleFieldError = (field, error) => {
    setFormErrors(prev => {
      if (error) {
        return { ...prev, [field]: error };
      } else {
        const { [field]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  
  const handleQualificationConfirm = async () => {
    setIsQualificationModalOpen(false);
    setIsLoading(true);
    try {
      const payload = { ...updatedFields, lead_status: 'Qualified' };
      const response = await axiosInstance.put(`/leads/${lead.id}`, payload);
      
      // Create deal, account, and contact
      // await createDealAccountContact(lead.id);
      const newDealId = await createDealAccountContact(lead.id);

      fetchLeadData();
      setActiveStep(getStepIndex('Qualified'));
      setUpdatedFields({});
      setSnackbar({
        open: true,
        message: 'Lead qualified successfully! Deal, Account, and Contact created.',
        severity: 'success',
      });

      navigate(`/deals/detail/${newDealId}`);
    } catch (error) {
      console.error('Error qualifying lead:', error);
      setSnackbar({
        open: true,
        message: 'Error qualifying lead. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createDealAccountContact = async (leadId) => {
    try {
      // Create account
      const accountResponse = await axiosInstance.post('/accounts/', {
        name: lead.company_name,
        // Add other account details as needed
      });

      // Create contact
      const contactResponse = await axiosInstance.post('/contacts/', {
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
        phone: lead.phone,
        account_id: accountResponse.data.id,
        // Add other contact details as needed
      });

      // Create deal
      const dealResponse = await axiosInstance.post('/deals/', {
        name: `Deal for ${lead.company_name}`,
        account_id: accountResponse.data.id,
        contact_id: contactResponse.data.id,
        lead_id: leadId,
        user_id: user.id,
        stage: qualificationData.stage,
        value: parseFloat(qualificationData.value),
        probability: parseInt(qualificationData.probability),
        // Add other deal details as needed
      });

      console.log('Deal, Account, and Contact created successfully');
      return dealResponse.data.id;
    } catch (error) {
      console.error('Error creating deal, account, or contact:', error);
      throw error;
    }
  };

  const renderQualificationModal = () => (
    <QualificationModal
      isOpen={isQualificationModalOpen}
      onClose={() => setIsQualificationModalOpen(false)}
      qualificationData={qualificationData}
      handleQualificationDataChange={handleQualificationDataChange}
      handleQualificationConfirm={handleQualificationConfirm}
    />
  );

  const handleSaveChanges = async () => {
    console.log(`Errors at handleSaveChanges:`, formErrors);
    
    if (Object.keys(formErrors).length > 0) {
      setSnackbar({
        open: true,
        message: 'Please correct the errors before saving.',
        severity: 'error',
      });
      return;
    }
  
    setIsLoading(true);
    console.log('saving changes');
    console.log(`Updated fields at handleSaveChanges:`, updatedFields);
    
    if (Object.keys(updatedFields).length > 0) {
      try {
        const payload = { ...updatedFields, updated_at: new Date().toISOString() };
        console.log(`Payload at handleSaveChanges:`, payload);
        
        const response = await axiosInstance.patch(`/leads/${lead.id}`, payload);
        await fetchLeadData();
        const newStep = getStepIndex(response.data.lead_status);
        console.log(`New step: ${newStep}`);
        
        setActiveStep(newStep);
        setUpdatedFields({});
        setFormErrors({});
        setSnackbar({
          open: true,
          message: 'Lead updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating lead:', error);
        setSnackbar({
          open: true,
          message: 'Error updating lead. Please try again.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
    setIsEditing(false);
  };

  const handleUserClick = (userId, userName) => {
    if (userId) {
      navigate(`/profile/${userId}`);
    } else {
      setSnackbar({
        open: true,
        message: `Unable to find user information for ${userName}.`,
        severity: 'info',
      });
    }
  };

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: '#fffff',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            {['created_by_name', 'updated_by_name'].includes(item.field) ? (
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  <strong>{item.label}:</strong>
                </MDTypography>
                <MDTypography 
                  component="span" 
                  onClick={() => {
                    const userId = lead[item.field.replace('_name', '_id')];
                    handleUserClick(userId, lead[item.field]);
                  }}
                  variant="button" 
                  color="info" 
                  fontWeight="medium"
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    ml: 1
                  }}
                >
                  {lead[item.field] || ''}
                </MDTypography>
              </MDBox>
            ) : item.field === 'annual_revenue' ? (
              <InlineEditField
                label={item.label}
                value={lead?.annual_revenue}
                onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
                onError={(field, error) => handleFieldError(item.field, error)}
                isEditing={isEditing && !isLeadLocked}
                readOnly={(item.readOnly ?? false) || isLeadLocked}
                currency={lead?.currency || 'INR'}
              />
            ) : (
              <InlineEditField
                label={item.label}
                value={lead?.[item.field]}
                options={
                  item.field === 'assigned_to'
                    ? agents.map(agent => ({ value: agent.id, label: agent.username }))
                    : item.options ?? null
                }
                onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
                onError={(field, error) => handleFieldError(item.field, error)}
                isEditing={isEditing && !isLeadLocked}
                readOnly={(item.readOnly ?? false) || isLeadLocked}
                isMultiSelect={item.isMultiSelect}
                renderValue={
                  item.field === 'assigned_to'
                    ? (value) => (
                        <MDTypography 
                          component="span" 
                          onClick={() => handleAgentClick(value)}
                          variant="button" 
                          color="info" 
                          fontWeight="medium"
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {agents.find(agent => agent.id === value)?.username || 'Unassigned'}
                        </MDTypography>
                      )
                    : item.field.includes('_at') || item.field === 'last_activity'
                      ? (value) => formatDate(value, 'datetime')
                      : undefined
                }
                required={
                  item.field === 'last_name' || 
                  (item.field === 'email' && !lead?.phone) || 
                  (item.field === 'phone' && !lead?.email)
                }
                helperText={
                  (item.field === 'email' || item.field === 'phone') && !lead?.email && !lead?.phone
                    ? 'Either Email or Phone is required'
                    : undefined
                }
              />
            )}
          </Grid>
        ))} 
      </Grid>
    </MDBox>
  );

  const fetchActivities = async () => {
    try {
      const params = new URLSearchParams();
      if (true) {
        params.append('parent_table', 'leads');
      }
      if (true) {
        params.append('parent_table_id', id);
      }
  
      // const response = await axiosInstance.get(`/activities/`, { params });
      const response = await axiosInstance.get(`/activities/leads/${id}`);
      console.log('Fetched activities:', response.data);
      setActivities(response.data || []);
    } catch (error) {
      console.error(`Error fetching activities: ${error}`);
      setSnackbar({
        open: true,
        message: `Error fetching activities: ${error.message}`,
        severity: 'error',
      });
      setActivities([]);
    }
  };
  
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  // const handleOpenConvertModal = () => setIsConvertModalOpen(true);
  // const handleCloseConvertModal = () => setIsConvertModalOpen(false);
  
  const handleDelete = async () => {
    setIsLoading(true);
    handleCloseDeleteModal();
    try {
      
      await axiosInstance.delete(`/leads/${lead.id}`, {
        params: { delete_related: deleteRelated }
      });

      navigate('/leads');
    } catch (error) {
      console.error('Error deleting lead:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgentClick = (agentId) => {
    if (agentId) {
      navigate(`/profile/${agentId}`);
    } else {
      setSnackbar({
        open: true,
        message: 'This lead is not assigned to any agent.',
        severity: 'info',
      });
    }
  };

  const fetchLeadData = async () => {
    setIsNoteLoading(true);
    setIsLoading(true);
    try {
      
      const response = await axiosInstance.get(`/leads/${id}`);
      console.log('Fetched lead:', response.data);
      setLead(response.data);

      const fetched_Step = getStepIndex(response.data.lead_status);
      setActiveStep(fetched_Step? fetched_Step : 0);
    } catch (error) {
      console.error('Error fetching lead data:', error);
    }
    finally {
      setIsLoading(false);
      setIsNoteLoading(false);
    }
  };

  const getStepIndex = (status) => {
    return steps.indexOf(status);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenActivityModal = () => {
    setIsActivityModalOpen(true);
  };

  const handleCloseActivityModal = () => {
    setIsActivityModalOpen(false);
    setNewActivity({
      lead_id: id,
      type: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
    });
  };

  const handleActivityInputChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  };

  const handleSaveActivity = async () => {
    setIsLoading(true);
    handleCloseActivityModal();
    try {
      const currentDate = new Date().toISOString()
      const response = await axiosInstance.post(`/leads/${id}/activities/`, newActivity, {});
      setActivities([...activities, response.data]);
      setSnackbar({
        open: true,
        message: 'Activity created successfully!',
        severity: 'success',
      });
      const lastActivity = await axiosInstance.patch(`/leads/${id}`, {
        last_activity: currentDate,
      });

      fetchLeadData();

      console.log('Last activity updated:', lastActivity.data);
      
    } catch (error) {
      console.error('Error saving activity:', error);
      setSnackbar({
        open: true,
        message: 'Error saving activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStepClick = async (stepIndex) => {
    const newStatus = steps[stepIndex];
    if (newStatus === lead.lead_status) return; // No change needed
  
    setIsLoading(true);
    try {
      const response = await axiosInstance.patch(`/leads/${lead.id}`, {
        lead_status: newStatus
      });
      
      setLead(response.data);
      setActiveStep(stepIndex);
      setSnackbar({
        open: true,
        message: `Lead status updated to ${newStatus}`,
        severity: 'success',
      });
  
      if (newStatus === 'Qualified') {
        setIsQualificationModalOpen(true);
      }
    } catch (error) {
      console.error('Error updating lead status:', error);
      setSnackbar({
        open: true,
        message: 'Error updating lead status. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleActivitySaved = async (newActivityData) => {
    setIsLoading(true);
    try {
      const currentDate = new Date().toISOString();
      setActivities([...activities, newActivityData]);
      
      const lastActivity = await axiosInstance.patch(`/leads/${id}`, {
        last_activity: currentDate,
      });

      console.log('Last activity updated:', lastActivity.data);
      
      setSnackbar({
        open: true,
        message: 'Activity created successfully!',
        severity: 'success',
      });

      await fetchLeadData(); // Refresh lead data to get updated information
    } catch (error) {
      console.error('Error updating last activity:', error);
      setSnackbar({
        open: true,
        message: 'Error updating last activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteActivity = async (activityId) => {
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/activities/${activityId}`);
      setActivities(activities.filter((activity) => activity.id !== activityId));
      setSnackbar({
        open: true,
        message: 'Activity deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting activity:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditActivity = async (activity) => {
    console.log('Edit activity:', activity.id);
    setIsLoading(true);
    try {
      await axiosInstance.patch(`/activities/${activity.id}`, activity);
      setSnackbar({
        open: true,
        message: 'Activity updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error editing activity:', error);
      setSnackbar({
        open: true,
        message: 'Error updating activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadSuccess = async (uploadData) => {
    console.log('Upload successful:', uploadData);
  
    const uploadDataPayload = {
      file_name: uploadData.file.name,
      file_type: uploadData.file.type,
      file_size: uploadData.file.size,
      bucket_name: 'attachments', // Replace with actual bucket name if different
      storage_path: uploadData.path,
      description: uploadData.description || '', // Optional, use default if no description is provided
      parent_table: 'leads',  // Assuming the parent table is 'leads', modify as needed
      parent_id: id,  // Assuming the parent ID is the same as lead ID
      is_active: true,
      is_private: uploadData.isPrivate || false, // Default to false if not provided
      file_url: uploadData.url,
      signed_url_expiry: uploadData.signedUrlExpiry || null,
    };
  
    setIsAttachmentModalOpen(false);
    try {
      const response = await axiosInstance.post(`/attachments/`, uploadDataPayload);
      
      setSnackbar({
        open: true,
        message: 'File uploaded successfully!',
        severity: 'success',
      });
      
      // Update files or attachments state as needed
      // setFiles([...files, response.data]); // Uncomment if `files` state exists
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbar({
        open: true,
        message: 'Error uploading file',
        severity: 'error',
      });
    } finally {
      fetchAttachments();
    }
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card
            sx={{
              animation: isCloning ? 'cloneAnimation 1s ease' : 'none',
            }}
            >
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
              <MDBox display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h5" color="white" marginLeft={1} marginBottom={2}>
                  {
                    (() => {
                      const firstName = lead.first_name 
                        ? lead.first_name.charAt(0).toUpperCase() + lead.first_name.slice(1).toLowerCase()
                        : "";
                      const lastName = lead.last_name
                        ? lead.last_name.charAt(0).toUpperCase() + lead.last_name.slice(1).toLowerCase()
                        : "";
                      
                      if (firstName && lastName) {
                        return `${firstName} ${lastName}`;
                      } else if (firstName) {
                        return firstName;
                      } else if (lastName) {
                        return lastName;
                      } else {
                        return "";
                      }
                    })()
                  }
                </MDTypography>

                <MDBox mt={-1} mb={2}>
                  {isEditing ? (
                    <MDBox>
                      <Button
                        onClick={handleSaveChanges}
                        variant="contained"
                        sx={{
                          backgroundColor: "#f0f0f0",
                          color: "#000",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            color: "#f0f0f0",
                          },
                        }}
                        startIcon={<Save />}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => setIsEditing(false)}
                        variant="contained"
                        sx={{
                          ml: 2,
                          backgroundColor: "#f0f0f0",
                          color: "#d9534f",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            color: "#f0f0f0",
                          },
                        }}
                        startIcon={<Close />}
                      >
                        Discard
                      </Button>
                    </MDBox>
                  ) : (
                    <MDBox>
                      {!isLeadLocked && (
                        <IconButton
                          aria-label="edit"
                          onClick={handleEditToggle}
                          sx={{
                            backgroundColor: "#f0f0f0",
                            borderRadius: "50%",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#0275d8",
                              "& .MuiSvgIcon-root": {
                                color: "#f0f0f0",
                              },
                            },
                          }}
                        >
                          <Edit sx={{ color: "#5bc0de" }} />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="clone"
                        onClick={handleCloneLead}
                        sx={{
                          backgroundColor: "#f0f0f0",
                          borderRadius: "50%",
                          marginVertical: "20px",
                          mx: 3,
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            "& .MuiSvgIcon-root": {
                              color: "#f0f0f0",
                            },
                          },
                        }}
                      >
                        <FileCopy sx={{ color: "#5cb85c" }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={handleOpenDeleteModal}
                        sx={{
                          backgroundColor: "#f0f0f0",
                          borderRadius: "50%",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          transition: "background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#0275d8",
                            "& .MuiSvgIcon-root": {
                              color: "#f0f0f0",
                            },
                          },
                        }}
                      >
                        <Delete sx={{ color: "#d9534f" }} />
                      </IconButton>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>


                <MDBox>
                  <MDTypography variant="button" color="white" mb={2}>
                    {lead.status}
                  </MDTypography>
                  <ThemedCustomStepper activeStep={activeStep} steps={leadStatus} onStepClick={isLeadLocked ? undefined : handleStepClick}  />
                </MDBox>
              </MDBox>
              <MDBox p={3}>

                  {!isLoading ? (
                  <>
                    {renderSection("Basic Information", [
                      { label: "Salutation", field: "salutation", options: ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."] },
                      {label: "Designation", field: "designation"},
                      { label: "First Name", field: "first_name" },
                      { label: "Last Name", field: "last_name" },
                     
                      { label: "Company", field: "company_name" },
                      { label: "Email", field: "email" },
                      
                      { label: "Phone", field: "phone" },
                      { label: "Mobile", field: "mobile" },
                      
                      { label: "Website", field: "website" },
                      { label: "Industry", field: "industry", options: ["Advertising", "Banking", "Consulting", "Education", "Finance", "Government", "Healthcare", "Insurance", "Manufacturing", "Media", "Retail", "Technology", "Telecommunications", "Travel", "Other"] },
                      { 
                        label: "Interested Products", 
                        field: "interested_products", 
                        options: products.map(p => ({ value: p.name, label: p.name })),
                        isMultiSelect: true
                      },
                      { label: "Lead Source", field: "lead_source" , options: [ "Website", "Referral", "Social Media", "Email Campaign", "Trade Show", "Cold Call", "Advertisement", "Other" ]},
                      { label: "Lead Status", field: "lead_status", options: ["New", "In Progress", "Qualified", "Disqualified"] },
                      { label: "Lead Rating", field: "lead_rating", options: ["Cold", "Warm", "Hot"] },
                      { 
                        label: "Assigned To", 
                        field: "assigned_to", 
                        options: agents.map(a => ({ value: a.id, label: a.username }))
                      },
                      { 
                        label: "Lead Score", 
                        field: "lead_score", 
                      },
                    ])}

                    {renderSection("Additional Details", [
                      { label: "Address", field: "address" },
                      { label: "City", field: "city" },
                      { label: "State", field: "state" },
                      { label: "Country", field: "country", options: ["United States", "United Kingdom", "India", "Australia", "Canada", "Germany", "France", "Italy", "Japan", "Switzerland", "China", "Hong Kong", "Singapore", "Malaysia", "Thailand", "Singapore", "Other"] },
                      { label: "Postal Code", field: "postal_code" },
                      { label: "Annual Revenue", field: "annual_revenue" },
                      { label: "Currency", field: "currency", options: ["USD", "EUR", "GBP", "INR", "JPY", "CAD", "AUD", "CHF", "CNY", "HKD"] },
                      { label: "Number of Employees", field: "number_of_employees" },
                      { label: "Comments", field: "notes" },
                    ])}

                    {renderSection("Audit Information", [
                      { label: "Created By", field: "created_by_name", readOnly: true },
                      { label: "Modified By", field: "updated_by_name", readOnly: true },
                      { label: "Created Date", field: "created_at", readOnly: true },
                      { label: "Modified Date", field: "updated_at", readOnly: true },
                      
                      { label: "Last Activity", field: "last_activity", readOnly: true },
                    ])}
                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={2}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Activities
              </MDTypography>
              <MDButton variant="gradient" color="dark" onClick={handleOpenActivityModal}>
                Add Activity
              </MDButton>
            </MDBox>
            <MDBox p={2}>
              <Activities 
                activities={activities.filter(Boolean)} 
                onDelete={handleDeleteActivity}
                onEdit={handleEditActivity} 
              />
            </MDBox>
          </Card>

          {/* Add margin between the two sections */}
          <Box mt={5} /> 

          <Card>
            <MDBox
              mx={2}
              mt={-3}  
              py={2}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Attachments
              </MDTypography>
              <MDButton variant="gradient" color="dark" onClick={() => setIsAttachmentModalOpen(true)}>
                Add Attachment
              </MDButton>
            </MDBox>
            <MDBox p={2}>
              <Attachments 
                attachments={attachments}
                onDelete={handleDeleteAttachment}
                onDownload={handleDownloadAttachment}
              />
            </MDBox>
          </Card>
                  
          <AttachmentUploadModal
            isOpen={isAttachmentModalOpen}
            onClose={() => setIsAttachmentModalOpen(false)}
            onUpload={handleUploadSuccess}
            axiosInstance={axiosInstance}
            allowedFileTypes="*/*"
            maxSizeInMB={5}
          />
        </Grid>

        </Grid>
      </MDBox>

      <ActivityModal
        isOpen={isActivityModalOpen}
        onClose={handleCloseActivityModal}
        parentId={id}
        parent="leads"
        axiosInstance={axiosInstance}
        onActivitySaved={handleActivitySaved}
      />

      {/* Delete Confirmation Modal */}
        <Modal
          open={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
              Confirm Deletion
            </MDTypography>
            <MDTypography id="delete-modal-description" mb={4}>
              Are you sure you want to delete this lead? This action cannot be undone.
            </MDTypography>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteRelated}
                    onChange={(e) => setDeleteRelated(e.target.checked)}
                    color="primary"
                  />
                }
                label="Delete related records"
              />
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
                Cancel
              </MDButton>
              <MDButton onClick={handleDelete} variant="contained" color="error">
                Delete
              </MDButton>
            </MDBox>
          </Box>
        </Modal>

      <Footer />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {renderQualificationModal()}

    </DashboardLayout>
  );
}

export default LeadDetail;
